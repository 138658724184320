import React from 'react'
import images from "../../../../helpers/images"


const ContentSection = () => {
    return (
        <div id="about_top_content">
            <div className='advisory_parent_section'>
                <div className='agency_detail_heading'>
                    <div className='purpose_left section_padding' data-aos="zoom-in">
                        <h1 className='text-center text-white'>Branding Strategy <br className='break_tag' />& Consulting</h1>
                    </div>
                    <div className='container'>
                        <div className=''>
                            <div className='row'>
                                <div className='col-lg-5 my-auto'>
                                    <div className='advisory_details_content_left' data-aos="fade-down-left">
                                        <h2 className='heading_para text-white'>Crafting A Unique Brand  <br className='break_tag' />Identity</h2>
                                        <p>Elevate your brand with our <span className='font-bold text-danger'>Branding Strategy & Consulting</span>  services. We collaborate with
                                            you to understand your vision, mission, and values, crafting a unique identity that
                                            resonates with your target audience.</p>
                                        <p>
                                            A compelling brand identity sets you apart from the competition, making you memorable in a crowded marketplace.                                            </p>
                                    </div>
                                </div>
                                <div className='col-lg-5 mx-auto'>
                                    <div className='advisory_details_content_right' data-aos="fade-down-right">
                                        <img src={images.advisory_branding_1} alt="../" className='w-100 Advisory_marketing_rght_img' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='section_padding'>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='advisory_details_content_right' data-aos="fade-down-right">
                                        <img src={images.advisory_branding_2} alt="../" className='w-100 Advisory_marketing_left_img' />
                                    </div>
                                </div>
                                <div className='col-lg-5  my-auto mx-auto'>
                                    <div className='advisory_details_content_left' data-aos="fade-down-left">
                                        <h2 className='heading_para text-white'>Ensuring Consistency <br className='break_tag' />Across Platforms</h2>
                                        <p>Consistency is key in branding. Our team conducts thorough market research and competitive
                                            analysis to create a cohesive branding strategy encompassing messaging, visual identity,
                                             and positioning.</p>
                                        <p>
                                        We ensure that your brand maintains consistency across all touchpoints, reinforcing your identity and building trust with your customers.                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
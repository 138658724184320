import React from "react";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import Banner from "../component/Home/Banner";
import Content from "../component/Home/Content";

const Home = () => {
  return (
    <div className="home-nav-main">
      <Navbar />
      <Banner />
      <Content />
      <Footer />
    </div>
  );
};

export default Home;

import React, { useEffect } from "react";
import Navbar from "../component/Navbar/Navbar";
import ContactUsBanner from "../component/ContactUs/ContactUsBanner";
import ContactUsContent from "../component/ContactUs/ContactUsContent";
import Footer from "../component/Footer/Footer";

const ContactUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className="overflow-hidden contact-hide-lets-connect">
        <ContactUsBanner />
        <ContactUsContent />
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;

import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Video from "../../assets/videos/se-video.mp4";
const Banner = () => {
  const ourLogos = [
    {
      img: require("../../assets/images/ourclients/c1.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c2.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c3.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c4.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c5.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c6.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c7.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c8.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c9.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c10.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c11.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c12.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c13.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c14.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c15.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c16.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c17.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c18.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c19.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c20.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c21.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c22.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c23.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c24.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c25.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c26.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c27.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c28.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c29.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c30.webp"),
    },
    {
      img: require("../../assets/images/ourclients/c31.webp"),
    },
  ];
  return (
    <>
      <div className="home-banner-section">
        <video autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>
      </div>
      <div className="md-scroll-section">
        <div className="container">
          <div className="scroll">
            <OwlCarousel
              className="owl-theme"
              loop={true}
              items={1}
              dots={false}
              nav={false}
              margin={0}
              center={true}
              autoplay={true}
              responsive={{
                360: {
                  items: "2",
                },
                414: {
                  items: "2",
                },
                670: {
                  items: "3",
                },
                992: {
                  items: "5",
                },
                1200: {
                  items: "7",
                },
                1300: {
                  items: "7",
                },
              }}
            >
              {ourLogos.map((item, index) => {
                return (
                  <img
                    key={index}
                    className="scroll-banner-logos"
                    src={item.img}
                    alt=""
                  />
                );
              })}
            </OwlCarousel>
          </div>
        </div>
        <marquee>
          {/* <div className="d-flex justify-content-start align-items-center">
            {ourLogos.map((item, index) => {
              return (
                <img
                  key={index}
                  className="scroll-banner-logos"
                  src={item.img}
                  alt=""
                />
              );
            })}
          </div> */}
        </marquee>
      </div>
    </>
  );
};

export default Banner;

import React from 'react'
import images from "../../../../helpers/images"
import Advisory_marketing_rght_img from "../../../../assets/images/scm_13.webp";
import social_management_whyChoose from "../../../../assets/images/scm_14.webp";
import { useNavigate } from 'react-router-dom';
const ContentSection = () => {
    const navigate = useNavigate();
    return (
        <div className='Agency_detail_page' id="about_top_content">
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='purpose_left' data-aos="fade-down-right">
                                <h1>Content  <br className='break_tag' />Marketing </h1>
                                <button onClick={()=>navigate('/contact-us')} className='button_red mt-4 agency_into_button'>Consult Now</button>
                            </div>
                        </div>
                        <div className='col-lg-8 my-auto'>
                            <div className='purpose_right pe-5' data-aos="fade-down-left">
                                <p>Looking to deepen your connection with your audience and strengthen brand loyalty? Our Content Marketing services are designed to do just that, creating high-impact, valuable content that resonates with your audience and drives measurable results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section  section_padding'>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-5'>
                            <div className='why_digital_market_left' data-aos="fade-down">
                                <h2 className='headings_text_black'>Why Content Marketing?</h2>
                                <img src={Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='why_digital_market_right' data-aos="fade-up">
                                <p className='paraWhite_margin_top text-black'>Content marketing is at the core of effective brand communication. In today’s competitive landscape, it’s not enough to simply have a product or service; you need a compelling story that resonates. Engaging content builds trust, nurtures relationships, and keeps your brand at the forefront of your audience’s mind. Without it, even the most outstanding products or services risk being overlooked. Content marketing bridges this gap, building a loyal audience and fostering brand affinity through meaningful, memorable experiences.</p>
                                <button onClick={()=>navigate('/contact-us')} className='button_gradient mt-4'>Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }} data-aos="fade-right">
                                <div className='container'>
                                    <h2 className='headings_text_white'>Why Choose Our Content  <br className='break_tag' /> Marketing Services?</h2>
                                    <p className='paraWhite_margin_top mb-4'>Simple: we deliver results. While creating content is one part of the equation, creating content that performs is another. Our team combines strategy, creativity, and analytics to produce content that not only informs but converts, maximizing your return on investment.</p>
                                    <img src={social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                    <p className='paraWhite_margin_top'>With our expertise in content marketing, we help you not only attract and retain customers but also strengthen your brand’s authority and online presence. We’re here to make your content marketing efforts as impactful and seamless as possible, building genuine connections with your audience every step of the way.</p>
                                    <h2 className='headings_text_white mt-5'>Ready to Elevate Your <br className='break_tag' />Content Marketing?</h2>
                                    <button onClick={()=>navigate('/contact-us')} className='button_red mt-4 agency_details_button'>Get Your FREE Content Strategy Session Today!</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_rght' data-aos="fade-left">
                                <h3 className='headings_text_white mb-5'>Here’s what makes our content <br className='break_tag' /> marketing unique</h3>
                                <div className='agency_detail_whyChoose_rght_content mt-0'>
                                    <div>
                                        <img src={images.Content_Marketing_icon1} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Strategic Development Tailored to Your Goals</h5>
                                        <p>We go beyond basic content creation by developing a fully customized content strategy that aligns with your business goals and speaks directly to your target audience. This sets the foundation for messaging that’s not only impactful but also sustainable.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Content_Marketing_icon2} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>High-Quality, Engaging Content Creation</h5>
                                        <p>We specialize in creating a wide range of engaging content types, from blogs and articles to infographics, videos, and more. Each piece is crafted to educate, entertain, and engage your audience, leaving a lasting impression and building brand loyalty.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Content_Marketing_icon3} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Targeted Distribution and Promotion</h5>
                                        <p>Creating great content is just the start. We ensure your message reaches the right audience through carefully selected distribution channels, supported by promotion tactics that amplify your reach and visibility. Whether it’s through social media, email marketing, or influencer partnerships, we make sure your content finds its mark.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Content_Marketing_icon3} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Data-Driven Performance Analysis</h5>
                                        <p>Creating great content is just the start. We ensure your message reaches the right audience through carefully selected distribution channels, supported by promotion tactics that amplify your reach and visibility. Whether it’s through social media, email marketing, or influencer partnerships, we make sure your content finds its mark.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
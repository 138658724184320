import React, { useEffect } from 'react'
import Navbar from '../component/Navbar/Navbar'
import AdvisoryCommonSections from '../component/Advisory/AdvisoryCommonSections'
import BannerSection from '../component/Advisory/SubPages/MarketingStrategyAndConsulting/BannerSection'
import ContentSection from '../component/Advisory/SubPages/MarketingStrategyAndConsulting/ContentSection'
import Footer from '../component/Footer/Footer'

const MarketingStrategyAndConsulting = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <BannerSection />
      <ContentSection />
      <AdvisoryCommonSections />
      <Footer />
    </div>
  )
}

export default MarketingStrategyAndConsulting
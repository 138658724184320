export const Key_Offerings = [
    {
        heading: [
            "Comprehensive Development Lifecycle",
            "Cross-Platform Development",
            "Scalable & Secure Architecture",
            "API & Third-Party Integrations",
        ],
        content: [
            "From initial research, wireframing, and prototyping to coding, testing, and deployment.",
            "Our development ensures that both iOS and Android users enjoy the same seamless experience.",
            "Built with robust architecture for easy scaling and optimized security to protect user data.",
            "Smooth integration with APIs, payment gateways, and other essential third-party tools for seamless functionality.",
        ],
    },
    {
        heading: [
            "Custom Shopping Cart & Payment Gateways",
            "Inventory Management System",
            "SEO-Optimized Product Listings",
            "Scalable Architecture",
        ],
        content: [
            "An intuitive shopping cart experience that simplifies the checkout process for your customers.",
            "Easily track and manage inventory with advanced, user-friendly backend tools.",
            "Optimized product pages to improve visibility and reach in search engines.",
            "Built to handle high traffic and order volumes without compromising site performance.",
        ],
    },
    {
        heading: [
            "Custom Themes & Templates",
            "Enhanced Product Page Optimization",
            "Secure Payment & Checkout Options",
            "Performance Optimization",
        ],
        content: [
            "Custom Shopify themes designed to reflect your unique brand identity.",
            "Customizable product layouts for a captivating shopping experience.",
            "Built-in options for a secure and seamless checkout.",
            "Fine-tuned for faster load times and optimal performance across devices.",
        ],
    },
    {
        heading: [
            "Custom Theme & Plugin Development",
            "SEO-Optimized Content Structure",
            "Mobile-Responsive Designs",
            "Continuous Support & Maintenance",
        ],
        content: [
            "Unique themes and custom plugins to enhance functionality and design.",
            "WordPress sites designed with SEO in mind to boost visibility.",
            "Fully responsive designs for seamless browsing on all devices.",
            "Ongoing support to keep your site up-to-date and secure.",
        ],
    },
    {
        heading: [
            "Regular Security Patching & Updates",
            "Bug Fixes & Feature Enhancements",
            "Real-Time Performance Monitoring",
            "User Feedback Integration",
        ],
        content: [
            "Keeping your applications safe and compliant with the latest security standards.",
            "Swift identification and correction of any bugs, along with feature updates.",
            "Analytics-based insights for continuous improvements and optimizations.",
            "Incorporating user feedback into regular updates to enhance the user experience.",
        ],
    },
    {
        heading: [
            "Custom AI Model Development",
            "Chatbot Integration & Customer Support",
            "Predictive Analytics & Data Processing",
            "Automation Solutions",
        ],
        content: [
            "Personalized AI solutions for predictive analytics and recommendations.",
            "AI-powered chatbots for real-time support and enhanced customer satisfaction.",
            "Analyzing trends and user behaviors to improve strategies and marketing.",
            "AI-driven automation to streamline operational efficiency and enhance customer service.",
        ],
    },
    {
        heading: [
            "In-Depth User Research",
            "Wireframes, Prototypes, & Usability Testing",
            "Responsive & Adaptive Design",
            "Ongoing UX Improvements",
        ],
        content: [
            "Conducting user studies to inform design strategies that truly meet user needs.",
            "Building and testing prototypes to refine usability and design.",
            "Designs that look and perform exceptionally across different devices and screen sizes.",
            "Regularly updating designs based on feedback and data to maximize engagement.",
        ],
    },
]
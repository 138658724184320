import React, { useState } from "react";
import india_flag from "../../assets/images/india_flag.webp";
import dubai_flag from "../../assets/images/dubai_flag.webp";
import axios from "axios";
import ThankyouModal from "../../common/ThankyouModal";

const ContactUsContent = () => {
  const [map, setMap] = useState("chennai");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [validation, setValidation] = useState({});
  const InitialState = {
    name: "",
    mobile: "",
    email: "",
    companyName: "",
    budget: "",
    website: "",
    message: "",
  };
  const [formData, setFormData] = useState(InitialState);

  const validate = () => {
    let error = {};
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Fullname validation
    if (formData.name.trim() === "") {
      error.name = "Name cannot be empty.";
    } else if (formData.name.length < 3) {
      error.name = "Name must be at least 3 characters long.";
    } else if (!nameRegex.test(formData.name)) {
      error.name = "Name can only contain letters and spaces.";
    }

    // WhatsApp number validation
    if (formData.mobile.trim() === "") {
      error.mobile = "WhatsApp number cannot be empty.";
    } else if (!/^[6-9]/.test(formData.mobile)) {
      error.mobile = "Invalid WhatsApp number";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      error.mobile = "WhatsApp number must be exactly 10 digits.";
    }

    // Email validation
    if (formData.email.trim() === "") {
      error.email = "Email cannot be empty.";
    } else if (!emailPattern.test(formData.email)) {
      error.email = "Invalid email address.";
    }

    // Designation validation
    if (!formData.message.trim()) {
      error.message = "Message is required.";
    }

    return error;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setValidation(errors);
    let formdata = new FormData();
    for (let key in formData) {
      formdata.append(key, formData[key]);
    }
    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        await axios.post(
          "https://script.google.com/macros/s/AKfycbz5riIJAeuAUEGSg9oQRV7YdphPawQyCGtnSCYeRnB3HDdwvS8ry1opEdOMEUH_6sIy/exec",
          formdata
        );
        setLoading(false);
        setModalOpen(true);
        setFormData(InitialState);
      } catch (err) {
        console.error("Error:", err);
        setLoading(false);
      }
    }
  };
  return (
    <>
      <div className="get_in_touch">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="form_parent_div">
                <div data-aos="fade-left" data-aos-delay="300">
                  <h4 className="form_heading">Your Vision, Our Mission:</h4>
                  <h4 className="form_heading">
                    Let's Shape Success Together.
                  </h4>
                </div>
                <form className="mt-4" onSubmit={handleSubmit}>
                  <div
                    className="row"
                    data-aos="fade-right"
                    data-aos-delay="300"
                  >
                    <div className="col-lg-12">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        className="form_input w-100"
                        maxLength={50}
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {validation.name && (
                        <p className="errorpara">{validation.name}</p>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="tel"
                        name="mobile"
                        placeholder="Mobile Number"
                        className="form_input w-100"
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                      {validation.mobile && (
                        <p className="errorpara">{validation.mobile}</p>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="form_input w-100"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {validation.email && (
                        <p className="errorpara">{validation.email}</p>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        name="companyName"
                        placeholder="Company Name"
                        className="form_input w-100"
                        maxLength={50}
                        value={formData.companyName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        name="budget"
                        placeholder="Avg. Monthly Marketing Budget"
                        className="form_input w-100"
                        value={formData.budget}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-12">
                      <input
                        type="tel"
                        name="website"
                        placeholder="Website URL"
                        className="form_input w-100"
                        value={formData.website}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        name="message"
                        placeholder="Message"
                        className="form_input w-100 pt-2"
                        style={{ height: "100px" }}
                        value={formData.message}
                        onChange={handleChange}
                      />
                      {validation.message && (
                        <p className="errorpara">{validation.message}</p>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="submit_btn mt-4"
                    disabled={loading}
                    style={{ width: "8rem" }}
                  >
                    {loading ? (
                      <div className="loader" />
                    ) : (
                      <>
                        Submit <i className="fa-solid fa-arrow-right ms-2"></i>
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="get_in_touch_right ps-3">
                <h4 className="form_heading">Get In Touch</h4>
                <div className="row">
                  <div
                    className="col-lg-6"
                    data-aos="fade-down"
                    data-aos-delay="300"
                  >
                    <div
                      className="address_div mt-4"
                      style={{ cursor: "pointer" }}
                      onMouseOver={() => setMap("chennai")}
                    >
                      <div>
                        <img
                          src={india_flag}
                          alt="India Flag"
                          className="mb-2"
                        />
                      </div>
                      <div className="mt-3">
                        <p className="mb-2">India</p>
                        <p>
                          No.14-16, Global Hospital Rd, <br /> Opp. ICICI Bank,
                          Indira Priyadarshini Nagar Perumbakkam, Chennai -
                          600100
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6"
                    data-aos="fade-down"
                    data-aos-delay="300"
                  >
                    <div
                      className="address_div mt-4"
                      style={{ cursor: "pointer" }}
                      onMouseOver={() => setMap("dubai")}
                    >
                      <div>
                        <img
                          src={dubai_flag}
                          alt="Dubai Flag"
                          className="mb-2"
                        />
                      </div>
                      <div className="mt-3">
                        <p className="mb-2">Dubai</p>
                        <p>
                          Building A1,
                          <br /> Dubai Digital Park,
                          <br /> Dubai Silicon Oasis, <br />
                          Dubai
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay="300">
                  {map === "chennai" ? (
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3889.039012492159!2d80.208154!3d12.905213!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d14f00995f7%3A0xb37cd82fd02a1480!2sSocial%20Eagle%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1730913569771!5m2!1sen!2sin"
                      width="100%"
                      height="280"
                      style={{ border: "0", marginTop: "30px" }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  ) : (
                    <>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7449432.25898415!2d48.654564292334705!3d24.262123124573222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f651c2438dc53%3A0xe6abcc1ea38a17f5!2sD1%20-%20Dubai%20Digital%20Park!5e0!3m2!1sen!2sin!4v1738233842381!5m2!1sen!2sin"
                        width="100%"
                        height="280"
                        style={{ border: "0", marginTop: "30px" }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ThankyouModal
          open={modalOpen}
          setOpen={() => {
            setModalOpen(false);
          }}
        />
      </div>
    </>
  );
};

export default ContactUsContent;

import React, { useEffect, useRef, useState } from "react";
import { digital_marketing_content } from "../../helpers/contents/AgencyDigitalMarketing";
import ROUTES from "../../helpers/constants/routes";
const Digital = () => {
  const agencySection = useRef([]);
  const observerRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  const digital_marketing_detail_page = [
    {
      heading: "Social Media Management",
      redirectLink: "/agency/social-media-management",
    },
    {
      heading: "Social Media Marketing",
      redirectLink: "/agency/social-media-marketing",
    },
    { heading: "Lead Generation", redirectLink: ROUTES.AGENCY_SERVICES.LEAD_GENERATION },
    { heading: "Pay-Per-Click", redirectLink: "/agency/pay-per-click" },
    {
      heading: "Performance Marketing",
      redirectLink: "/agency/performance-marketing",
    },
    {
      heading: "Digital Media Planning & Buying",
      redirectLink: "/agency/digital-media-planning-and-buying",
    },
    {
      heading: "SEO Services",
      redirectLink: "/agency/search-engine-optimization",
    },
    {
      heading: "Content Marketing",
      redirectLink: "/agency/content-marketing-service",
    },
    {
      heading: "Email marketing",
      redirectLink: "/agency/email-marketing-service",
    },
    { heading: "Automation", redirectLink: "/agency/marketing-automation" },
  ];

  // Set up Intersection Observer to detect which video is in view
  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = agencySection.current.indexOf(entry.target);
            if (index !== -1) {
              setActiveTab(index);
            }
          }
        });
      },
      { threshold: 0.5 }
    ); // Adjust threshold as needed

    agencySection.current.forEach((section) => {
      if (section) observerRef.current.observe(section);
    });

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (agencySection.current[index]) {
      const offsetTop =
        agencySection.current[index].getBoundingClientRect().top +
        window.pageYOffset -
        100; // Adjust -100 to your desired offset
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-between ">
          <div className="col-lg-6 px-0 position-relative">
            <div className="hands-on-left-content">
              <div className="padding-inner-content">
                <ul className="list-unstyled hands-on-content-mob pl-0">
                  {digital_marketing_detail_page.map((text, index) => (
                    <li key={index}>
                      <span
                        className={`hands-on-left-text ${activeTab === index ? "active" : ""
                          }`}
                        onClick={() => handleTabClick(index)}
                        ref={(el) => (agencySection.current[index] = el)}
                      >
                        {text?.heading}
                        <a
                          href={text?.redirectLink}
                          style={{ textDecoration: "none" }}
                        >
                          <i
                            className={`${index === activeTab ? "circle_arrow_active" : ""
                              } fa-solid fa-arrow-right circle_arrow my-auto`}
                          ></i>
                        </a>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hands-on-right-section padding-inner-content">
              {digital_marketing_content.map((item, index) => (
                <div
                  key={index}
                  className="hands-on-right-video mb-5 position-relative"
                  ref={(el) => (agencySection.current[index] = el)}
                  style={{ marginBottom: "16px" }}
                >
                  <h2 className="heading_para">{item?.heading}</h2>
                  <p className="">{item.SubPara}</p>
                  <ul>
                    {item?.ListPara.map((data, index) => (
                      <li className="mt-3" key={index}>
                        {data}
                      </li>
                    ))}
                  </ul>
                  {item?.Content.map((data2, index2) => (
                    <p className="mt-3" key={index2}>
                      {data2}
                    </p>
                  ))}
                  <a href={item?.RedirectLink}>
                    <button className="button_red mt-3">
                      Learn More <i class="fa-solid fa-arrow-right ms-2"></i>
                    </button>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Digital;

import React from 'react'
import images from "../../../../helpers/images"
import { useNavigate } from 'react-router-dom'
const ContentSection = () => {
    const navigate = useNavigate();
    return (
        <div className='Agency_detail_page overflow-hidden' id='about_top_content' >
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='purpose_left' data-aos="fade-down-right">
                                <h1>Social Media  <br className='break_tag' />Management </h1>
                                <button onClick={()=>navigate('/contact-us')} className='button_red mt-4 agency_into_button'>Dominate Social Media Now</button>
                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='purpose_right pe-5' data-aos="fade-down-left">
                                <p>Ever noticed those eye-catching posts and stories dominating your
                                    social feeds? They’re the prime real estate of social media, and
                                    if you want your brand to stand out in this bustling space, you’ll
                                    need a smart strategy to get there. Managing social media accounts may
                                    seem straightforward, but doing it right can make all the difference
                                    between just existing online and truly thriving.</p>
                                <p>At Social Eagle, a social media management agency in Dubai, we
                                    know how to create the buzz your brand needs. From crafting engaging content to optimising your social ads,
                                    we’ll handle your social media like pros, without breaking the bank.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section'>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-5'>
                            <div className='why_digital_market_left' data-aos="fade-right">
                                <h2 className='headings_text_black'>What is Social Media<br className='break_tag' />Management?</h2>
                                <img src={images.Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-lg-4 mt-0' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='why_digital_market_right' data-aos="fade-left">
                                <p className='text-black'>Social media management is all about creating, scheduling, and monitoring content across your social channels to engage your audience and grow your brand. When done right, it can boost your visibility, drive traffic, and increase sales. But without the right approach, it can turn into a time-consuming task with little return.</p>
                                <p className='paraWhite_margin_top text-black'>Our team at Social Eagle, Top social media management agency, knows how to craft strategies that resonate with your target audience. Whether it's on.</p>
                                <div className='d-flex flex-column mt-4'>
                                    <p className='text-black'><i class="fa-brands fa-facebook me-2"></i> Facebook</p>
                                    <p className='text-black'><i class="fa-brands fa-instagram me-2"></i> Instagram</p>
                                    <p className='text-black'><i class="fa-brands fa-linkedin me-2"></i> LinkedIn</p>
                                </div>
                                <p className='paraWhite_margin_top text-black'>or on other platforms, we handle everything from content creation to analytics, ensuring your brand stays active and relevant without you having to lift a finger.</p>
                                <p className='paraWhite_margin_top text-black'>Let us bring your social media to life and watch your online presence thrive!</p>
                                <button onClick={()=>navigate('/contact-us')} className='button_gradient mt-4'> Keen to Know More?</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }} data-aos="zoom-out-right">
                                <div className='container'>
                                    <h2 className='headings_text_white'>Why Choose Us?</h2>
                                    <p className='paraWhite_margin_top mb-5'>Our Social Media Management services help your business succeed on social media and increases brand visibility by</p>
                                    <img src={images.social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                    <p className='paraWhite_margin_top mt-5'>We also track results to see what works and adjust our strategies as needed.</p>
                                    <p className='paraWhite_margin_top'>With targeted ads, we reach the right audience and increase your brand’s visibility.</p>
                                    <p className='paraWhite_margin_top'>Let us help you connect with customers and grow your online presence.</p>
                                    <button onClick={()=>navigate('/contact-us')} className='button_red mt-5'> Get Consultation</button>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_rght' data-aos="zoom-out-left">
                                <div className='agency_detail_whyChoose_rght_content mt-0'>
                                    <div>
                                        <img src={images.SocialMedia_icon1} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Creating Engaging Content</h5>
                                        <p>We create interesting posts that connect with your audience and represent your brand.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.SocialMedia_icon2} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Smart Scheduling</h5>
                                        <p> We schedule posts at the best times to get the most engagement.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.SocialMedia_icon3} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Community Interaction & Reputation Management</h5>
                                        <p>We interact with your followers to build a strong community around your brand.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
import React from "react";
import Logo from "../../assets/images/logo.svg";
import india_map from "../../assets/images/india_map.webp";
import dubai_map from "../../assets/images/dubai_map.webp";

import contact_banner_img1 from "../../assets/images/banner/lw1.webp";
import contact_banner_img2 from "../../assets/images/banner/lw2.webp";
import contact_bar from "../../assets/images/contact_bar.webp";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../helpers/constants/routes";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <a
       href="https://api.whatsapp.com/send?phone=971588647897&text=I%20want%20to%20grow%20my%20business%20digitally.%20How%20Social%20Eagle%20can%20help%20me?"
        class="float"
        target="_blank"
      >
        <i class="fa-brands fa-whatsapp  my-float"></i>
      </a>
      <div className="let_connect_section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6 col-md-8">
              <div className="let_connect_left">
                <h1 className="let_connect_head">
                  Let's{" "}
                  <img
                    src={contact_banner_img1}
                    alt=""
                    className="lets_connect_img"
                  />{" "}
                  work
                </h1>
                <h1 className="let_connect_head">
                  <img
                    src={contact_banner_img2}
                    alt=""
                    className="lets_connect_img2"
                  />{" "}
                  together
                </h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 m-auto">
              <div className="let_connect_right footer_let_connect_right">
                <button
                  onClick={() => navigate("/contact-us")}
                  className="button_white"
                >
                  Get In Touch
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logo_section_footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="logo_section">
                <div>
                  <img className="logo-img" src={Logo} alt="Logo" />
                </div>
                <p className="mt-1">
                  On a Mission to Add value and Make a Difference in People’s
                  Lives
                </p>
                <div className="d-flex mt-3">
                  <a
                    href="https://www.instagram.com/socialeagleofficial/?hl=en"
                    target="_blank"
                    className="social_media"
                  >
                    <div className="">
                      <i class="fa-brands fa-instagram"></i>
                    </div>
                  </a>
                  {/* <a
                    href="https://www.instagram.com/socialeagleofficial/?hl=en"
                    target="_blank"
                    className="social_media"
                  >
                    <div className="">
                      <i class="fa-brands fa-whatsapp"></i>
                    </div>
                  </a> */}
                  <a
                    href="https://www.youtube.com/@socialeagleofficial1155"
                    target="_blank"
                    className="social_media"
                  >
                    <div className="">
                      <i class="fa-brands fa-youtube"></i>
                    </div>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/socialeagle/"
                    target="_blank"
                    className="social_media"
                  >
                    <div className="">
                      <i class="fa-brands fa-linkedin-in"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="address_div2 d-flex">
                <div className="me-4">
                  <img src={india_map} alt="" className="map_images" />
                </div>
                <div>
                  <h5 className="mb-3 font-medium">India</h5>
                  <p>
                    No.14-16, Global Hospital Rd,
                    <br /> Opp. ICICI Bank, Indira Priyadarshini Nagar
                    Perumbakkam, Chennai - 600100
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="address_div2 d-flex">
                <div className="me-4">
                  <img src={dubai_map} alt="" className="map_images" />
                </div>
                <div>
                  <h5 className="mb-3 font-medium">Dubai</h5>
                  <p>
                    Building A1,
                    <br /> Dubai Digital Park,
                    <br /> Dubai Silicon Oasis, <br />
                    Dubai
                  </p>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-between footer_link_columns">
          <div className="col-lg-3 col-6 col-md-4 ">
            <div className="footer_boxes d-flex flex-column ">
              <h5 className="mb-4">Company</h5>
              <Link className="footer_links" to={ROUTES.ABOUTUS}>
                About Us
              </Link>

              <Link className="footer_links" to={ROUTES.CONTACTUS}>
                Contact Us
              </Link>
              {/* <a className="footer_links" href="/case-study">
                Case Study
              </a>
              <a className="footer_links" href="/blogs">
                Blogs
              </a> */}
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="footer_boxes d-flex flex-column">
              <h5 className="mb-4">Services</h5>
              <Link className="footer_links" to={ROUTES.AGENCY}>
                Agency
              </Link>
              <Link className="footer_links" to={ROUTES.ADVISORY}>
                Advisory
              </Link>
              <Link className="footer_links" to={ROUTES.TECHNOLOGYDEVELOPMENT}>
                Tech Development
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="footer_boxes d-flex flex-column">
              <h5 className="mb-4">Resources</h5>
              <Link className="footer_links" to={ROUTES.CASE_STUDY}>
                Case Studies
              </Link>
              <Link className="footer_links" to={ROUTES.BLOGS}>
                Blogs
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-7 col-md-4">
            <div className="footer_boxes d-flex flex-column">
              <h5 className="mb-4">Contact</h5>

              <div className="footer_links">+971 588647897 </div>
              <a className="footer_links" href="mailto:contact@socialeagle.co">
                contact@socialeagle.co
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom d-flex mt-4">
        <div className="container">
          <div className="d-flex justify-content-between footer_links_section">
            <div className="copy_right">
              <p>© 2025 Social Eagle. All rights reserved.</p>
            </div>
            <div className="footer_links_section">
              <Link className="footer_links" to={ROUTES.PRIVACY_POLICY}>
                Privacy Policy
              </Link>
              <Link className="footer_links" to={ROUTES.TERMS_AND_CONDITION}>
                Terms & condition
              </Link>
              {/* <a className='footer_links' href="">Cookie Policy</a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react'
 import images from "../../../../helpers/images"

const ContentSection = () => {
    return (
        <div id="about_top_content">
            <div className='advisory_parent_section'>
                <div className='agency_detail_heading'>
                    <div className='purpose_left section_padding' data-aos="zoom-in">
                        <h1 className='text-center text-white'>Community &<br className='break_tag' /> Mastermind Creation</h1>
                    </div>
                    <div className='container'>
                        <div className=''>
                            <div className='row'>
                                <div className='col-lg-5  my-auto'>
                                    <div className='advisory_details_content_left' data-aos="fade-right">
                                        <h2 className='heading_para text-white'>Building A Supportive  <br className='break_tag' />Community</h2>
                                        <p>Build strong connections and foster collaboration with our <span className='font-bold text-danger'>Community & Mastermind Creation</span> services.
                                            If you’re a coach or service provider, creating a strong community is essential for boosting
                                            sales.</p>
                                        <p>
                                            Our services help you cultivate a supportive environment where members can share ideas,
                                             provide feedback, and encourage one another, resulting in increased loyalty and referrals.</p>
                                    </div>
                                </div>
                                <div className='col-lg-5 mx-auto'>
                                    <div className='advisory_details_content_right' data-aos="fade-left">
                                        <img src={images.advisory_community_1} alt="../" className='w-100 Advisory_marketing_rght_img' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='section_padding'>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='advisory_details_content_right' data-aos="fade-up">
                                        <img src={images.advisory_community_2} alt="../" className='w-100 Advisory_marketing_left_img' />
                                    </div>
                                </div>
                                <div className='col-lg-5  my-auto mx-auto'>
                                    <div className='advisory_details_content_left' data-aos="fade-down">
                                        <h2 className='heading_para text-white'>Creating Mastermind  <br className='break_tag' />Groups For Accountability</h2>
                                        <p>In addition to community building, we assist in forming mastermind groups that promote 
                                            accountability and collaboration. These groups enable members to tackle challenges together,
                                            share insights, and reach their goals more effectively.</p>
                                        <p>
                                        By nurturing these connections, we empower your community to succeed collectively, driving growth for everyone involved.                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import images from "../../helpers/images";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OurTeam from "./OurTeam";

import CR1 from "../../assets/images/home/oc1.webp";
import CR2 from "../../assets/images/home/oc2.webp";
import CR3 from "../../assets/images/home/oc3.webp";
import CR4 from "../../assets/images/home/oc4.webp";
import CR5 from "../../assets/images/home/oc5.webp";
import CR6 from "../../assets/images/home/oc6.webp";
import CR7 from "../../assets/images/home/oc7.webp";
import ROUTES from "../../helpers/constants/routes";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Content = () => {
  const [Counteron, setCounterOn] = useState(true);
  const agencyData = [
    {
      name: "Social Media Management",
      url: `${ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MANAGEMENT}`,
      img: require("../../assets/images/home/agency/ag1.webp"),
    },
    {
      name: "Social Media Marketing",
      url: `${ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MARKETING}`,
      img: require("../../assets/images/home/agency/ag2.webp"),
    },
    {
      name: "Lead Generation",
      url: `${ROUTES.AGENCY_SERVICES.LEAD_GENERATION}`,
      img: require("../../assets/images/home/agency/ag3.webp"),
    },
    {
      name: "Pay Per Click (PPC)",
      url: `${ROUTES.AGENCY_SERVICES.PAY_PER_CLICK}`,
      img: require("../../assets/images/home/agency/ag4.webp"),
    },
    {
      name: "Performance Marketing",
      url: `${ROUTES.AGENCY_SERVICES.PERFORMANCE_MARKETING}`,
      img: require("../../assets/images/home/agency/ag5.webp"),
    },
    {
      name: "Digital Media Planning & Buying",
      url: `${ROUTES.AGENCY_SERVICES.DIGITAL_MEDIA_PLANNING_AND_BUYING}`,
      img: require("../../assets/images/home/agency/ag6.webp"),
    },
    {
      name: "SEO Services",
      url: `${ROUTES.AGENCY_SERVICES.SEARCH_ENGINE_OPTIMIZATION}`,
      img: require("../../assets/images/home/agency/ag7.webp"),
    },
    {
      name: "Content Marketing",
      url: `${ROUTES.AGENCY_SERVICES.CONTENT_MARKETING_SERVICE}`,
      img: require("../../assets/images/home/agency/ag8.webp"),
    },
    {
      name: "Email Marketing",
      url: `${ROUTES.AGENCY_SERVICES.EMAIL_MARKETING_SERVICE}`,
      img: require("../../assets/images/home/agency/ag9.webp"),
    },
    {
      name: "Automation",
      url: `${ROUTES.ADVISORY_SERVICES.MARKETING_PROCESS_AUTOMATION}`,
      img: require("../../assets/images/home/agency/ag10.webp"),
    },
    {
      name: "Creative Solutions",
      url: `${ROUTES.AGENCY_SERVICES.CREATIVE_SOLUTIONS}`,
      img: require("../../assets/images/home/agency/ag11.webp"),
    },
    {
      name: "Video Content Production",
      url: `${ROUTES.AGENCY_SERVICES.VIDEO_CONTENT_PRODUCTION}`,
      img: require("../../assets/images/home/agency/ag12.webp"),
    },
    {
      name: "Video Marketing",
      url: `${ROUTES.AGENCY_SERVICES.VIDEO_MARKETING}`,
      img: require("../../assets/images/home/agency/ag13.webp"),
    },
    {
      name: "Funnel Building & Marketing",
      url: `${ROUTES.AGENCY_SERVICES.FUNNEL_BUILDING_MARKETING}`,
      img: require("../../assets/images/home/agency/ag14.webp"),
    },
  ];
  const adivisoryData = [
    {
      name: "Marketing Strategy & Consulting",
      img: require("../../assets/images/home/agency/ad1.webp"),
      url: `${ROUTES.ADVISORY_SERVICES.MARKETING_STRATEGY_CONSULTING}`,
    },
    {
      name: "Marketing process & Automation",
      img: require("../../assets/images/home/agency/ad2.webp"),
      url: `${ROUTES.ADVISORY_SERVICES.MARKETING_PROCESS_AUTOMATION}`,
    },
    {
      name: "Brand Strategy & Consulting",
      img: require("../../assets/images/home/agency/ad3.webp"),
      url: `${ROUTES.ADVISORY_SERVICES.BRANDING_STRATEGY_CONSULTING}`,
    },
    {
      name: "Community & Mastermind crteation",
      img: require("../../assets/images/home/agency/ad4.webp"),
      url: `${ROUTES.ADVISORY_SERVICES.COMMUNITY_MASTERMIND_CREATION}`,
    },
  ];
  const techDevelopmentData = [
    {
      name: "Web & Mobile App Development",
      img: require("../../assets/images/home/agency/td1.webp"),
      routing_index: 0,
    },
    {
      name: "E-Com Custom Website Development",
      img: require("../../assets/images/home/agency/td2.webp"),
      routing_index: 1,
    },
    {
      name: "Shopify Development",
      img: require("../../assets/images/home/agency/td3.webp"),
      routing_index: 2,
    },
    {
      name: "WordPress Development",
      img: require("../../assets/images/home/agency/td4.webp"),
      routing_index: 3,
    },
    {
      name: "Web & Mobile App Maintenance",
      img: require("../../assets/images/home/agency/td5.webp"),
      routing_index: 4,
    },
    {
      name: "Machine Learning & AI Development",
      img: require("../../assets/images/home/agency/td6.webp"),
      routing_index: 5,
    },
    {
      name: "UI/UX Development",
      img: require("../../assets/images/home/agency/td7.webp"),
      routing_index: 6,
    },
  ];

  const resultsData = [
    { img: require("../../assets/images/home/or1.webp") },
    { img: require("../../assets/images/home/or2.webp") },
    { img: require("../../assets/images/home/or3.webp") },
    { img: require("../../assets/images/home/or4.webp") },
    { img: require("../../assets/images/home/or5.webp") },
    { img: require("../../assets/images/home/or6.webp") },
    { img: require("../../assets/images/home/or7.webp") },
    { img: require("../../assets/images/home/or10.webp") },
    { img: require("../../assets/images/home/or11.webp") },
    { img: require("../../assets/images/home/or12.webp") },
    { img: require("../../assets/images/home/or13.webp") },
  ];

  const casestudyHeading = [
    "Green Protein",
    "Horlicks",
    "YCB Academy",
    "ECG Academy",
  ];
  const [selectedTab, setSelectedTab] = useState("Green Protein");

  const ourClientsData = [
    {
      name: "Jamaal",
      about: "Freelance Digital Marketer",
      img: CR1,
      para: "Results in the coaching business were doubled through the application of a proven framework. The growth was achieved with strategies implemented by Social Eagle. Expertise and structured guidance ensured the outcome.",
    },
    {
      name: "Khanthini",
      about: "Psychologist & Founder of KV School",
      img: CR2,
      para: "A follower base grew from 1000 to over 200000 with fewer than 30 videos. This remarkable growth was achieved through the support of Social Eagle. Strategic methods ensured maximum impact.",
    },
    {
      name: "Suresh Radhakrishnan",
      about: "Entrepreneur | Business Coach | Content creator",
      img: CR3,
      para: "Consistent and predictable results were achieved for the online course. Effective strategies ensured sustained success. Structured approaches made the outcome reliable.",
    },
    {
      name: "Brian Mcbroom",
      about: "Executive Coach",
      img: CR6,
      para: "They helped grow my business by 169% using Facebook groups and funnels. Effective planning led to measurable success.",
    },
    {
      name: "Dominic",
      about: "CEO",
      img: CR4,
      para: "The client base and profitability were boosted by 267%. Strategic actions ensured substantial growth. Measurable outcomes reflected effective implementation.",
    },
    {
      name: "Iain",
      about: "CEO Digital courses",
      img: CR5,
      para: "The appointment rate was increased by 35% for the high-ticket sales closing business. Proven strategies ensured this improvement. Results reflected focused execution.",
    },
    {
      name: "Scott",
      about: "Co-Founder Agensi",
      img: CR7,
      para: "Digital courses were sold with a 300% profit in just 4 months. Strategic execution ensured exceptional outcomes. Structured planning drove remarkable success.",
    },
  ];

  const navigate = useNavigate();

  return (
    <div>
      <div className="what-we-do-section overflow-hidden py-5">
        <h1
          className="contact_banner_head text-light fs-50 mb-5"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          We transform Businesses into{" "}
          <span className="text-decoration-underline">Market Leaders</span>
        </h1>
        <div
          className="what-we-do-stroke"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          What do we do
        </div>
        <h1
          className="what-we-do-heading text-center mb-4"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          What Do We Do
        </h1>
        <h5
          className="text-light text-center mb-5"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          We help turn your Business into the Next Market Leader{" "}
          <br className="br-tag" /> in Dubai, faster than ever!
        </h5>
        <div className="container py-5">
          <div className="row">
            <div
              className="col-lg-2 mb-lg-0 mb-md-3 col-md-4 border-end-count"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(true)}
              >
                {Counteron && (
                  <div className="counter-flex-home">
                    <div>
                      <CountUp className="count-home-number" end={10} />
                      <span className="count-home-plus">+</span>
                    </div>
                    <h5 className="count-home-text">Years in the Industry</h5>
                  </div>
                )}
              </ScrollTrigger>
            </div>
            <div
              className="col-lg-2 col-md-4 mb-md-3 border-end-count"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(true)}
              >
                {Counteron && (
                  <div className="counter-flex-home">
                    <div>
                      <CountUp className="count-home-number" end={850} />
                      <span className="count-home-number">K</span>
                      <span className="count-home-plus">+</span>
                    </div>
                    <h5 className="count-home-text">Leads Generated</h5>
                  </div>
                )}
              </ScrollTrigger>
            </div>
            <div
              className="col-lg-2 col-md-4 mb-md-3 border-end-count"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(true)}
              >
                {Counteron && (
                  <div className="counter-flex-home">
                    <div>
                      <span className="count-home-number">$</span>
                      <CountUp className="count-home-number" end={3} />
                      <span className="count-home-number">.5M</span>
                      <span className="count-home-plus">+</span>
                    </div>
                    <h5 className="count-home-text">Ad Spends</h5>
                  </div>
                )}
              </ScrollTrigger>
            </div>
            <div
              className="col-lg-2 col-md-4 mb-md-3 border-end-count"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(true)}
              >
                {Counteron && (
                  <div className="counter-flex-home">
                    <div>
                      <span className="count-home-number">$</span>
                      <CountUp className="count-home-number" end={18} />
                      <span className="count-home-number">M</span>
                      <span className="count-home-plus">+</span>
                    </div>
                    <h5 className="count-home-text">Revenue Generated</h5>
                  </div>
                )}
              </ScrollTrigger>
            </div>
            <div
              className="col-lg-2 col-md-4 mb-md-3 border-end-count"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(true)}
              >
                {Counteron && (
                  <div className="counter-flex-home">
                    <div>
                      <CountUp className="count-home-number" end={300} />
                      <span className="count-home-plus">+</span>
                    </div>
                    <h5 className="count-home-text">
                      Clients Across the Globe
                    </h5>
                  </div>
                )}
              </ScrollTrigger>
            </div>
            <div
              className="col-lg-2 col-md-4 mb-md-3"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(true)}
              >
                {Counteron && (
                  <div className="counter-flex-home">
                    <div>
                      <CountUp className="count-home-number" end={25} />
                      <span className="count-home-number">K</span>
                      <span className="count-home-plus">+</span>
                    </div>
                    <h5 className="count-home-text">People Trained</h5>
                  </div>
                )}
              </ScrollTrigger>
            </div>
          </div>
        </div>
      </div>
      <div className="who-we-are-section overflow-hidden  py-5">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h1 className="what-we-do-heading  mb-4">Who We Are</h1>
              <p className="para-text-home-banner mb-2">
                We are an agile, ever-evolving, and constantly improving team
                where everyone belongs.
              </p>
              <p className="para-text-home-banner mb-3">
                Our mission is to empower businesses by:
              </p>
              <ul>
                <li className="text-light mb-2">
                  Defining your audience with precision.
                </li>
                <li className="text-light mb-2">
                  Refining your message to make a lasting impact.
                </li>
                <li className="text-light mb-4">
                  Identifying the right platforms to help you leverage the full
                  potential of the digital ecosystem.
                </li>
              </ul>

              <p className="para-text-home-banner mb-2">
                We combine creativity with analytics to drive growth and
                innovation.
              </p>
              <p className="para-text-home-banner mb-2">
                We help you get the right attention and take your business to
                escape velocity, propelling you ahead of the competition.
              </p>
              <p className="para-text-home-banner mb-3">
                With us, you’re not just keeping up with the market, you’re
                leading it!
              </p>
              <ol>
                <li className="text-light mb-2">
                  Emerging Social Media Company of the Year 2017
                </li>
                <li className="text-light mb-2">
                  Power Contributor ICT Academy
                </li>
                <li className="text-light mb-4">
                  10 Best Social Media Marketing Startups 2023
                </li>
              </ol>
              <Link to="/contact-us" className="text-decoration-none">
                <button
                  type="button"
                  className="btn btn-primary mb-lg-0 mb-4 button_red d-flex"
                >
                  Talk To Us
                  <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                </button>
              </Link>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div>
                <div className="what-we-do-stroke mt-3 ">Who We Are</div>
                <img
                  className="w-100 h-auto rounded"
                  src={images.WhoWeAre}
                  alt=""
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-services-home overflow-hidden  pt-4 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-10">
              <div className="row">
                <div
                  className="col-lg-5"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <div>
                    <h1 className="what-we-do-heading mb-3">Our Services</h1>
                    <h5 className="para-text-home-banner font-bold  mb-lg-0 mb-md-0 mb-4">
                      Apply If You Want To Increase Your Revenue In The Next 90
                      Days
                    </h5>
                  </div>
                </div>
                <div
                  className="col-lg-7"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <div className="">
                    <p className="para-text-home-banner mb-3">
                      Social Eagle delivers higher quality marketing strategy
                      and faster results.
                    </p>
                    <p className="para-text-home-banner mb-3">
                      Within 30 days you’ll have an actionable, winning
                      marketing strategy, and within 90 days you’ll be driving
                      results.
                    </p>
                    <p className="para-text-home-banner mb-3">
                      With Social Eagle, it's easier than ever to hit your
                      business goals and look great in your organization.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div
                    className="services-home-cards"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            01 <span className="ms-5">AGENCY</span>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p className=" para-inner-accordian mb-3">
                              {" "}
                              We take care of everything end-to-end to get the
                              best results using a Data-Driven and a
                              Performance-Based approach.
                            </p>
                            <div>
                              <OwlCarousel
                                className="owl-theme"
                                loop={true}
                                items={1}
                                dots={false}
                                nav={false}
                                margin={0}
                                center={true}
                                autoplay={true}
                                responsive={{
                                  360: {
                                    items: "1",
                                  },
                                  414: {
                                    items: "1",
                                  },
                                  670: {
                                    items: "2",
                                  },
                                  992: {
                                    items: "2",
                                  },
                                  1200: {
                                    items: "3",
                                  },
                                  1300: {
                                    items: "3",
                                  },
                                }}
                              >
                                {agencyData.map((item, index) => {
                                  return (
                                    <div
                                      className="bg-card-content-home"
                                      key={index}
                                    >
                                      <img
                                        className="bg-card-img-ser-home"
                                        src={item?.img}
                                        alt=""
                                      ></img>
                                      <div className="home-services-card">
                                        <h4 className="text-center font-medium text-white mb-3">
                                          {item?.name}
                                        </h4>
                                        <div
                                          onClick={() => navigate(item?.url)}
                                        >
                                          <i
                                            className={`circle_arrow_active fa-solid fa-arrow-right my-auto`}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </OwlCarousel>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            02 <span className="ms-5">ADVISORY</span>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p className=" para-inner-accordian mb-3">
                              {" "}
                              We help Businesses save time by 2x and Grow by 10x
                              with Battle-Tested and Proven Results.
                            </p>

                            <div>
                              <OwlCarousel
                                className="owl-theme"
                                loop={true}
                                items={1}
                                dots={false}
                                nav={false}
                                margin={0}
                                center={true}
                                autoplay={true}
                                responsive={{
                                  360: {
                                    items: "1",
                                  },
                                  414: {
                                    items: "1",
                                  },
                                  670: {
                                    items: "2",
                                  },
                                  992: {
                                    items: "2",
                                  },
                                  1200: {
                                    items: "3",
                                  },
                                  1300: {
                                    items: "3",
                                  },
                                }}
                              >
                                {adivisoryData.map((item, index) => {
                                  return (
                                    <div
                                      className="bg-card-content-home"
                                      key={index}
                                    >
                                      <img
                                        className="bg-card-img-ser-home"
                                        src={item?.img}
                                        alt=""
                                      ></img>
                                      <div className="home-services-card">
                                        <h4 className="text-center font-medium text-white mb-3">
                                          {item?.name}
                                        </h4>
                                        <div
                                          onClick={() => navigate(item?.url)}
                                        >
                                          <i
                                            className={`circle_arrow_active fa-solid fa-arrow-right my-auto`}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </OwlCarousel>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            03 <span className="ms-5">TECH DEVELOPMENT</span>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p className=" para-inner-accordian mb-3">
                              {" "}
                              We build Tech for Businesses and Brands through
                              cutting-edge designing, ML, AI, and help position
                              the Brand as a Global Leader.
                            </p>
                            <div>
                              <OwlCarousel
                                className="owl-theme"
                                loop={true}
                                items={1}
                                dots={false}
                                nav={false}
                                margin={0}
                                center={true}
                                autoplay={true}
                                responsive={{
                                  360: {
                                    items: "1",
                                  },
                                  414: {
                                    items: "1",
                                  },
                                  670: {
                                    items: "2",
                                  },
                                  992: {
                                    items: "2",
                                  },
                                  1200: {
                                    items: "3",
                                  },
                                  1300: {
                                    items: "3",
                                  },
                                }}
                              >
                                {techDevelopmentData.map((item, index) => {
                                  return (
                                    <div
                                      className="bg-card-content-home"
                                      key={index}
                                    >
                                      <img
                                        className="bg-card-img-ser-home"
                                        src={item?.img}
                                        alt=""
                                      ></img>
                                      <div className="home-services-card">
                                        <h4 className="text-center font-medium text-white mb-3">
                                          {item?.name}
                                        </h4>
                                        <NavLink
                                          to={ROUTES.TECHNOLOGYDEVELOPMENT}
                                          state={{ index: item?.routing_index }}
                                        >
                                          <i
                                            className={`circle_arrow_active fa-solid fa-arrow-right my-auto`}
                                          ></i>
                                        </NavLink>
                                      </div>
                                    </div>
                                  );
                                })}
                              </OwlCarousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-2"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div className="position-relative">
                <div className="what-we-do-stroke rotate-90-home">
                  OUR SERVICES
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-services-home overflow-hidden  services-home-cards">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-2 col-md-2 position-relative"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div>
                <div className="what-we-do-stroke rotate-270-home">
                  OUR PROCESS
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-md-10 ">
              <div className="row">
                <div
                  className="col-lg-5 my-auto"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <div>
                    <h1 className="what-we-do-heading mb-lg-0 mb-md-4 mb-4">
                      Our Process
                    </h1>
                  </div>
                </div>
                <div
                  className="col-lg-7 my-auto"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <div className="">
                    <h4 className="para-text-home-banner ">
                      Social Eagle delivers a higher quality strategy{" "}
                      <br className="br-tag" /> and faster results
                    </h4>
                  </div>
                </div>
                <div className="col-lg-9 mt-5">
                  <div
                    className="d-flex border-bottom-grey py-4 gap-lg-5 gap-3 justify-content-start align-items-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <img
                      className="our-process-img"
                      src={images.OurProcess1}
                      alt=""
                    ></img>
                    <div className="position-relative">
                      <h1 className="mb-0 our-process-content-para">
                        Apply to Work with us
                      </h1>
                      <img className="mark-img" src={images.Mark} alt=""></img>
                    </div>
                  </div>
                  <div
                    className="d-flex border-bottom-grey py-4 gap-lg-5 gap-3 justify-content-end align-items-center"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <div className="position-relative">
                      <img
                        className="mark-img-left"
                        src={images.Mark}
                        alt=""
                      ></img>
                      <h1 className="mb-0 ps-lg-5 ps-5 our-process-content-para">
                        See if we can work together
                      </h1>
                    </div>
                    <img
                      className="our-process-img"
                      src={images.OurProcess2}
                      alt=""
                    ></img>
                  </div>
                  <div
                    className="d-flex mb-4 py-4 gap-lg-5 gap-3 justify-content-start align-items-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <img
                      className="our-process-img"
                      src={images.OurProcess3}
                      alt=""
                    ></img>
                    <div className="position-relative">
                      <h1 className="mb-0 our-process-content-para">
                        We‘ll take it from there.
                      </h1>
                      <img className="mark-img" src={images.Mark} alt=""></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-services-home overflow-hidden  services-home-cards">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div
                className="results-section-home"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <h1 className="what-we-do-heading mb-4">Our Results</h1>
                <h4 className="para-text-home-banner ">
                  Everything we do is Quality-based <br className="br-tag" />{" "}
                  and Result-driven
                </h4>
              </div>
            </div>
            <div
              className="col-lg-6 my-auto"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div>
                <div className="what-we-do-stroke">OUR RESULTS</div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12 mt-5"
              data-aos="zoom-out"
              data-aos-duration="1000"
            >
              <OwlCarousel
                className="owl-theme"
                loop={true}
                items={1}
                dots={false}
                nav={false}
                margin={0}
                center={true}
                autoplay={true}
                responsive={{
                  360: {
                    items: "1",
                  },
                  414: {
                    items: "1",
                  },
                  670: {
                    items: "2",
                  },
                  992: {
                    items: "3",
                  },
                  1200: {
                    items: "5",
                  },
                  1300: {
                    items: "5",
                  },
                }}
              >
                {resultsData.map((item, index) => {
                  console.log(item, "itemitemitem");
                  return (
                    <div className="m-3" key={index}>
                      <img src={item.img} alt="" />
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <div className="what-we-do-section overflow-hidden  pb-5">
        <div
          className="what-we-do-stroke"
          data-aos="zoom-out"
          data-aos-duration="1000"
        >
          OUR WORKS
        </div>
        <h1
          className="what-we-do-heading text-center mb-4"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Our Works
        </h1>
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="row justify-content-center">
                <div
                  className="col-lg-4 mb-4 col-md-6 "
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <img
                    className="our-works-img"
                    src={require("../../assets/images/home/ow1.webp")}
                    alt=""
                  ></img>
                </div>
                <div
                  className="col-lg-4 mb-4  col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <img
                    className="our-works-img"
                    src={require("../../assets/images/home/ow2.webp")}
                    alt=""
                  ></img>
                </div>
                <div
                  className="col-lg-4 mb-4 col-md-6"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img
                    className="our-works-img"
                    src={require("../../assets/images/home/ow3.webp")}
                    alt=""
                  ></img>
                </div>
              </div>
              <div className="row justify-content-center">
                <div
                  className="col-lg-4 mb-4 col-md-6"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <img
                    className="our-works-img"
                    src={require("../../assets/images/home/ow4.webp")}
                    alt=""
                  ></img>
                </div>
                <div
                  className="col-lg-4 mb-4 col-md-6"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img
                    className="our-works-img"
                    src={require("../../assets/images/home/ow5.webp")}
                    alt=""
                  ></img>
                </div>
              </div>
              <div className="row justify-content-center">
                <div
                  className="col-lg-4 mb-4 col-md-6"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <img
                    className="our-works-img"
                    src={require("../../assets/images/home/ow6.webp")}
                    alt=""
                  ></img>
                </div>
                <div
                  className="col-lg-4 mb-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <img
                    className="our-works-img"
                    src={require("../../assets/images/home/ow7.webp")}
                    alt=""
                  ></img>
                </div>
                <div
                  className="col-lg-4 mb-4 col-md-6"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img
                    className="our-works-img"
                    src={require("../../assets/images/home/ow8.webp")}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-services-home   services-home-cards">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <div>
                <h1 className="what-we-do-heading mb-3">Our Case Studies</h1>
              </div>
              <div>
                <div className="casestudy-tab-section my-5">
                  {casestudyHeading.map((tab, index) => {
                    return (
                      <h5
                        key={index}
                        onClick={() => {
                          setSelectedTab(tab);
                        }}
                        className={`d-flex mb-0  ${
                          selectedTab === tab
                            ? "agency-tab-content line-anim-result active"
                            : "agency-tab-content"
                        }`}
                      >
                        {tab}
                      </h5>
                    );
                  })}
                </div>
                <div>
                  {selectedTab === "Horlicks" && (
                    <>
                      <img
                        className="case-study-img"
                        src={images.C1}
                        alt=""
                      ></img>
                      <p className="para-text-home-banner fs-5 mb-3">
                        We successfully launched and drove sales for new
                        subcategories by transitioning from offline to online
                        and educating customers, targeting diabetic consumers,
                        and promoting health benefits.
                      </p>
                      <div className="row py-5">
                        <div className="col-lg-2 col-md-4  border-end-count">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <span className="count-home-number-case">
                                    {" "}
                                    ₹
                                  </span>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={1}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    .8M
                                  </span>
                                </div>
                                <h5 className="count-home-text-case">
                                  in revenue
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                        <div className="col-lg-2 col-md-4 border-end-count">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={37}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    .5x
                                  </span>
                                </div>
                                <h5 className="count-home-text-case">ROAS</h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={32}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    %
                                  </span>
                                </div>
                                <h5 className="count-home-text-case">
                                  conversion rate
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary mb-5 button_red d-flex"
                        onClick={() => navigate("/case-study/horlicks")}
                      >
                        View More
                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                      </button>
                    </>
                  )}
                  {selectedTab === "YCB Academy" && (
                    <>
                      <img
                        className="case-study-img"
                        src={images.C3}
                        alt=""
                      ></img>
                      <p className="para-text-home-banner fs-5 mb-3">
                        We set up a lead generation funnel by transitioning from
                        offline to online and implementing an effective online
                        strategy for appointment-setting.
                      </p>
                      <div className="row py-5">
                        <div className="col-lg-2 col-md-4  border-end-count">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <span className="count-home-number-case">
                                    {" "}
                                    ₹
                                  </span>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={5}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    .9x
                                  </span>
                                </div>
                                <h5 className="count-home-text-case">ROAS</h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                        <div className="col-lg-2 col-md-4   border-end-count">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={16000}
                                    duration={2}
                                    delay={0}
                                  />
                                </div>
                                <h5 className="count-home-text-case">
                                  GBP return
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                        <div className="col-lg-2 col-md-4 ">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <span className="count-home-number-case">
                                    £
                                  </span>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={2700}
                                    duration={2}
                                    delay={0}
                                  />
                                </div>
                                <h5 className="count-home-text-case">
                                  ad spend
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate("/case-study/ycb-academy")}
                        type="button"
                        className="btn btn-primary mb-5 button_red d-flex"
                      >
                        View More
                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                      </button>
                    </>
                  )}
                  {selectedTab === "ECG Academy" && (
                    <>
                      <img
                        className="case-study-img"
                        src={images.C4}
                        alt=""
                      ></img>
                      <p className="para-text-home-banner fs-5 mb-3">
                        We acquired new customers by educating and engaging a
                        niche audience of nurses through gamification strategies
                        to boost retention.
                      </p>
                      <div className="row py-5">
                        <div className="col-lg-2 col-md-3   border-end-count">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={3}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    x
                                  </span>
                                </div>
                                <h5 className="count-home-text-case">ROAS</h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                        <div className="col-lg-3 col-md-5   border-end-count">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={25}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    %
                                  </span>
                                </div>
                                <h5 className="count-home-text-case">
                                  conversion rate increase
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                        <div className="col-lg-2 col-md-4 ">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <span className="count-home-number-case">
                                    $
                                  </span>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={6000}
                                    duration={2}
                                    delay={0}
                                  />
                                </div>
                                <h5 className="count-home-text-case">
                                  ad spend
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate("/case-study/ecg-academy")}
                        type="button"
                        className="btn btn-primary mb-5 button_red d-flex"
                      >
                        View More
                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                      </button>
                    </>
                  )}
                  {selectedTab === "Green Protein" && (
                    <>
                      <img
                        className="case-study-img"
                        src={images.C2}
                        alt=""
                      ></img>
                      <p className="para-text-home-banner fs-5 mb-3">
                        We boosted online sales by building a strong digital
                        presence and increasing Average Order Value (AOV)
                        through a focus on high-value products and growth
                        hacking strategies.
                      </p>
                      <div className="row py-5">
                        <div className="col-lg-2 col-md-3   border-end-count">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <span className="count-home-number-case">
                                    ₹
                                  </span>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={27}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    L
                                  </span>
                                </div>
                                <h5 className="count-home-text-case">
                                  in sales
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                        <div className="col-lg-3 col-md-5   border-end-count">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={3}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    .35x
                                  </span>
                                </div>
                                <h5 className="count-home-text-case">
                                  ROAS in 30 days
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                        <div className="col-lg-2 col-md-4 ">
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(true)}
                          >
                            {Counteron && (
                              <div className="counter-flex-home">
                                <div>
                                  <span className="count-home-number-case">
                                    ₹
                                  </span>
                                  <CountUp
                                    className="count-home-number-case"
                                    start={0}
                                    end={8}
                                    duration={2}
                                    delay={0}
                                  />
                                  <span className="count-home-number-case">
                                    L
                                  </span>
                                </div>

                                <h5 className="count-home-text-case">
                                  ad spend
                                </h5>
                              </div>
                            )}
                          </ScrollTrigger>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate("/case-study/green-protein")}
                        type="button"
                        className="btn btn-primary mb-5 button_red d-flex"
                      >
                        View More
                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-2"
              data-aos="zoom-out"
              data-aos-duration="1000"
            >
              <div>
                <div className="what-we-do-stroke fs-4rem rotate-90-home">
                  OUR CASE STUDIES
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="meet-our-team-home-bg  ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 my-auto">
              <h1
                className="what-we-do-heading mb-0"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                Meet Our Team
              </h1>
            </div>
            <div className="col-lg-6 my-auto">
              <p
                className="para-text-home-banner mb-0"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                Bringing decades of experience and in-field knowledge from an
                Industry-Leader perspective. Each Member being a master in their
                craft, they thrive in provide bleeding-edge strategies and
                results.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="our-team-home-section-bg  py-5">
        <OurTeam />
      </div>
      <div className="what-we-do-section pb-5  ">
        <div className="what-we-do-stroke">OUR CLIENTS SAY</div>
        <h1 className="what-we-do-heading text-center mb-4">Our Clients Say</h1>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-12">
              <OwlCarousel
                className="owl-theme"
                loop={true}
                items={1}
                dots={false}
                nav={false}
                margin={0}
                center={true}
                autoplay={true}
                responsive={{
                  360: {
                    items: "1",
                  },
                  414: {
                    items: "1",
                  },
                  670: {
                    items: "2",
                  },
                  992: {
                    items: "2",
                  },
                  1200: {
                    items: "3",
                  },
                  1300: {
                    items: "3",
                  },
                }}
              >
                {ourClientsData.map((item, index) => {
                  return (
                    <div className="m-3 our-case-study-home-bg" key={index}>
                      <div className="d-flex mb-3 align-items-center justify-content-start gap-3">
                        <img
                          className="ourCaseStudyProfile"
                          src={item?.img}
                          alt=""
                        />
                        <div>
                          <h5 className=" text-light font-medium mb-0">
                            {item?.name}
                          </h5>
                          <small className="text-light mb-0">
                            {item?.about}
                          </small>
                        </div>
                      </div>
                      <p className="text-light height-para-our-client mb-0">
                        {item?.para}
                      </p>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;

import React from "react";
import Logo from "../assets/images/logo.svg";
import Success from "../assets/images/thanksbatchimg.webp";
import CustomModal from "./CustomModal";

const ThankyouModal = ({ open, setOpen }) => {
    return (
        <div>
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content contact-modal-container w-50 m-auto">
                    <div className="modal-body p-0 ">
                        <div className="text-center pt-3">
                            <img loading="lazy" className="thnk-modal-logo" src={Logo} alt=""></img>
                        </div>
                        <hr />
                        <div className="container ">
                            <div className="row d-flex align-items-center justify-content-center  my-4">
                                <div className="container px-md-5 px-2">
                                    <div className="row justify-content-center  ">
                                        <div className="col-lg-12 justify-content-center">
                                            <div className="text-center">
                                                <img loading="lazy" className="success-img" src={Success} alt=""></img>
                                            </div>

                                            <h1 className="thankyou_text text-center mb-2">Thank You!</h1>
                                            <p className="mb-0 text-center">
                                                Your details has been successfully submitted.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="btn  mt-3  w-fit btn-primary contact-btn"
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            Okay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ThankyouModal;

const ROUTES = {
    HOME: '/',
    ABOUTUS: '/about-us',
    CONTACTUS: '/contact-us',
    TECHNOLOGYDEVELOPMENT: '/technology-development',
    ADVISORY: '/advisory',
    ADVISORY_SERVICES: {
        BRANDING_STRATEGY_CONSULTING: '/advisory/branding-strategy-and-consulting',
        MARKETING_STRATEGY_CONSULTING: '/advisory/marketing-strategy-and-consulting',
        MARKETING_PROCESS_AUTOMATION: '/advisory/marketing-process-and-automation',
        COMMUNITY_MASTERMIND_CREATION: '/advisory/community-and-mastermind-creation'
    },
    AGENCY: '/agency',
    AGENCY_SERVICES: {
        COMMUNITY_AND_MASTERMIND_CREATION: '/agency/community-and-mastermind-creation',
        SOCIAL_MEDIA_MANAGEMENT: '/agency/social-media-management',
        SOCIAL_MEDIA_MARKETING: '/agency/social-media-marketing',
        PAY_PER_CLICK: '/agency/pay-per-click',
        LEAD_GENERATION: '/agency/lead-generation',
        PERFORMANCE_MARKETING: '/agency/performance-marketing',
        DIGITAL_MEDIA_PLANNING_AND_BUYING: '/agency/digital-media-planning-and-buying',
        SEARCH_ENGINE_OPTIMIZATION: '/agency/search-engine-optimization',
        CONTENT_MARKETING_SERVICE: '/agency/content-marketing-service',
        EMAIL_MARKETING_SERVICE: '/agency/email-marketing-service',
        MARKETING_AUTOMATION: '/agency/marketing-automation',
        VIDEO_CONTENT_PRODUCTION: '/agency/video-content-production',
        CREATIVE_SOLUTIONS: '/agency/creative-solutions',
        VIDEO_MARKETING: '/agency/video-marketing',
        FUNNEL_BUILDING_MARKETING: '/agency/funnel-building-marketing'
    },
    BLOGS: '/blogs',
    BLOGS_SERVICE: {
        DIGITAL_MARKETING_FUTURES: '/blogs/digital-marketing-and-its-future',
        SIX_PILLAR_OF_DIGITAL_MARKETING: '/blogs/six-pillars-of-digital-marketing',
        TEN_REASON_FOR_DIGITAL_MARKETING: '/blogs/ten-reason-your-business-needs-digital-marketing-in-dubai',
        CHOOSE_RIGHT_DIGITAL_MARKETING: '/blogs/how-to-choose-right-digital-marketing-agency-in-dubai',
        DIGITAL_MARKETING_2025TRENDS: '/blogs/top-digital-marketing-trends-for-2025-business-in-dubai-must-follow',
        BOOST_YOUR_BUSINESS_WITH_SOCIALMEDIA: '/blogs/boost-your-business-with-social-media'
    },

    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITION: '/terms-and-condition',
    CASE_STUDY: '/case-study',
    CASE_STUDY_SERVICE: {
        GREEN_PROTEIN: '/case-study/green-protein',
        HORLICKS: '/case-study/horlicks',
        ECG_ACADEMY:'/case-study/ecg-academy',
        BLISSPADS:'/case-study/blisspads',
        SKINPOT_CO:'/case-study/skinpot-co',
        TECHGYAN:'/case-study/techgyan',
        YCB_ACADEMY:'/case-study/ycb-academy'

    }
};

export default ROUTES;






import React, { useEffect, useState } from "react";
import { NavLink, redirect, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import white_logo from "../../assets/images/white_logo.svg";
import dummyicon1 from "../../assets/images/dummy_icon1.webp";

import ROUTES from "../../helpers/constants/routes";
import case_study_img_1 from "../../assets/images/case_study/case_1.webp";
import case_study_img_2 from "../../assets/images/case_study/case_2.webp";
import case_study_img_3 from "../../assets/images/case_study/case_3.webp";
import case_study_img_4 from "../../assets/images/case_study/case_4.webp";
import case_study_img_5 from "../../assets/images/case_study/case_5.webp";
import case_study_img_6 from "../../assets/images/case_study/case_6.webp";
import case_study_img_7 from "../../assets/images/case_study/case_7.webp";
import case_study_img_8 from "../../assets/images/case_study/case_8.webp";

import digital_1 from "../../assets/images/nav_icons/digital_1.webp";
import digital_2 from "../../assets/images/nav_icons/digital_2.webp";
import digital_3 from "../../assets/images/nav_icons/digital_3.webp";
import digital_4 from "../../assets/images/nav_icons/digital_4.webp";
import digital_5 from "../../assets/images/nav_icons/digital_5.webp";
import digital_6 from "../../assets/images/nav_icons/digital_6.webp";
import digital_7 from "../../assets/images/nav_icons/digital_7.webp";
import digital_8 from "../../assets/images/nav_icons/digital_8.webp";
import digital_9 from "../../assets/images/nav_icons/digital_9.webp";
import digital_10 from "../../assets/images/nav_icons/digital_10.webp";

import advisory_1 from "../../assets/images/nav_icons/advisory_1.webp";
import advisory_2 from "../../assets/images/nav_icons/advisory_2.webp";
import advisory_3 from "../../assets/images/nav_icons/advisory_3.webp";
import advisory_4 from "../../assets/images/nav_icons/advisory_4.webp";

import tech_1 from "../../assets/images/nav_icons/tech_1.webp";
import tech_2 from "../../assets/images/nav_icons/tech_2.webp";
import tech_3 from "../../assets/images/nav_icons/tech_3.webp";
import tech_4 from "../../assets/images/nav_icons/tech_4.webp";
import tech_5 from "../../assets/images/nav_icons/tech_5.webp";
import tech_6 from "../../assets/images/nav_icons/tech_6.webp";
import tech_7 from "../../assets/images/nav_icons/tech_7.webp";

import last_1 from "../../assets/images/nav_icons/last_1.webp";
import last_2 from "../../assets/images/nav_icons/last_2.webp";
import last_3 from "../../assets/images/nav_icons/last_3.webp";
import last_4 from "../../assets/images/nav_icons/last_4.webp";

import images from "../../helpers/images";

const Navbar = () => {
  const ServiceTabs = [
    "Agency",
    // "Academy",
    "Advisory",
    "Technology Development",
  ];
  const [selectedServiceTabs, setSelectedServiceTabs] = useState(
    ServiceTabs[0]
  );

  const workwithusTabs = ["Case Study", "Blogs"];
  const [selectedWorkWithUsTabs, setSelectedWorkWithUsTabs] = useState(
    workwithusTabs[0]
  );

  const [scrolled, setScrolled] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleNavbar = () => setIsCollapsed(!isCollapsed);
  const closeNavbar = () => setIsCollapsed(true);
  let location = useLocation();
  console.log(location.pathname, "pathname");
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navigate = useNavigate();
  const handleCloseMenu = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 500);
  };
  const case_study_data = [
    {
      name: "Green Protein",
      img: case_study_img_1,
      redirect: "/case-study/green-protein",
    },
    {
      name: "Horlicks",
      img: case_study_img_2,
      redirect: "/case-study/horlicks",
    },
    {
      name: "Techgyan",
      img: case_study_img_3,
      redirect: "/case-study/techgyan",
    },
    {
      name: "ECG Academy",
      img: case_study_img_4,
      redirect: "/case-study/ecg-academy",
    },
    {
      name: "Skinpot Co",
      img: case_study_img_5,
      redirect: "/case-study/skinpot-co",
    },
    {
      name: "Blisspads",
      img: case_study_img_6,
      redirect: "/case-study/blisspads",
    },
    {
      name: "YCB Academy",
      img: case_study_img_7,
      redirect: "/case-study/ycb-academy",
    },
    // { name: "Social Eagle Academy", img: case_study_img_8, redirect: "/" },
  ];

  const technology_developement = [
    {
      img: tech_1,
      routing_index: 0,
      heading: "Web & Mobile App Development",
      content:
        "Build seamless, user-friendly web and mobile applications tailored to your needs.",
    },
    {
      img: tech_2,
      routing_index: 1,
      heading: "E-Com Custom Website Development",
      content:
        "Design and develop custom e-commerce websites that drive sales and enhance user engagement.",
    },
    {
      img: tech_3,
      routing_index: 2,
      heading: "Shopify Development",
      content:
        "Build and customize Shopify stores to boost your online business and maximize sales.",
    },
    {
      img: tech_4,
      routing_index: 3,
      heading: "WordPress Development",
      content:
        "Create dynamic, custom WordPress websites tailored to your brand and business needs.",
    },
    {
      img: tech_5,
      routing_index: 4,
      heading: "Web & Mobile App Maintenance",
      content:
        "Ensure peak performance with reliable web and mobile app maintenance services.",
    },
    {
      img: tech_6,
      routing_index: 5,
      heading: "Machine Learning & AI Development",
      content:
        "Transform your business with cutting-edge machine learning and AI solutions.",
    },
    {
      img: tech_7,
      routing_index: 6,
      heading: "UI/UX Development",
      content:
        "Design intuitive and engaging UI/UX to enhance user experiences and drive interaction.",
    },
  ];

  return (
    <div>
      <div
        className={`bg-light-nav-top ${
          scrolled ? "bg-light-nav-top-scrolled" : ""
        }`}
      >
        <div className="home-navbar">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <div className="container">
              <NavLink
                className="navbar-brand d-lg-block d-md-none d-none d-flex align-items-end"
                to="/"
              >
                {[
                  "/",
                  "/agency",
                  "/about-us",
                  "/case-study",
                  "/technology-development",
                ].some(
                  (path) =>
                    location.pathname.toLowerCase() === path ||
                    location.pathname.toLowerCase().startsWith("/agency/") ||
                    location.pathname.toLowerCase().startsWith("/case-study/") || 
                    location.pathname.toLowerCase().startsWith("/about-us/") ||
                    location.pathname.toLowerCase().startsWith("/technology-development/")
                ) ? (
                  <img className="logo-img" src={white_logo} alt="White Logo" />
                ) : null}
                {[
                  "/contact-us",
                  "/advisory",
                  "/privacy-policy",
                  "/blogs",
                  "/terms-and-condition",
                ].some(
                  (path) =>
                    location.pathname.toLowerCase() === path ||
                    location.pathname.toLowerCase().startsWith("/advisory/") ||
                    location.pathname.toLowerCase().startsWith("/blogs/") || 
                    location.pathname.toLowerCase().startsWith("/contact-us/")
                ) ? (
                  scrolled ? (
                    <img
                      className="logo-img"
                      src={white_logo}
                      alt="White Logo"
                    />
                  ) : (
                    <img className="logo-img" src={Logo} alt="Default Logo" />
                  )
                ) : null}
              </NavLink>
              <NavLink
                className="navbar-brand d-lg-none d-md-block d-block d-flex align-items-end"
                to="/"
              >
                <img className="logo-img" src={white_logo} alt="White Logo" />
              </NavLink>
              <button
                className="navbar-toggler my-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse justify-content-end lh-lg"
                id="main_nav"
              >
                <ul className="navbar-nav p-3 p-md-0 mx-auto">
                  <li className="nav-item ">
                    <NavLink
                      className={`nav-link  ${
                        scrolled ? "nav-link-scrolled" : ""
                      }`}
                      to={ROUTES.ABOUTUS}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li
                    className={`nav-item dropdown d-lg-block d-md-none d-none ktm-mega-menu `}
                  >
                    <a
                      className={`nav-link dropdown-toggle  ${
                        scrolled ? "nav-link-scrolled" : ""
                      }`}
                      href={ROUTES.AGENCY}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </a>
                    <div
                      className={`dropdown-menu ${
                        isOpen ? "" : "d-none"
                      } mega-menu p-3`}
                    >
                      <div className="mega-menu-inner container">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="">
                              <div className="tabs border-b">
                                {ServiceTabs.map((tab, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        selectedServiceTabs === tab
                                          ? "tab active  "
                                          : "tab "
                                      }
                                      onMouseOver={() => {
                                        setSelectedServiceTabs(tab);
                                      }}
                                      onClick={() => {
                                        if (
                                          selectedServiceTabs === "Advisory"
                                        ) {
                                          navigate(`${ROUTES.ADVISORY}`);
                                        } else if (
                                          selectedServiceTabs === "Agency"
                                        ) {
                                          navigate(`${ROUTES.AGENCY}`);
                                        } else if (
                                          selectedServiceTabs ===
                                          "Technology Development"
                                        ) {
                                          navigate(
                                            `${ROUTES.TECHNOLOGYDEVELOPMENT}`
                                          );
                                        }
                                      }}
                                    >
                                      <span>
                                        <img src={dummyicon1} alt="" />
                                      </span>
                                      <span className="my-auto ms-2">
                                        {tab}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="padd-left">
                              {selectedServiceTabs === "Agency" && (
                                <>
                                  <p className="text-white nav-right-heading mb-0">
                                    Digital Marketing
                                  </p>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MANAGEMENT}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_1}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Social Media Management
                                            </h6>
                                            <p>
                                              Let us handle your socials while
                                              you watch your audience grow.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MARKETING}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_2}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Social Media Marketing
                                            </h6>
                                            <p>
                                              Turn engagement into sales with
                                              targeted social campaigns.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.LEAD_GENERATION}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={last_1}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Lead Generation
                                            </h6>
                                            <p>
                                              Start Building Your Pipeline of
                                              Qualified Leads Today!
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.PAY_PER_CLICK}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_3}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Pay Per Click(PPC)
                                            </h6>
                                            <p>
                                              Scale fast with precision-driven
                                              PPC campaigns that deliver
                                              results.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.PERFORMANCE_MARKETING}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_4}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Performance Marketing
                                            </h6>
                                            <p>
                                              Grow your business with
                                              data-backed performance marketing.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.DIGITAL_MEDIA_PLANNING_AND_BUYING}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={last_2}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Digital Media Planning & Buying
                                            </h6>
                                            <p>
                                              Get strategic and stretch your ad
                                              budget further.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.SEARCH_ENGINE_OPTIMIZATION}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_5}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              SEO Services
                                            </h6>
                                            <p>
                                              Dominate search rankings and crush
                                              your competition.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.CONTENT_MARKETING_SERVICE}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_6}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Content Marketing
                                            </h6>
                                            <p>
                                              Engage, educate, and convert with
                                              powerful content.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.EMAIL_MARKETING_SERVICE}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={last_3}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Email Marketing
                                            </h6>
                                            <p>
                                              Drive revenue with emails your
                                              audience can’t ignore.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.MARKETING_AUTOMATION}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_7}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Marketing process & Automation
                                            </h6>
                                            <p>
                                              Automate your marketing and unlock
                                              explosive growth.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>

                                    <hr className="text-white mt-4 mb-4" />
                                  </div>
                                  <p className="text-white nav-right-heading mb-0">
                                    Branding & Designing
                                  </p>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.CREATIVE_SOLUTIONS}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_8}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Creative Solutions
                                            </h6>
                                            <p>
                                              Create a brand your audience will
                                              love and remember forever.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.VIDEO_CONTENT_PRODUCTION}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_9}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Video Content Production
                                            </h6>
                                            <p>
                                              Create videos that connect,
                                              engage, and drive action.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.VIDEO_MARKETING}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={last_4}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Video Marketing
                                            </h6>
                                            <p>
                                              Use video to inspire, engage, and
                                              grow your audience.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                    <div className="col-lg-4">
                                      <NavLink
                                        to={`${ROUTES.AGENCY_SERVICES.FUNNEL_BUILDING_MARKETING}`}
                                        className="text-decoration-none"
                                      >
                                        <div className="d-flex navleft_link mt-3">
                                          <div>
                                            <img
                                              src={digital_10}
                                              alt=""
                                              className="digital_icon"
                                            />
                                          </div>
                                          <div className="mt-2 ms-2">
                                            <h6 className="text-white mb-0">
                                              Funnel Building & Marketing
                                            </h6>
                                            <p>
                                              Guide your audience from click to
                                              customer effortlessly.
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                  </div>
                                </>
                              )}

                              {selectedServiceTabs === "Advisory" && (
                                <div className="row">
                                  <div className="col-lg-6">
                                    <NavLink
                                      to={`${ROUTES.ADVISORY_SERVICES.MARKETING_STRATEGY_CONSULTING}`}
                                      className="text-decoration-none"
                                    >
                                      <div className="d-flex navleft_link mt-3">
                                        <div>
                                          <img
                                            src={advisory_1}
                                            alt=""
                                            className="digital_icon"
                                          />
                                        </div>
                                        <div className="mt-2 ms-2">
                                          <h6 className="text-white mb-0">
                                            Marketing Strategy & Consulting
                                          </h6>
                                          <p>
                                            Build strategies that skyrocket your
                                            results.
                                          </p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>

                                  <div className="col-lg-6">
                                    <NavLink
                                      to={`${ROUTES.ADVISORY_SERVICES.MARKETING_PROCESS_AUTOMATION}`}
                                      className="text-decoration-none"
                                    >
                                      <div className="d-flex navleft_link mt-3">
                                        <div>
                                          <img
                                            src={advisory_2}
                                            alt=""
                                            className="digital_icon"
                                          />
                                        </div>
                                        <div className="mt-2 ms-2">
                                          <h6 className="text-white mb-0">
                                            Marketing process & Automation
                                          </h6>
                                          <p>
                                            Automate your marketing and unlock
                                            explosive growth.
                                          </p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>
                                  <div className="col-lg-6">
                                    <NavLink
                                      to={`${ROUTES.ADVISORY_SERVICES.BRANDING_STRATEGY_CONSULTING}`}
                                      className="text-decoration-none"
                                    >
                                      <div className="d-flex navleft_link mt-3">
                                        <div>
                                          <img
                                            src={advisory_3}
                                            alt=""
                                            className="digital_icon"
                                          />
                                        </div>
                                        <div className="mt-2 ms-2">
                                          <h6 className="text-white mb-0">
                                            Brand Strategy & Consulting
                                          </h6>
                                          <p>
                                            Create a brand your audience will
                                            love and remember forever.
                                          </p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>
                                  <div className="col-lg-6">
                                    <NavLink
                                      to={`${ROUTES.ADVISORY_SERVICES.COMMUNITY_MASTERMIND_CREATION}`}
                                      className="text-decoration-none"
                                    >
                                      <div className="d-flex navleft_link mt-3">
                                        <div>
                                          <img
                                            src={advisory_4}
                                            alt=""
                                            className="digital_icon"
                                          />
                                        </div>
                                        <div className="mt-2 ms-2">
                                          <h6 className="text-white mb-0">
                                            Community & Mastermind crteation
                                          </h6>
                                          <p>
                                            Foster connections and growth with
                                            engaging community and mastermind
                                            creation services.
                                          </p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>
                                </div>
                              )}
                              {selectedServiceTabs ===
                                "Technology Development" && (
                                <div className="row">
                                  {technology_developement.map(
                                    (item, index) => (
                                      <div className="col-lg-6" key={index}>
                                        <NavLink
                                          to={ROUTES.TECHNOLOGYDEVELOPMENT}
                                          state={{ index: item?.routing_index }}
                                          className="text-decoration-none"
                                          onClick={handleCloseMenu}
                                        >
                                          <div className="d-flex navleft_link mt-3">
                                            <div>
                                              <img
                                                src={item?.img}
                                                alt=""
                                                className="digital_icon"
                                              />
                                            </div>
                                            <div className="mt-2 ms-2">
                                              <h6 className="text-white mb-0">
                                                {item?.heading}
                                              </h6>
                                              <p>{item?.content}</p>
                                            </div>
                                          </div>
                                        </NavLink>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between navbar_footer">
                            <div className="d-flex">
                              <div className="me-5">
                                {" "}
                                <i class="fa-regular fa-envelope text-white me-2"></i>
                                <a
                                  href="mailto:flyhigh@socialeagle.in"
                                  className="mails_tags"
                                >
                                  flyhigh@socialeagle.in
                                </a>
                              </div>
                              <div>
                                {" "}
                                <i class="fa-solid fa-phone text-white me-2"></i>
                                <a
                                  href="tel:+91 8428421222"
                                  className="mails_tags"
                                >
                                  +91 8428421222
                                </a>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="me-2 social_media">
                                <a
                                  href="https://www.instagram.com/socialeagleofficial/?hl=en"
                                  target="_blank"
                                >
                                  <i class="fa-brands fs-6 text-light fa-instagram"></i>
                                </a>
                              </div>
                              {/* <div className="me-2 social_media">
                                <a
                                  href="https://www.instagram.com/socialeagleofficial/?hl=en"
                                  target="_blank"
                                >
                                  <i class="fa-brands fs-6 text-light fa-whatsapp"></i>
                                </a>
                              </div> */}
                              <div className="me-2 social_media">
                                <a
                                  href="https://www.youtube.com/@socialeagleofficial1155"
                                  target="_blank"
                                >
                                  <i class="fa-brands fs-6 text-light fa-youtube"></i>
                                </a>
                              </div>
                              <div>
                                <a
                                  href="https://www.linkedin.com/company/socialeagle/"
                                  target="_blank"
                                >
                                  <i class="fa-brands fs-6 text-light fa-linkedin-in"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown  d-lg-none d-md-block  d-block">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Services
                    </a>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/Agency"
                        >
                          Agency
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/Advisory"
                        >
                          Advisory
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to={`${ROUTES.TECHNOLOGYDEVELOPMENT}`}
                        >
                          Technology Development
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown d-lg-block d-md-none d-none ktm-mega-menu">
                    <a
                      className={`nav-link dropdown-toggle ${
                        scrolled ? "nav-link-scrolled" : ""
                      }`}
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Resources
                    </a>

                    <div className="dropdown-menu mega-menu p-3">
                      <div className="mega-menu-inner container">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="">
                              <div className="tabs border-b">
                                {workwithusTabs.map((tab, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        selectedWorkWithUsTabs === tab
                                          ? "tab active  "
                                          : "tab "
                                      }
                                      onMouseOver={() => {
                                        setSelectedWorkWithUsTabs(tab);
                                      }}
                                      onClick={() => {
                                        if (
                                          selectedWorkWithUsTabs ===
                                          "Case Study"
                                        ) {
                                          navigate(ROUTES.CASE_STUDY);
                                        } else if (
                                          selectedWorkWithUsTabs === "Blogs"
                                        ) {
                                          navigate(ROUTES.BLOGS);
                                        }
                                      }}
                                    >
                                      <span>
                                        <img src={dummyicon1} alt="" />
                                      </span>
                                      <span className="my-auto ms-2">
                                        {tab}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            {selectedWorkWithUsTabs === "Case Study" && (
                              <div>
                                <div className="row">
                                  <div className="col-lg-9">
                                    <div className="row">
                                      {case_study_data.map((item, i) => (
                                        <div className="col-lg-6 g-4" key={i}>
                                          <NavLink
                                            to={item?.redirect}
                                            className="text-decoration-none"
                                          >
                                            <div className="d-flex">
                                              <div className="me-3">
                                                <img
                                                  src={item?.img}
                                                  alt=""
                                                  className="w-auto case_nav_image"
                                                />
                                              </div>
                                              <div className="">
                                                <h6 className="text-white">
                                                  {item?.name}
                                                </h6>
                                                <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                              </div>
                                            </div>
                                          </NavLink>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedWorkWithUsTabs === "Blogs" && (
                              <div className="">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <NavLink
                                      to={
                                        ROUTES.BLOGS_SERVICE
                                          .DIGITAL_MARKETING_FUTURES
                                      }
                                      className="text-decoration-none"
                                    >
                                      <div className="navleft_link ">
                                        <div className="me-2">
                                          <img
                                            src={images.blog_1}
                                            alt=""
                                            className="nav_blog_image"
                                          />
                                        </div>
                                        <div className="mt-3">
                                          <h6 className="text-white">
                                            Digital Marketing and Its Future
                                          </h6>
                                          <p className="text-white">
                                            In today’s digital-first world,
                                            marketing has undergone a dramatic
                                            transformation.
                                          </p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>

                                  <div className="col-lg-4">
                                    <NavLink
                                      to={
                                        ROUTES.BLOGS_SERVICE
                                          .SIX_PILLAR_OF_DIGITAL_MARKETING
                                      }
                                      className="text-decoration-none"
                                    >
                                      <div className="navleft_link  ">
                                        <div className="me-2">
                                          <img
                                            src={images.blog_2}
                                            alt=""
                                            className="nav_blog_image"
                                          />
                                        </div>
                                        <div className="mt-3">
                                          <h6 className="text-white">
                                            6 Pillars of Digital Marketing
                                          </h6>
                                          <p className="text-white">
                                            A Guide for Businesses in Dubai,
                                            United Arab Emirates
                                          </p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>

                                  <div className="col-lg-4 my-auto ">
                                    <button
                                      type="button"
                                      className="btn btn-primary button_red d-flex mx-auto"
                                      onClick={() => navigate(ROUTES.BLOGS)}
                                    >
                                      View All
                                      <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between navbar_footer">
                        <div className="d-flex">
                          <div className="me-5">
                            {" "}
                            <i class="fa-regular fa-envelope text-white me-2"></i>
                            <a
                              href="mailto:flyhigh@socialeagle.in"
                              className="mails_tags"
                            >
                              flyhigh@socialeagle.in
                            </a>
                          </div>
                          <div>
                            {" "}
                            <i class="fa-solid fa-phone text-white me-2"></i>
                            <a href="tel:+91 8428421222" className="mails_tags">
                              +91 8428421222
                            </a>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="me-2 social_media">
                            <a
                              href="https://www.instagram.com/socialeagleofficial/?hl=en"
                              target="_blank"
                            >
                              <i class="fa-brands fs-6 text-light fa-instagram"></i>
                            </a>
                          </div>
                          {/* <div className="me-2 social_media">
                            <a
                              href="https://www.instagram.com/socialeagleofficial/?hl=en"
                              target="_blank"
                            >
                              <i class="fa-brands fs-6 text-light fa-whatsapp"></i>
                            </a>
                          </div> */}
                          <div className="me-2 social_media">
                            <a
                              href="https://www.youtube.com/@socialeagleofficial1155"
                              target="_blank"
                            >
                              <i class="fa-brands fs-6 text-light fa-youtube"></i>
                            </a>
                          </div>
                          <div>
                            <a
                              href="https://www.linkedin.com/company/socialeagle/"
                              target="_blank"
                            >
                              <i class="fa-brands fs-6 text-light fa-linkedin-in"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown mb-3  d-lg-none d-md-block  d-block">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Resources
                    </a>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to={ROUTES.CASE_STUDY}
                        >
                          Case Study
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to={ROUTES.BLOGS}
                        >
                          Blogs
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item d-lg-block d-md-none d-none dropdown ktm-mega-menu">
                    <a
                      className={`nav-link  ${
                        scrolled ? "nav-link-scrolled" : ""
                      }`}
                      href={ROUTES.CASE_STUDY}
                    >
                      Case Study
                    </a>
                  </li> */}
                </ul>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-primary button_red d-flex"
                    onClick={() => navigate(`${ROUTES.CONTACTUS}`)}
                  >
                    Contact Us
                    <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

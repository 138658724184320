import React, { useState } from "react";
import Navbar from "../../component/Navbar/Navbar";
import Footer from "../../component/Footer/Footer";
import CaseStudyBanner from "../../component/CaseStudy/CaseStudyBanner";
import casestudy_details from "../../assets/images/case_study_img/csd7.webp";
import casestudy_details_top from "../../assets/images/case_study_img/case-study-details-img-7.webp";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const YCBacademy = () => {
  const [Counteron, setCounterOn] = useState(true);

  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    "10 years of offline experience, no digital presence.",
    "Struggled due to COVID-19 challenges and needed a transition to online.",
  ];

  const solution = [
    "Mapped and implemented a comprehensive online presence strategy.",
    "Transformed the offline business to an automated online model in 60 days.",
  ];

  return (
    <div>
      <div className="aboutus_main">
        <Navbar />
        <div className="csd-scroll">
          <CaseStudyBanner />
        </div>

        <section className="" id="about_top_content">
          <div className="purposeof_se_parent_section csd-scroll">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="">
                    <h1
                      className="text-center headings_text_black"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      YCB Academy
                    </h1>
                    <p
                      className="text-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      Setup an appointment-setting funnel and automate lead
                      bookings.
                    </p>
                    <div
                      className="d-flex justify-content-center"
                      data-aos="zoom-out"
                      data-aos-duration="1000"
                    >
                      <img
                        src={casestudy_details_top}
                        alt="Green Protein"
                        className="csd-image-top"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="meetour_team_parent_section">
              <div className="">
                <h1
                  className="mt-4 text-center text-white csd-text"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  Challenges
                </h1>
                <div className="container">
                  <div className="row justify-content-start">
                    <div
                      className="col-lg-5"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <div className="">
                        <img
                          src={casestudy_details}
                          alt="Green Protein"
                          className="csd-image"
                        />
                      </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div
                      className="col-lg-5 col-md-12 my-auto"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      {/* <div className='csd-line'>
                                        <div className='csd-card'>
                                            <p className='text-white'>1</p>
                                        </div>
                                        <div>
                                        <p className='text-white ms-3'>A new brand entering the fitness and supplement market.</p>
                                        </div>
                                    </div> */}
                      <div className="csd-list-container my-lg-0 my-md-5 my-5">
                        {items.map((item, index) => (
                          <div
                            key={index}
                            className={`csd-list-item ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onMouseEnter={() => setActiveIndex(index)}
                          >
                            <div className="number">{index + 1}</div>
                            <div className="content">{item}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <h1
                    className="text-center text-white csd-sol-text"
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                  >
                    Solutions
                  </h1>
                  <div className="row justify-content-center gap-5 csd-mobile csd-tab">
                    <div className="col-lg-11">
                      <div className="row csd-list-container my-lg-0 my-md-5 my-5">
                        {solution.map((item, index) => (
                          <div
                            className="col-lg-6"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                          >
                            <div
                              key={index}
                              className={`our-case-study-home-bg csd-sol-list-item ${
                                activeIndex === index ? "active" : ""
                              }`}
                              onMouseEnter={() => setActiveIndex(index)}
                              onMouseLeave={() => setActiveIndex(null)}
                            >
                              <div className="number">{index + 1}</div>
                              <div className="content">{item}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row justify-content-center">
                    <h1
                      className="text-white text-center csd-result"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      Results
                    </h1>
                    <div className="row justify-content-center csd-result-num">
                      <div
                        className="col-lg-4 col-md-4  border-end-count"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <ScrollTrigger
                          onEnter={() => setCounterOn(true)}
                          onExit={() => setCounterOn(true)}
                        >
                          {Counteron && (
                            <div className="counter-flex-home">
                              <div>
                                <CountUp
                                  className="csd-count-home-number-case"
                                  start={0}
                                  end={2700}
                                  duration={2}
                                  delay={0}
                                />
                                <span className="csd-count-home-number-case">
                                  GBP
                                </span>
                              </div>
                              <h5 className="count-home-text-case">Ad Spend</h5>
                            </div>
                          )}
                        </ScrollTrigger>
                      </div>
                      <div
                        className="col-lg-4 col-md-4 border-end-count"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <ScrollTrigger
                          onEnter={() => setCounterOn(true)}
                          onExit={() => setCounterOn(true)}
                        >
                          {Counteron && (
                            <div className="counter-flex-home">
                              <div>
                                <CountUp
                                  className="csd-count-home-number-case"
                                  start={0}
                                  end={16000}
                                  duration={2}
                                  delay={0}
                                />
                                <span className="csd-count-home-number-case">
                                  GBP
                                </span>
                              </div>
                              <h5 className="count-home-text-case">Return </h5>
                            </div>
                          )}
                        </ScrollTrigger>
                      </div>
                      <div
                        className="col-lg-4 col-md-4 "
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <ScrollTrigger
                          onEnter={() => setCounterOn(true)}
                          onExit={() => setCounterOn(true)}
                        >
                          {Counteron && (
                            <div className="counter-flex-home">
                              <div>
                                <span className="csd-count-home-plus"> ₹</span>
                                <CountUp
                                  className="csd-count-home-number-case"
                                  start={0}
                                  end={5}
                                  duration={2}
                                  delay={0}
                                />
                                <span className="csd-count-home-number-case">
                                  .9x
                                </span>
                              </div>
                              <h5 className="count-home-text-case">ROAS</h5>
                            </div>
                          )}
                        </ScrollTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default YCBacademy;

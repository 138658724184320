import React, { useState } from "react";

import about_purpose_image from "../../assets/images/about_purpose_image.webp";
import eagle_image from "../../assets/images/eagle_image.webp";
import transparency_icon from "../../assets/images/transparency_icon.webp";
import wrk_life_icon from "../../assets/images/wrk_life_icon.webp";
import diversity_icon from "../../assets/images/diversity_icon.webp";
import recognition_icon from "../../assets/images/recognition_icon.webp";
import creativity_icon from "../../assets/images/creativity_icon.webp";
import connected_culture_icon from "../../assets/images/connected_culture_icon.webp";
import psycologic_icon from "../../assets/images/psycologic_icon.webp";
import purpose_icon from "../../assets/images/purpose_icon.webp";
import mentorship_icon from "../../assets/images/mentorship_icon.webp";

import we_connected_back_img from "../../assets/images/we_connected_back_img.webp";

import vib1 from "../../assets/images/vibe/vibe1.webp";
import vib2 from "../../assets/images/vibe/vibe2.webp";
import vib3 from "../../assets/images/vibe/vibe3.webp";
import vib4 from "../../assets/images/vibe/vibe4.webp";
import vib5 from "../../assets/images/vibe/vibe5.webp";
import vib6 from "../../assets/images/vibe/vibe6.webp";
import vib7 from "../../assets/images/vibe/vibe7.webp";
import vib8 from "../../assets/images/vibe/vibe8.webp";
import vib9 from "../../assets/images/vibe/vibe9.webp";

//
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OurTeamCard from "./OurTeamCard";
import { Link } from "react-router-dom";
import {
  teamData,
  teamData2,
  teamData3,
  teamData4,
  teamData5,
  teamData6,
} from "./MeetOurTeamContent";

import leadership_image from "../../assets/images/leadership_image.webp";


const AboutUsContent = () => {
  const [MeetOurteam, SetMeetOurteam] = useState("Prime Experts");
  // const team_tab = ["Prime Experts", "Experts Sessions", "Special Lectures"];
  const team_tab = [
    { name: "Prime Experts", nav: "expert-1" },
    { name: "Experts Experts", nav: "expert-2" },
    { name: "Special Experts", nav: "expert-3" },
  ];
  const our_philosophy_array = [
    "Add Value",
    "Keep it Simple",
    "Think like a customer",
    "Trust people",
    "It is okay to make mistakes, but don’t repeat the same mistakes again",
    "Collaborate like an Army and Believe in Teamwork",
    "Take Pride for doing a Great Job",
    "Basics First and Specifics Later",
    "Be prepared for Continuous Learning",
    "Think on Paper",
    "Clients are Human, Understand their Emotions",
    "Don’t Assume, Just Ask",
    "Add Value",
    "Keep it Simple",
    "Think like a customer",
    "Trust people",
    "It is okay to make mistakes, but don’t repeat the same mistakes again",
    "Collaborate like an Army and Believe in Teamwork",
    "Take Pride for doing a Great Job",
    "Basics First and Specifics Later",
    "Be prepared for Continuous Learning",
    "Think on Paper",
    "Clients are Human, Understand their Emotions",
    "Don’t Assume, Just Ask",
  ];
  const our_philosophy = [...our_philosophy_array, ...our_philosophy_array];
  const Work_Culture_array = [
    "We are human only through the humanity of others",
    "We are people through other people",
    "We cannot be fully human alone",
    "We are human only through the humanity of others",
    "We are people through other people",
    "We cannot be fully human alone",
    "We are human only through the humanity of others",
    "We are people through other people",
    "We cannot be fully human alone",
  ];
  const Work_Culture = [...Work_Culture_array, ...Work_Culture_array];
  const images = [vib1, vib2, vib3, vib4, vib5, vib6, vib7, vib8, vib9];
  const loopImages = [...images, ...images];

  const [hoveredIndex, setHoveredIndex] = useState(null);
  // const [isShowcard, setShowcard] = useState(false);

  const handleMove = (id) => {
    document.getElementById(id);
  };
  return (
    <div id="about_top_content">
      <div className="purposeof_se_parent_section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="purpose_left" data-aos="fade-down-right">
                <h1>
                  Purpose
                  <br className="break_tag" />
                  Of Social Eagle
                </h1>
              </div>
            </div>
            <div className="col-lg-7" data-aos="fade-left" data-aos-delay="300">
              <div className="purpose_right">
                <p>
                  At our core, we strive to enrich lives by bringing unique
                  value.
                </p>
                <p>
                  Our aim is to elevate businesses by expanding their reach,
                  delivering valuable
                  <br className="break_tag" />
                  connections, and improving revenue streams.{" "}
                </p>
                <p>
                  We're dedicated to ensuring that business owners find joy and
                  success in their <br className="break_tag" />
                  business, creating a real impact in the lives the both of us
                  touch
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-11 ">
            <div className="mt-5 position-relative">
              <p
                className="purpose_img_top_content"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                On A Mission To Add Value And Make A<br className="break_tag" />
                Difference In People Lives
              </p>
              <img
                src={about_purpose_image}
                alt=""
                className="purpose_image mt-2"
              />
              <p
                className="purpose_img_bottom_content"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Greater Reach + Quality Leads + Increased Sales = Happy Business
                Owner
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="eagle_img_parent_section">
        <div className="container">
          <div className="section_padding">
            <div className="row">
              <div className="col-lg-5">
                <div data-aos="fade-right">
                  <img src={eagle_image} alt="" className="eagle_image" />
                </div>
              </div>
              <div className="col-lg-7 my-auto">
                <div
                  className="eagle_section_right ps-lg-5 ps-2"
                  data-aos="fade-left"
                  data-aos-delay="300"
                >
                  <p>
                    These aren't just words; they're the fuel behind our
                    success.
                    <br className="break_tag" />
                    At <span className="text-danger">Social Eagle</span>, we're
                    on a mission to transform Ambition into
                    <br className="break_tag" /> Achievement
                  </p>
                  <p>
                    Dreams become reality through Determination, Leadership, and
                    a<br className="break_tag" />
                    Competitive Spirit.
                    <br className="break_tag" /> We don't settle for less than
                    first place.
                  </p>
                  <p>
                    Our pioneering digital transformation embraces fresh
                    methodologies, <br className="break_tag" />
                    ensuring excellence isn't an option; it's our standard.
                  </p>
                  <p className="text-danger pb-0">Challenges?</p>
                  <p className="text-danger">They trigger miracles.</p>
                  <p>
                    We thrive in the age of Creative Minds, where change leads
                    to <br className="break_tag" />
                    opportunity.{" "}
                  </p>
                  <p>
                    We're committed to ethical practices, ensuring our work
                    reflects our
                    <br className="break_tag" /> values and principles.
                    <br className="break_tag" />
                    We thrive on Innovation and Creativity with an eye for
                    Excellence.
                  </p>
                  <p>
                    Through transformative Leadership, we positively contribute
                    to Faith and <br className="break_tag" />
                    Trust, Hope and Competitiveness.
                    <br className="break_tag" />
                    In our <span className="text-danger">#EagleSpirit</span>, we
                    achieve our Ambitious Goals through a Scientific{" "}
                    <br className="break_tag" />
                    approach through Technology by taking Initiatives and Daily
                    Action.
                  </p>
                  <p>
                    Join the Journey towards Excellence. Let's raise the
                    standards together.
                  </p>
                  <div className="d-flex">
                    <Link to="/contact-us" className="link_tag">
                      <button type="button" className="button_red d-flex mt-4">
                        <span>Fly With Us</span>
                        <div className="button_red_arrow">
                          <i class="fa-solid fa-arrow-right contact_arrow  ms-2"></i>
                        </div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="philosophy_parent_section section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="purpose_left">
                <h1 data-aos="fade-down-right">Our Philosophy</h1>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 my-auto"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <div className="purpose_right text-start">
                <p className="pb-0">
                  A philosophy that supports collectivism over individualism.
                </p>
                <p className="mb-0 pb-0">
                  Ubuntu asserts that society gives human beings their humanity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="our_philosophy_ubuntu"
        data-aos="fade-down"
        data-aos-delay="300"
      >
        <div className="container">
          <p>'I Am Because We Are': The African Philosophy of Ubuntu</p>
        </div>
      </div>
      <div className="vertical_corosel_parent_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div class="vertical_marque blur">
                <h4 className="text-center">
                  ‘ONE FOR ALL’ AND ‘ALL FOR ONE’{" "}
                </h4>
                <ul class="slider">
                  {our_philosophy.map((item, index) => (
                    <li>
                      <p>{item} </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="meetour_team_parent_section section_padding">
        <div className="container">
          <div className="">
            <div>
              <h1
                className="text-white text-center mb-lg-0 mb-md-0 mb-4"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                Meet our team
              </h1>
            </div>
          </div>
          <div>
            <div id="expert-1">
              <OurTeamCard teamData={teamData} />
              <OurTeamCard teamData={teamData2} />
              <OurTeamCard teamData={teamData3} />
              <OurTeamCard teamData={teamData4} />
              <OurTeamCard teamData={teamData5} />
              <OurTeamCard teamData={teamData6} />
            </div>
          </div>
        </div>
      </div>
      <div className="vertical_corosel_parent_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div class="vertical_marque blur">
                <h4 className="text-center">WORK CULTURE</h4>
                <ul class="slider2">
                  {Work_Culture.map((item, index) => (
                    <li>
                      <p>{item} </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="we_connted_section position-relative">
        <div className="row" data-aos="fade-up" data-aos-delay="300">
          <div className="col-lg-12">
            <div className="we_connect_div">
              <img
                src={we_connected_back_img}
                alt=""
                className="we_connect_backimg"
              />
              <p className="we_connect_para">
                We are all connected and one can only grow and progress
                <br className="break_tag" /> through the growth and progression
                of others
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="values_section_parent_div section_padding">
        <div className="container">
          {/* <div className='section_padding_bottom'>
            <div className='row'>
              <div className='col-lg-6'>
                <h2 className='headings_text_white mb-md-3'>Value Creation Enabled  <br className='break_tag' />By Digital Transformation</h2>
              </div>
              <div className='col-lg-5'>
                <p className='text-white'>We believe in "Transforming Marketing Through Innovation and Technology" to deliver measurable value. Digital transformation goes beyond adopting new tools; it’s about creating value, solving problems, and meeting the dynamic needs of customers. Here’s how we help you achieve that</p>
              </div>
            </div>
          </div> */}
          <div
            className="row justify-content-between"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <div className="col-lg-3">
              <div className="our_value_child_div mt-5">
                <div className="icon_div">
                  <img
                    src={transparency_icon}
                    alt=".."
                    className="our_value_icon"
                  />
                </div>
                <div className="our_value_content">
                  <h6>Transparency</h6>
                  <p className="text-white">
                    We value open communication. Through regular updates, team
                    meetings, and newsletters, we keep everyone in the loop and
                    build trust.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="our_value_child_div mt-5">
                <div>
                  <img
                    src={wrk_life_icon}
                    alt=".."
                    className="our_value_icon"
                  />
                </div>
                <div className="our_value_content">
                  <h6>Work-Life Balance</h6>
                  <p className="text-white">
                    We prioritize our team's well-being, encouraging a balance
                    that keeps our creative minds refreshed and focused.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="our_value_child_div mt-5">
                <div>
                  <img
                    src={diversity_icon}
                    alt=".."
                    className="our_value_icon"
                  />
                </div>
                <div className="our_value_content">
                  <h6>Diversity and Inclusion</h6>
                  <p className="text-white">
                    Different perspectives fuel our creativity. We embrace
                    diversity to drive innovation in our digital strategies.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div
            className="row justify-content-between"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <div className="col-lg-3">
              <div className="our_value_child_div value_margintop">
                <div>
                  <img
                    src={recognition_icon}
                    alt=".."
                    className="our_value_icon"
                  />
                </div>
                <div className="our_value_content">
                  <h6>Recognition and Appreciation</h6>
                  <p className="text-white">
                    We celebrate our team's wins, big and small, to keep
                    motivation high and spirits up.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="our_value_child_div value_margintop">
                <div>
                  <img
                    src={creativity_icon}
                    alt=".."
                    className="our_value_icon"
                  />
                </div>
                <div className="our_value_content">
                  <h6>Creativity and Innovation</h6>
                  <p className="text-white">
                    We foster an environment where new ideas thrive, helping us
                    stay ahead in the digital marketing world.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="our_value_child_div value_margintop">
                <div>
                  <img
                    src={connected_culture_icon}
                    alt=".."
                    className="our_value_icon"
                  />
                </div>
                <div className="our_value_content">
                  <h6>Connected Culture</h6>
                  <p className="text-white">
                    We believe in staying connected. Team-building activities
                    and collaboration help us grow together.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div
            className="row justify-content-between"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <div className="col-lg-3">
              <div className="our_value_child_div value_margintop">
                <div>
                  <img
                    src={psycologic_icon}
                    alt=".."
                    className="our_value_icon"
                  />
                </div>
                <div className="our_value_content">
                  <h6>Psychological Safety</h6>
                  <p className="text-white">
                    We encourage our team to share ideas freely, knowing their
                    input is valued and respected.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="our_value_child_div value_margintop">
                <div>
                  <img src={purpose_icon} alt=".." className="our_value_icon" />
                </div>
                <div className="our_value_content">
                  <h6>Purpose-Driven Work</h6>
                  <p className="text-white">
                    Our work goes beyond marketing. We aim to make a positive
                    impact, with a focus on meaningful, sustainable goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="our_value_child_div value_margintop">
                <div>
                  <img
                    src={mentorship_icon}
                    alt=".."
                    className="our_value_icon"
                  />
                </div>
                <div className="our_value_content">
                  <h6>Mentorship and Growth</h6>
                  <p className="text-white">
                    We support our team's growth through mentoring, providing
                    guidance, and inspiring success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="company_vibe_parent_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div
                className="company_vibes_left "
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <h2 className="heading_para">Company Vibe</h2>
                <div className="my-4">
                  <p>We believe that,</p>
                  <ul>
                    <li>
                      <p>
                        When skilled individuals work together, they boost each
                        other's <br className="break_tag" />
                        abilities.
                      </p>{" "}
                    </li>
                    <li>
                      {" "}
                      <p>Success is like a forest fire.</p>{" "}
                    </li>
                    <li>
                      {" "}
                      <p>
                        We value a culture where everyone has the freedom to
                        express
                        <br className="break_tag" /> themselves and takes
                        responsibility for their actions.
                      </p>{" "}
                    </li>
                    <li>
                      {" "}
                      <p>
                        We're honest about our thoughts and strive to help one
                        another.
                      </p>{" "}
                    </li>
                    <li>
                      {" "}
                      <p>
                        Instead of enforcing rules, we guide based on
                        understanding the <br className="break_tag" />
                        situation.
                      </p>{" "}
                    </li>
                  </ul>
                </div>
                <div className="d-flex ">
                  <Link to="/contact-us" className="link_tag">
                    <button type="button" className="button_red d-flex ">
                      <span>Fly With Us</span>
                      <div className="button_red_arrow">
                        {" "}
                        <i class="fa-solid fa-arrow-right contact_arrow  ms-2"></i>
                      </div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="vertical-carousel-container">
                {/* Top Blur */}
                <div className="blur-top"></div>

                {/* Column 1: Top to Bottom */}
                <div className="carousel-column top-to-bottom">
                  {loopImages.map((image, index) => (
                    <img
                      key={`col1-${index}`}
                      src={image}
                      alt={`Image ${index + 1}`}
                    />
                  ))}
                </div>

                {/* Column 2: Bottom to Top */}
                <div className="carousel-column bottom-to-top">
                  {loopImages.map((image, index) => (
                    <img
                      key={`col2-${index}`}
                      src={image}
                      alt={`Image ${index + 1}`}
                    />
                  ))}
                </div>

                {/* Column 3: Top to Bottom */}
                <div className="carousel-column top-to-bottom">
                  {loopImages.map((image, index) => (
                    <img
                      key={`col3-${index}`}
                      src={image}
                      alt={`Image ${index + 1}`}
                    />
                  ))}
                </div>

                {/* Bottom Blur */}
                <div className="blur-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="leadership_parent_div position-relative">
          <div className="row">
            <div className="col-lg-6">
              <div className="leadership_left">
                <h2 className="text-white">Leadership</h2>
                <div>
                  <p className="text-white mt-3">
                    We believe that great leadership is the foundation of innovation and growth.
                    Our leaders not only guide with expertise but also inspire our teams to
                    achieve their best.
                  </p>
                  <p className="text-white mt-4">
                    Meet the team leading us into the future of Data-driven business solutions.
                  </p>
                </div>
              </div>

            </div>
            <div className="col-lg-6 ">
              <div className="leadership_right d-flex justify-content-center">
                <img src={leadership_image} alt="leadership" className=""/>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default AboutUsContent;

import React, { useEffect, useRef, useState } from "react";
import { branding_designing_content } from "../../helpers/contents/AgencyBrandingDesigning";
const Branding = () => {
  const [activeTab2, setActiveTab2] = useState(0);
  const agencySection2 = useRef([]);
  const observerRef2 = useRef(null);

  const branding_designing_detail_page = [
    {
      heading: "Creative Solution",
      redirectLink: "/agency/creative-solutions",
    },
    {
      heading: "Video Content Production",
      redirectLink: "/agency/video-content-production",
    },
    { heading: "Video Marketing", redirectLink: "/agency/video-marketing" },
    {
      heading: "Funnel Building & Marketing",
      redirectLink: "/agency/funnel-building-marketing",
    },
  ];

  // Set up Intersection Observer to detect which video is in view
  useEffect(() => {
    if (observerRef2.current) observerRef2.current.disconnect();

    observerRef2.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = agencySection2.current.indexOf(entry.target);
            if (index !== -1) {
              setActiveTab2(index);
              
            }
          }
        });
      },
      { threshold: 0.5 }
    ); // Adjust threshold as needed

    agencySection2.current.forEach((section) => {
      if (section) observerRef2.current.observe(section);
    });

    return () => {
      if (observerRef2.current) observerRef2.current.disconnect();
    };
  }, []);

  const handleTabClick2 = (index) => {
    setActiveTab2(index);
    if (agencySection2.current[index]) {
      const offsetTop =
        agencySection2.current[index].getBoundingClientRect().top +
        window.pageYOffset -
        100; // Adjust -100 to your desired offset
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-between ">
          <div className="col-lg-6 px-0 position-relative">
            <div className="hands-on-left-content">
              <div className="padding-inner-content">
                <ul className="list-unstyled hands-on-content-mob pl-0">
                  {branding_designing_detail_page.map((text, index) => (
                    <li key={index}>
                      <span
                        className={`hands-on-left-text ${
                          activeTab2 === index ? "active" : ""
                        }`}
                        onClick={() => handleTabClick2(index)}
                        ref={(el) => (agencySection2.current[index] = el)}
                      >
                        {text?.heading}
                        <a
                          href={text?.redirectLink}
                          style={{ textDecoration: "none" }}
                        >
                          <i
                            className={`${
                              index === activeTab2 ? "circle_arrow_active" : ""
                            } fa-solid fa-arrow-right circle_arrow my-auto`}
                          ></i>
                        </a>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hands-on-right-section padding-inner-content">
              {branding_designing_content?.map((item, index) => (
                <div
                  key={index}
                  className="hands-on-right-video mb-5 position-relative"
                  ref={(el) => (agencySection2.current[index] = el)}
                  style={{ marginBottom: "16px" }}
                >
                  <h2 className="heading_para">{item?.heading}</h2>
                  <p className="">{item.content}</p>
                  <a href={item?.RedirectLink}>
                    <button className="button_red mt-3">
                      Learn More <i class="fa-solid fa-arrow-right ms-2"></i>
                    </button>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branding;

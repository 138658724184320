import React, { useState } from "react";
import teamimg1 from "../../assets/images/home/mt1.webp";
import teamimg2 from "../../assets/images/home/mt2.webp";
import teamimg3 from "../../assets/images/home/mt3.webp";
import teamimg4 from "../../assets/images/home/mt4.webp";

import ArrowTeam from "../../assets/images/home/arrow-team.webp";
import CustomModal from "../../common/CustomModal";
import { useNavigate } from "react-router-dom";
const teamData = [
  {
    id: 1,
    name: "Dharaneetharan G D",
    title: "Founder & Thought Leader",
    img: teamimg1,
    para: "Dharaneetharan G D, the “Digital Monk” from Sivakasi, Tamil Nadu, is redefining EdTech and business globally with over 10 years of digital marketing expertise. As the founder of Social Eagle Marketing, he has generated ₹100+ Crores in client revenue and impacted over 5 lakh people through high-value content. Dharani's innovative strategies and purpose-driven leadership continue to inspire measurable growth and entrepreneurial success.",
    faceBookUrl: "https://www.facebook.com/dharaneetharangd/",
    instaUrl: "https://www.instagram.com/dharaneetharan/?hl=en",
    linkedinUrl:
      "https://www.linkedin.com/in/dharaneetharan/?originalSubdomain=in",
  },
  {
    id: 2,
    name: "Vishnu Hari",
    title: "Performance Marketer & Lead Trainer",
    img: teamimg2,
    para: "Vishnuhari Harikumar, a tech expert and digital entrepreneur, transforms ideas into thriving businesses. As Co-founder of Social Eagle Marketing, he has trained over 1,200 students in digital entrepreneurship, driving crores in revenue. His strategic leadership and collaborations with global brands empower businesses to excel in the digital space.",
    faceBookUrl: "https://www.facebook.com/imvishnuhari?mibextid=ZbWKwL",
    instaUrl: "https://www.instagram.com/imvishnuhari/",
    linkedinUrl: "https://www.linkedin.com/in/vishnuhari-harikumar/",
  },
  {
    id: 3,
    name: "Sathish S",
    title: "Operations & Acquisition Expert",
    img: teamimg3,
    para: "Sathish S, a results-driven leader, transitioned from Mechanical Engineer to Digital Entrepreneur, scaling businesses to 8-figure revenues. As an expert in operations and talent acquisition, he specializes in streamlining processes and building high-performing teams. His passion for growth and operational expertise drives measurable business success.",
    faceBookUrl: "https://www.facebook.com/SocialEagleOfficial/",
    instaUrl: "https://www.instagram.com/socialeagleofficial/?hl=en",
    linkedinUrl: "https://www.linkedin.com/company/socialeagle/",
  },
  {
    id: 4,
    name: "Shatheeshram Ganapathy",
    title: "Sales Beast",
    img: teamimg4,
    para: "Shatheeshram Ganapathy, the “Sales Beast,” is a high-ticket sales expert known for his strategic creativity and process-driven approach. From aesthetic photographer to process-oriented entrepreneur, he mentors sales teams using 8-figure systems to achieve exceptional results. His guidance helps students secure high-ticket clients, empowering businesses to scale with innovation and efficiency.",
    faceBookUrl: "https://www.facebook.com/shatheeshram?mibextid=ZbWKwL",
    instaUrl:
      "https://www.instagram.com/shatheeshramganapathy/?igsh=MW5nYzc4Nmh3OXJvdQ%3D%3D#",
    linkedinUrl: "https://www.linkedin.com/in/shatheeshram-ganapathy-a7476473/",
  },
];
const OurTeam = () => {
  const [activeCard, setActiveCard] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState("");
  const [currentItem, setCurrentItem] = useState({});

  return (
    <div>
      <div className="team-container">
        {teamData.map((member) => (
          <div
            className={`padding-card-team ${activeCard === member.id ? "expanded" : ""
              }`}
            key={member.id}
            onMouseEnter={() => setActiveCard(member.id)}
            onMouseLeave={() => setActiveCard(1)}
          >
            <div
              className={`team-card ${activeCard === member.id ? "expanded" : ""
                }`}
            >
              <div className="card-content">
                <div className="card-inner-content">
                  <h2 className="mb-2">{member.name}</h2>
                  <small className="team-card-small-data">{member.title}</small>
                  <div className="d-flex gap-3 mt-3">
                    <div>
                      <a
                        className="our-team-sm-redirect"
                        href={member.instaUrl}
                        target="_blank"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </div>
                    <div>
                      <a
                        className="our-team-sm-redirect"
                        href={member.faceBookUrl}
                        target="_blank"
                      >
                        <i class="fa-brands fa-facebook"></i>
                      </a>
                    </div>
                    <div>
                      <a
                        className="our-team-sm-redirect"
                        href={member.linkedinUrl}
                        target="_blank"
                      >
                        <i class="fa-brands fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setOpenModal(true);
                      setCurrentMemberId(member.id);
                      setCurrentItem(member);
                    }}
                  >
                    <img
                      className="arrow-team-redirect"
                      src={ArrowTeam}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="card-image">
                <img src={member.img} alt={member.name} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="team-modal-open">
        <CustomModal
          open={openModal}
          onClickOutside={() => {
            setOpenModal(false);
            setCurrentMemberId(null);
          }}
        >
          <div className="modal-content contact-container m-auto">
            <div className="modal-body bg-team-modal w-100 p-0 ">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-md-8">
                    <div className="p-2">
                      <p className="text-light mb-2">About</p>
                      <h5 className="mb-2 text-light font-medium">
                        {currentItem.name}
                      </h5>
                      <small className="text-light">{currentItem.para}</small>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-4">
                    <div className="">
                      <img
                        className="team-open-card-modal"
                        src={currentItem.img}
                        alt={currentItem.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start px-4 gap-3 mt-3">
              <div>
                <a
                  className="our-team-sm-redirect"
                  href={currentItem.instaUrl}
                  target="_blank"
                >
                  <i class="fa-brands fs-5 text-light fa-instagram"></i>
                </a>
              </div>
              <div>
                <a
                  className="our-team-sm-redirect"
                  href={currentItem.faceBookUrl}
                  target="_blank"
                >
                  <i class="fa-brands fs-5 text-light fa-facebook"></i>
                </a>
              </div>
              <div>
                <a
                  className="our-team-sm-redirect"
                  href={currentItem.linkedinUrl}
                  target="_blank"
                >
                  <i class="fa-brands fs-5 text-light fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default OurTeam;

import React from 'react'
import images from "../../../../helpers/images"
import Advisory_marketing_rght_img from "../../../../assets/images/scm_9.webp";
import social_management_whyChoose from "../../../../assets/images/scm_10.webp";
import { useNavigate } from 'react-router-dom';
const ContentSection = () => {
    const navigate = useNavigate();
    return (
        <div className='Agency_detail_page' id="about_top_content">
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='purpose_left' data-aos="fade-down-right">
                                <h1>Digital Media  <br className='break_tag' />Planning & Buying  </h1>
                                <button onClick={()=>navigate('/contact-us')} className='button_red mt-4 agency_into_button'>Learn More</button>
                            </div>
                        </div>
                        <div className='col-lg-7 my-auto'>
                            <div className='purpose_right' data-aos="fade-down-left">
                                <p>Wondering why digital media planning and buying is essential for your brand? Let’s break it down.</p>
                                <p>Every great campaign starts with a plan. Our Digital Media Planning & Buying services are designed to ensure your brand reaches the right audience at the perfect moment, maximizing impact and engagement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row justify-content-between' >
                        <div className='col-lg-5'>
                            <div className='why_digital_market_left' data-aos="fade-right">
                                <h2 className='headings_text_black'>Why Choose Our<br className='break_tag' /> Digital Media Services?</h2>
                                <img src={Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='why_digital_market_right' data-aos="fade-left">
                                <p className='paraWhite_margin_top text-black'>When it comes to media planning and buying, expertise matters. So, why partner with us.</p>
                                <p className='paraWhite_margin_top text-black'>Simple: we deliver results. While anyone can place ads, our team knows how to target, negotiate, and optimize ad placements with precision. Our experience in the digital media landscape ensures that your campaigns reach the right people and drive measurable growth.</p>
                                <p className='paraWhite_margin_top text-black'>With our expertise, we make digital media planning and buying a seamless process that connects you to your audience effectively. Ready to elevate your digital advertising?</p>
                                <button onClick={()=>navigate('/contact-us')} className='button_gradient mt-4'> Get Your FREE Consultation</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }} data-aos="zoom-out-right">
                                <div className='container'>
                                    <h2 className='headings_text_white'>Why Digital Media <br className='break_tag' /> Planning & Buying?</h2>
                                    <p className='paraWhite_margin_top mb-5'>Absolutely! In today’s digital age, knowing where and when to place your ads is crucial. By strategically planning and buying media, you can maximize your brand’s exposure, ensure better engagement, and make every advertising dollar count. Without it, even the best ads can get lost in the noise. Here’s how our expertise makes a difference:</p>
                                    <img src={social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                    <button onClick={()=>navigate('/contact-us')} className='button_red mt-5'>Learn More</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_rght' data-aos="zoom-out-left">
                                <div className='agency_detail_whyChoose_rght_content mt-0' >
                                    <div>
                                        <img src={images.Digital_media_icon1} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Strategic Planning</h5>
                                        <p>We develop tailored media plans that align perfectly with your goals and audience profile. This optimizes your ad spend and makes sure your message resonates where it matters most.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Digital_media_icon2} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Targeted Buying</h5>
                                        <p> Our team secures strategic ad placements across the most relevant digital platforms, amplifying your brand’s visibility and creating meaningful connections with potential customers.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Digital_media_icon3} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Performance Monitoring & Optimization</h5>
                                        <p> We continuously monitor each campaign, analyzing performance metrics to make real-time adjustments, ensuring each campaign delivers the best results and ROI.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
import React from 'react'
import images from "../../../../helpers/images"
import Advisory_marketing_rght_img from "../../../../assets/images/scm_17.webp";
import social_management_whyChoose from "../../../../assets/images/scm_18.webp";
import { useNavigate } from 'react-router-dom';
const ContentSection = () => {
    const navigate = useNavigate();
    return (
        <div className='Agency_detail_page' id="about_top_content">
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='purpose_left' data-aos="zoom-in">
                                <h1> Marketing <br className='break_tag' />Automation </h1>
                                <button onClick={()=>navigate('/contact-us')} className='button_red mt-4 agency_details_button'>Consult Now</button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='purpose_right pe-5' data-aos="zoom-in">
                                <p>Ready to streamline your marketing efforts and boost efficiency? Our Marketing Automation services are designed to optimize your processes, saving you time while enhancing your overall marketing strategy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-5'>
                            <div className='why_digital_market_left' data-aos="zoom-in">
                                <h2 className='headings_text_black'>Why Marketing<br className='break_tag' />Automation?</h2>
                                <img src={Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='why_digital_market_right' data-aos="zoom-out">
                                <p className='paraWhite_margin_top text-black'>
                                    Absolutely! In today’s fast-paced business environment, marketing automation is essential. It allows businesses to manage repetitive tasks efficiently, freeing up valuable resources and enabling teams to focus on strategy and creative initiatives. Automation not only improves efficiency but also ensures that no lead is left unattended, maximizing your chances of conversion.                                    </p>
                                <button onClick={()=>navigate('/contact-us')} className='button_gradient mt-4'> Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='Agency_added_section'>
                <div className='container'>
                    <div className='Agency_added_section_heading section_padding'>
                        <h1 className='heading_para text-center' data-aos="zoom-in">How Automation Benefits<br className='break_tag' /> Your Business</h1>
                    </div>
                    <div className='section_padding_bottom'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex'>
                                    <div><p className='number_list_backred' data-aos="zoom-out-left">1</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="zoom-out-right">
                                        <h4 className='heading_para my-auto'>Increased Efficiency</h4>
                                        <p>By automating repetitive tasks, your team can focus on high-value activities that drive growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex me-0'>
                                    <div><p className='number_list_backred' data-aos="zoom-out-right">2</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="zoom-out-left">
                                        <h4 className='heading_para my-auto'>Enhanced Customer Experience</h4>
                                        <p> Automated messaging ensures timely and relevant communication, improving customer satisfaction and loyalty.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection d-flex'>
                                    <div><p className='number_list_backred' data-aos="zoom-out-left">3</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="zoom-out-right">
                                        <h4 className='heading_para my-auto'>Scalability</h4>
                                        <p> Automation allows your marketing efforts to grow with your business, managing increased demand without sacrificing quality.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection  d-flex me-0'>
                                    <div><p className='number_list_backred' data-aos="zoom-out-right">4</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="zoom-out-left">
                                        <h4 className='heading_para my-auto'>Better Data Utilization</h4>
                                        <p>With robust analytics, you can make informed decisions based on real-time performance data, optimizing your marketing strategy effectively.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='row'>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }} data-aos="fade-up">
                            <div className='container'>
                                <h2 className='headings_text_white'>Why Choose Our <br className='break_tag' /> Marketing Automation  <br className='break_tag' />Services?</h2>
                                <p className='paraWhite_margin_top mb-5'>we drive results. While many offer automation solutions, our team specializes in creating tailored strategies that integrate seamlessly with your existing systems to enhance productivity.</p>
                                <img src={social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                <p className='paraWhite_margin_top mt-5'>With our marketing automation expertise, we empower your business to achieve better marketing outcomes while simplifying your processes.</p>
                                <h2 className='headings_text_white mt-5'>Ready to revolutionize <br className='break_tag' />your marketing efforts? </h2>
                                <button onClick={()=>navigate('/contact-us')} className='button_red mt-5'>  Get Your FREE Marketing Automation Consultation</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_rght' data-aos="fade-down">
                            <h3 className='headings_text_white mb-5'>Here’s how our marketing  <br className='break_tag' />automation services can <br className='break_tag' /> transform your business</h3>
                            <div className='agency_detail_whyChoose_rght_content mt-0'>
                                <div>
                                    <img src={images.market_Automation_icon1} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Automated Workflows</h5>
                                    <p>We help you set up automated processes that handle repetitive tasks—such as email marketing, social media posting, and lead management—saving you time and reducing errors.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.market_Automation_icon2} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Immediate Lead Notifications</h5>
                                    <p> With our system, you’ll receive instant alerts whenever new leads come in. This enables your team to respond quickly, ensuring timely follow-ups that can significantly increase conversion rates.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.market_Automation_icon3} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Lead Touch Points</h5>
                                    <p>We develop automated touch points that nurture leads through the sales funnel. These consistent engagements keep your brand at the forefront of potential customers’ minds, guiding them toward making a purchase.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content '>
                                <div>
                                    <img src={images.market_Automation_icon4} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Personalized Messaging</h5>
                                    <p>Our automation solutions deliver targeted messages based on user behavior and preferences. This personalization ensures your audience receives relevant content, enhancing engagement and fostering stronger relationships.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content '>
                                <div>
                                    <img src={images.market_Automation_icon5} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Performance Analytics</h5>
                                    <p>We track and analyze the performance of your automated campaigns. This data-driven approach allows us to refine strategies continually, improving outcomes and maximizing ROI.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContentSection
import React from 'react'
import BlogsBanner from '../component/Blogs/BlogsBanner'
import BlogsContent from '../component/Blogs/BlogsContent'
import Navbar from '../component/Navbar/Navbar'
import Footer from '../component/Footer/Footer'

const Blogs = () => {
  return (
    <div>
      <Navbar />
      <BlogsBanner/>
      <BlogsContent/>
      <Footer />
    </div>
  )
}

export default Blogs

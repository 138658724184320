import React, { useEffect } from "react";
import {
  HashRouter,
  Route,
  Routes,
  ScrollRestoration,
  useLocation,
} from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Agency from "./pages/Agency";
import Blogs from "./pages/Blogs";
import TechnologyPartner from "./pages/TechnologyPartner";
import Advisory from "./pages/Advisory";
import MarketingStrategyAndConsulting from "./pages/MarketingStrategyAndConsulting";
import MarketingProcessAndAutomation from "./pages/MarketingProcessAndAutomation";
import BrandingStrategyAndConsulting from "./pages/BrandingStrategyAndConsulting";
import CommunityAndMastermindCreation from "./pages/CommunityAndMastermindCreation";
import SocialMediaManagement from "./pages/SocialMediaManagement";
import SocialMediaMarketing from "./pages/SocialMediaMarketing";
import PayPerClick from "./pages/PayPerClick";
import PerformanceMarketing from "./pages/PerformanceMarketing";
import DigitalMediaPlanningAndBuying from "./pages/DigitalMediaPlanningAndBuying";
import SearchEngineOptimization from "./pages/SearchEngineOptimization";
import ContentMarketingService from "./pages/ContentMarketingService";
import EmailMarketingService from "./pages/EmailMarketingService";
import MarketingAutomation from "./pages/MarketingAutomation";
import CreativeSolutions from "./pages/CreativeSolutions";
import VideoContentProduction from "./pages/VideoContentProduction";
import VideoMarketing from "./pages/VideoMarketing";
import FunnelBuildingMarketing from "./pages/FunnelBuildingMarketing";
import ROUTES from "./helpers/constants/routes";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import CaseStudy from './pages/CaseStudy';
import GreenProtein from "./pages/CaseStudy/GreenProtein";
import LeadGeneration from "./pages/LeadGeneration";
import DigitalMarketingFutures from "./pages/Blogs/DigitalMarketingFutures";
import PillarsofDigitalMarketing from "./pages/Blogs/PillarsofDigitalMarketing";
import ReasonForBusineesInDubai from "./pages/Blogs/ReasonForBusineesInDubai";
import HowToChooseRightDIgital from "./pages/Blogs/HowToChooseRightDIgital";
import BoostYourBusinessWithSocailMedia from "./pages/Blogs/BoostYourBusinessWithSocailMedia";
import DigitalMarketingTrends2025 from "./pages/Blogs/DigitalMarketingTrends2025";
import Horlicks from "./pages/CaseStudy/Horlicks";
import Techgyan from "./pages/CaseStudy/Techgyan";
import ECGacademy from "./pages/CaseStudy/ECGacademy";
import YCBacademy from "./pages/CaseStudy/YCBacademy";
import Blisspads from "./pages/CaseStudy/Blisspads";
import SkinpotCo from "./pages/CaseStudy/SkinpotCo";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return null;
};

const Main = () => {
  return (
    <>
      <HashRouter basename="/">
        <ScrollToTop />
        <Routes>
          <Route>
            {/* Common Routes */}
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.CONTACTUS} element={<ContactUs />} />
            <Route path={ROUTES.ABOUTUS} element={<AboutUs />} />
            <Route
              path={ROUTES.TECHNOLOGYDEVELOPMENT}
              element={<TechnologyPartner />}
            />

            {/* Advisory Routes */}
            <Route path={ROUTES.ADVISORY} element={<Advisory />} />
            <Route
              path={ROUTES.ADVISORY_SERVICES.MARKETING_STRATEGY_CONSULTING}
              element={<MarketingStrategyAndConsulting />}
            />
            <Route
              path={ROUTES.ADVISORY_SERVICES.MARKETING_PROCESS_AUTOMATION}
              element={<MarketingProcessAndAutomation />}
            />
            <Route
              path={ROUTES.ADVISORY_SERVICES.BRANDING_STRATEGY_CONSULTING}
              element={<BrandingStrategyAndConsulting />}
            />
            <Route
              path={ROUTES.ADVISORY_SERVICES.COMMUNITY_MASTERMIND_CREATION}
              element={<CommunityAndMastermindCreation />}
            />

            {/* Agency Routes */}
            <Route path={ROUTES.AGENCY} element={<Agency />} />
            <Route
              path={ROUTES.AGENCY_SERVICES.COMMUNITY_AND_MASTERMIND_CREATION}
              element={<CommunityAndMastermindCreation />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MANAGEMENT}
              element={<SocialMediaManagement />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MARKETING}
              element={<SocialMediaMarketing />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.PAY_PER_CLICK}
              element={<PayPerClick />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.PERFORMANCE_MARKETING}
              element={<PerformanceMarketing />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.DIGITAL_MEDIA_PLANNING_AND_BUYING}
              element={<DigitalMediaPlanningAndBuying />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.SEARCH_ENGINE_OPTIMIZATION}
              element={<SearchEngineOptimization />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.CONTENT_MARKETING_SERVICE}
              element={<ContentMarketingService />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.EMAIL_MARKETING_SERVICE}
              element={<EmailMarketingService />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.MARKETING_AUTOMATION}
              element={<MarketingAutomation />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.VIDEO_CONTENT_PRODUCTION}
              element={<VideoContentProduction />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.CREATIVE_SOLUTIONS}
              element={<CreativeSolutions />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.VIDEO_MARKETING}
              element={<VideoMarketing />}
            />
            <Route
              path={ROUTES.AGENCY_SERVICES.FUNNEL_BUILDING_MARKETING}
              element={<FunnelBuildingMarketing />}
            />
            <Route path={ROUTES.BLOGS} element={<Blogs />} />
            <Route path={ROUTES.BLOGS_SERVICE.DIGITAL_MARKETING_FUTURES} element={<DigitalMarketingFutures />} />
            <Route path={ROUTES.BLOGS_SERVICE.SIX_PILLAR_OF_DIGITAL_MARKETING} element={<PillarsofDigitalMarketing />} />
            <Route path={ROUTES.BLOGS_SERVICE.TEN_REASON_FOR_DIGITAL_MARKETING} element={<ReasonForBusineesInDubai />} />
            <Route path={ROUTES.BLOGS_SERVICE.CHOOSE_RIGHT_DIGITAL_MARKETING} element={<HowToChooseRightDIgital />} />
            <Route path={ROUTES.BLOGS_SERVICE.BOOST_YOUR_BUSINESS_WITH_SOCIALMEDIA} element={<BoostYourBusinessWithSocailMedia />} />
            <Route path={ROUTES.BLOGS_SERVICE.DIGITAL_MARKETING_2025TRENDS} element={<DigitalMarketingTrends2025 />} />


            <Route path={ROUTES.CASE_STUDY} element={<CaseStudy />} />
            <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={ROUTES.TERMS_AND_CONDITION} element={<TermsAndCondition />} />
            <Route path={ROUTES.CASE_STUDY_SERVICE.GREEN_PROTEIN} element={<GreenProtein />} />
            <Route path={ROUTES.CASE_STUDY_SERVICE.HORLICKS} element={<Horlicks />} />
            <Route path={ROUTES.CASE_STUDY_SERVICE.TECHGYAN} element={<Techgyan />} />
            <Route path={ROUTES.CASE_STUDY_SERVICE.ECG_ACADEMY} element={<ECGacademy />} />
            <Route path={ROUTES.CASE_STUDY_SERVICE.YCB_ACADEMY} element={<YCBacademy />} />
            <Route path={ROUTES.CASE_STUDY_SERVICE.BLISSPADS} element={<Blisspads />} />
            <Route path={ROUTES.CASE_STUDY_SERVICE.SKINPOT_CO} element={<SkinpotCo />} />
            <Route path={ROUTES.AGENCY_SERVICES.LEAD_GENERATION} element={<LeadGeneration />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
};

export default Main;


import React, { useEffect } from 'react'
import BannerSection from '../component/Agency/SubPages/ContentMarketingService/BannerSection'
import ContentSection from '../component/Agency/SubPages/ContentMarketingService/ContentSection'
import Navbar from '../component/Navbar/Navbar'
import Footer from '../component/Footer/Footer'
const ContentMarketingService = () => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (
        <div className='aboutus_main overflow-hidden'>
            <Navbar />
            <BannerSection />
            <ContentSection />
            <Footer />
        </div>
    )
}

export default ContentMarketingService
import Advisory_marketing_rght_img from "../assets/images/scm_1.webp";
import social_management_whyChoose from "../assets/images/scm_2.webp";

import seo_icon1 from "../assets/images/seo_icon1.webp";
import seo_icon2 from "../assets/images/seo_icon2.webp";
import seo_icon3 from "../assets/images/seo_icon3.webp";
import seo_icon4 from "../assets/images/seo_icon4.webp";

import Digital_media_icon1 from "../assets/images/Digital_media_icon1.webp";
import Digital_media_icon2 from "../assets/images/Digital_media_icon2.webp";
import Digital_media_icon3 from "../assets/images/Digital_media_icon3.webp";

import payper_icon1 from "../assets/images/payper_icon1.webp";
import payper_icon2 from "../assets/images/payper_icon2.webp";
import payper_icon3 from "../assets/images/payper_icon3.webp";

import performance_marketicon1 from "../assets/images/performance_marketicon1.webp";
import performance_marketicon2 from "../assets/images/performance_marketicon2.webp";
import performance_marketicon3 from "../assets/images/performance_marketicon3.webp";

import SocialMedia_icon1 from "../assets/images/SocialMedia_icon1.webp";
import SocialMedia_icon2 from "../assets/images/SocialMedia_icon2.webp";
import SocialMedia_icon3 from "../assets/images/SocialMedia_icon3.webp";

import Content_Marketing_icon1 from "../assets/images/Content_Marketing_icon1.webp";
import Content_Marketing_icon2 from "../assets/images/Content_Marketing_icon2.webp";
import Content_Marketing_icon3 from "../assets/images/Content_Marketing_icon3.webp";
import Content_Marketing_icon4 from "../assets/images/Content_Marketing_icon4.webp";

import Email_market_icon1 from "../assets/images/Email_market_icon1.webp";
import Email_market_icon2 from "../assets/images/Email_market_icon2.webp";
import Email_market_icon3 from "../assets/images/Email_market_icon3.webp";
import Email_market_icon4 from "../assets/images/Email_market_icon4.webp";

import creative_solution_icon1 from "../assets/images/creative_solution_icon1.webp";
import creative_solution_icon2 from "../assets/images/creative_solution_icon2.webp";
import creative_solution_icon3 from "../assets/images/creative_solution_icon3.webp";
import creative_solution_icon4 from "../assets/images/creative_solution_icon4.webp";

import video_content_icon1 from "../assets/images/video_content_icon1.webp";
import video_content_icon2 from "../assets/images/video_content_icon2.webp";
import video_content_icon3 from "../assets/images/video_content_icon3.webp";
import video_content_icon4 from "../assets/images/video_content_icon4.webp";
import video_content_icon5 from "../assets/images/video_content_icon5.webp";

import market_Automation_icon1 from "../assets/images/market_Automation_icon1.webp";
import market_Automation_icon2 from "../assets/images/market_Automation_icon2.webp";
import market_Automation_icon3 from "../assets/images/market_Automation_icon3.webp";
import market_Automation_icon4 from "../assets/images/market_Automation_icon4.webp";
import market_Automation_icon5 from "../assets/images/market_Automation_icon5.webp";

import Funnel_marketing_icon1 from "../assets/images/Funnel_marketing_icon1.webp";
import Funnel_marketing_icon2 from "../assets/images/Funnel_marketing_icon2.webp";
import Funnel_marketing_icon3 from "../assets/images/Funnel_marketing_icon3.webp";
import Funnel_marketing_icon4 from "../assets/images/Funnel_marketing_icon4.webp";
import Funnel_marketing_icon5 from "../assets/images/Funnel_marketing_icon5.webp";
import Funnel_marketing_icon6 from "../assets/images/Funnel_marketing_icon6.webp";
import Funnel_marketing_icon7 from "../assets/images/Funnel_marketing_icon7.webp";
import Funnel_marketing_icon8 from "../assets/images/Funnel_marketing_icon8.webp";
import Funnel_marketing_icon9 from "../assets/images/Funnel_marketing_icon9.webp";

import video_marketing_icon1 from "../assets/images/video_marketing_icon1.webp";
import video_marketing_icon2 from "../assets/images/video_marketing_icon2.webp";
import video_marketing_icon3 from "../assets/images/video_marketing_icon3.webp";
import video_marketing_icon4 from "../assets/images/video_marketing_icon4.webp";
import video_marketing_icon5 from "../assets/images/video_marketing_icon5.webp";
import video_marketing_icon6 from "../assets/images/video_marketing_icon6.webp";
import video_marketing_icon7 from "../assets/images/video_marketing_icon7.webp";
import video_marketing_icon8 from "../assets/images/video_marketing_icon8.webp";

import SocialMarket_icon1 from "../assets/images/SocialMarket_icon1.webp";
import SocialMarket_icon2 from "../assets/images/SocialMarket_icon2.webp";
import SocialMarket_icon3 from "../assets/images/SocialMarket_icon3.webp";

import contact_bar from "../assets/images/contact_bar.webp";
import contact_bar_bottom from "../assets/images/contact_bar_bottom.webp";

import Agency_tab_image from "../assets/images/Agency_tab_image.png";
import Agency_tab_image_right from "../assets/images/Agency_tab_image_right.png";
import agency_listing_background from "../assets/images/agency_listing_background.webp";

import WhoWeAre from "../assets/images/home/whoweare.webp";

import OurProcess1 from "../assets/images/home/o1.webp";
import OurProcess2 from "../assets/images/home/o2.webp";
import OurProcess3 from "../assets/images/home/o3.webp";
import Mark from "../assets/images/home/mark.webp";

import C1 from "../assets/images/home/c1.webp";
import C2 from "../assets/images/home/c2.webp";
import C3 from "../assets/images/home/c3.webp";
import C4 from "../assets/images/home/c4.webp";

import blog_1 from "../assets/images/blogs/blog_main_01.webp";
import blog_2 from "../assets/images/blogs/blog_main_02.webp";
import blog_3 from "../assets/images/blogs/blog_main_03.webp";
import blog_4 from "../assets/images/blogs/blog_main_04.webp";
import blog_5 from "../assets/images/blogs/blog_main_05.webp";
import blog_6 from "../assets/images/blogs/blog_main_06.webp";

import blog_detail_1 from "../assets/images/blogs/blog_detail_1.webp";
import blog_detail_2 from "../assets/images/blogs/blog_detail_2.webp";
import blog_detail_3 from "../assets/images/blogs/blog_detail_3.webp";
import blog_detail_4 from "../assets/images/blogs/blog_detail_4.webp";
import blog_detail_5 from "../assets/images/blogs/blog_detail_5.webp";
import blog_detail_6 from "../assets/images/blogs/blog_detail_6.webp";
import blogs_banner_top from "../assets/images/blogs/blogs_banner_top.webp";
import blogs_banner_bottom from "../assets/images/blogs/blogs_banner_bottom.webp";
import video_content_1 from "../assets/images/video_content_1.webp";
import video_content_2 from "../assets/images/video_content_2.webp";
import video_market_1 from "../assets/images/video_market_1.webp";
import video_market_2 from "../assets/images/video_market_2.webp";
import creative_1 from "../assets/images/creative_1.webp";
import creative_2 from "../assets/images/creative_2.webp";
import funnel_1 from "../assets/images/funnel_1.webp";
import funnel_2 from "../assets/images/funnel_2.webp";

import advisory_market_1 from "../assets/images/advisory_market_1.webp";
import advisory_market_2 from "../assets/images/advisory_market_2.webp";
import advisory_common_1 from "../assets/images/advisory_common_1.webp";
import advisory_common_2 from "../assets/images/advisory_common_2.webp";
import advisory_common_3 from "../assets/images/advisory_common_3.webp";

import advisory_branding_1 from "../assets/images/advisory_branding_1.webp";
import advisory_branding_2 from "../assets/images/advisory_branding_2.webp";

import advisory_community_1 from "../assets/images/advisory_community_1.webp";
import advisory_community_2 from "../assets/images/advisory_community_2.webp";

import advisory_auto_1 from "../assets/images/advisory_auto_1.webp";
import advisory_auto_2 from "../assets/images/advisory_auto_2.webp";







// import blogs_dummy from "../assets/images/blogs/blogs_dummy.png";

const images = {
  //blogs
  blog_1,
  blog_2,
  blog_3,
  blog_4,
  blog_5,
  blog_6,
  blog_detail_1,
  blog_detail_2,
  blog_detail_3,
  blog_detail_4,
  blog_detail_5,
  blog_detail_6,
  blogs_banner_bottom,
  blogs_banner_top, video_content_1, video_content_2,
  video_market_1, video_market_2,
  creative_1, creative_2, funnel_1, funnel_2, advisory_market_1,
  advisory_market_2, advisory_common_1,
  advisory_common_2, advisory_common_3,
  advisory_branding_1, advisory_branding_2, advisory_community_1, advisory_community_2,
  advisory_auto_1,advisory_auto_2,

  // images
  Agency_tab_image,
  Agency_tab_image_right,
  agency_listing_background,

  //home

  WhoWeAre,

  OurProcess1,
  OurProcess2,
  OurProcess3,
  Mark,
  C1,
  C2,
  C3,
  C4,

  //
  contact_bar,
  contact_bar_bottom,

  performance_marketicon1,
  performance_marketicon2,
  performance_marketicon3,
  SocialMedia_icon1,
  SocialMedia_icon2,
  SocialMedia_icon3,
  SocialMarket_icon1,
  SocialMarket_icon2,
  SocialMarket_icon3,
  Advisory_marketing_rght_img,
  social_management_whyChoose,
  seo_icon1,
  seo_icon2,
  seo_icon3,
  seo_icon4,
  Content_Marketing_icon1,
  Content_Marketing_icon2,
  Content_Marketing_icon3,
  Content_Marketing_icon4,
  Email_market_icon1,
  Email_market_icon2,
  Email_market_icon3,
  Email_market_icon4,
  creative_solution_icon1,
  creative_solution_icon2,
  creative_solution_icon3,
  creative_solution_icon4,
  video_content_icon1,
  video_content_icon2,
  video_content_icon3,
  video_content_icon4,
  video_content_icon5,
  Funnel_marketing_icon1,
  Funnel_marketing_icon2,
  Funnel_marketing_icon3,
  Funnel_marketing_icon4,
  Funnel_marketing_icon5,
  Funnel_marketing_icon6,
  Funnel_marketing_icon7,
  Funnel_marketing_icon8,
  Funnel_marketing_icon9,
  video_marketing_icon1,
  video_marketing_icon2,
  video_marketing_icon3,
  video_marketing_icon4,
  video_marketing_icon5,
  video_marketing_icon6,
  video_marketing_icon7,
  video_marketing_icon8,
  Digital_media_icon1,
  Digital_media_icon2,
  Digital_media_icon3,
  market_Automation_icon1,
  market_Automation_icon2,
  market_Automation_icon3,
  market_Automation_icon4,
  market_Automation_icon5,
  payper_icon1,
  payper_icon2,
  payper_icon3,

  // blogs_dummy
};

export default images;

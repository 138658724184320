import ROUTES from "../constants/routes";

export const digital_marketing_content = [
    {
        heading: "Social Media Management",
        SubPara: "Our Social Media Management services help your business succeed on social media and increases brand visibility by",
        ListPara: ["Creating Engaging Content: We create interesting posts that connect with your audience and represent your brand",
            "Smart Scheduling: We schedule posts at the best times to get the most engagement",
            "Community Interaction & Reputation Management: We interact with your followers to build a strong community around your brand"],
        Content: ["We also track results to see what works and adjust our strategies as needed",
            "With targeted ads, we reach the right audience and increase your brand’s visibility.",
            "Let us help you connect with customers and grow your online presence"],
        RedirectLink: "/agency/social-media-management"

    },
    {
        heading: "Social Media Marketing",
        SubPara: "Our Social Media Marketing services transform your brand's online presence and drive results on platforms like Facebook and Instagram",
        ListPara: ["Strategic Campaigns: We design targeted advertising campaigns that attract and convert your ideal customers",
            "Compelling Storytelling: We craft engaging content that tells your brand's story and resonates with your audience",
            "Insights and Optimization: We analyse campaign performance to uncover insights and continuously optimise for better results."],
        Content: ["With our expertise, we help you harness the power of social media to build brand loyalty and drive growth. Let us take your social media marketing to the next level!"],
        RedirectLink: "/agency/social-media-marketing"
    },
    {
        heading: "Lead Generation",
        SubPara: "Our Lead Generation services help you attract and convert potential customers effectively",
        ListPara: ["Targeted Campaigns: We create tailored marketing campaigns that reach your ideal audience and capture their interest",
            "Compelling Offers: We develop irresistible offers and content that encourage users to provide their contact information",
            "Performance Tracking: We monitor and analyse lead generation efforts to ensure optimal results and continuous improvement."],
        Content: ["With our strategies, we help you build a strong pipeline of qualified leads, turning prospects into loyal customers. Let us help you boost your lead generation efforts!"],
        RedirectLink: ROUTES.AGENCY_SERVICES.LEAD_GENERATION

    },
    {
        heading: "Pay-Per-Click",
        SubPara: "Our PPC (Pay-Per-Click) Advertising services help you drive targeted traffic to your website quickly and efficiently",
        ListPara: ["Strategic Ad Creation: We design eye-catching ads that grab attention and encourage clicks from your ideal customers",
            "Targeted Campaigns: We use precise targeting to reach the right audience at the right time, maximising your ad spend",
            "Performance Analysis: We continuously monitor and optimise campaigns to improve ROI and ensure your ads perform at their best"],
        Content: ["With our PPC expertise, we help you achieve immediate visibility and generate leads, driving growth for your business. Let us elevate your PPC advertising!"],
        RedirectLink: "/agency/pay-per-click"
    },
    {
        heading: "Performance Marketing",
        SubPara: "Our Performance Marketing services focus on delivering measurable results and maximising your return on investment",
        ListPara: ["Data-Driven Strategies: We create targeted campaigns based on data analysis to reach your specific audience effectively.",
            "Multi-Channel Approach: We leverage various platforms, including search engines, social media, and display networks, to boost visibility and engagement.",
            "Real-Time Optimization: We continuously monitor performance metrics, making adjustments to improve results and maximise your budget"],
        Content: ["With our performance marketing expertise, we help you achieve your business goals through strategies that focus on results. Let us drive success for your brand."],
        RedirectLink: "/agency/performance-marketing"
    },
    {
        heading: "Digital Media Planning & Buying",
        SubPara: "Our Digital Media Planning & Buying services ensure your brand reaches the right audience at the right time.",
        ListPara: ["Strategic Planning: We develop comprehensive media plans that align with your goals and target audience, optimising your ad spend.",
            "Targeted Buying: We negotiate and purchase ad placements across various digital platforms, ensuring maximum visibility and engagement.",
            "Performance Monitoring: We track and analyse campaign performance to make data-driven adjustments, ensuring your campaigns deliver the best results."],
        Content: ["With our expertise in digital media planning and buying, we help you effectively connect with your audience and achieve your marketing objectives. Let us elevate your digital advertising efforts!."],
        RedirectLink: "/agency/digital-media-planning-and-buying"
    },
    {
        heading: "SEO Services",
        SubPara: "	Our SEO (Search Engine Optimization) services help improve your website's visibility and drive organic traffic.",
        ListPara: ["Comprehensive Audit: We conduct a thorough analysis of your website to identify strengths, weaknesses, and opportunities for improvement.",
            "On-Page Optimization: We optimize your website’s content, structure, and meta tags to ensure it ranks well on search engines.",
            "Keyword Research: We identify and target relevant keywords that potential customers are searching for, enhancing your site's relevance.", "Content Strategy: We develop a content plan that focuses on creating high-quality, engaging content to attract and retain visitors.",
            "Content Strategy: We develop a content plan that focuses on creating high-quality, engaging content to attract and retain visitors."],
        Content: ["With our SEO expertise, we help your business achieve higher rankings, increase website traffic, and boost conversions. Let us enhance your online presence!"],
        RedirectLink: "/agency/search-engine-optimization"
    },
    {
        heading: "Content Marketing",
        SubPara: "Our Content Marketing services help you connect with your audience and build brand loyalty through valuable content.",
        ListPara: ["Strategy Development: We create a tailored content strategy that aligns with your business goals and resonates with your target audience.",
            "Engaging Content Creation: Our team produces high-quality blogs, articles, infographics, and videos that inform and engage your audience.",
            "Distribution and Promotion: We ensure your content reaches the right people through effective distribution channels and promotional tactics.",
            "Performance Analysis: We track and analyze content performance to understand what works, allowing us to refine strategies for better results."],
        Content: ["With our content marketing expertise, we help you attract and retain customers while enhancing your brand’s visibility and authority. Let us elevate your content marketing efforts!"],
        RedirectLink: "/agency/content-marketing-service"
    },
    {
        heading: "Email marketing",
        SubPara: "Our Email Marketing services help you connect with your audience and drive sales.",
        ListPara: ["List Building: We help you grow a strong email list of interested subscribers.",
            "Engaging Campaigns: We create eye-catching emails that grab attention and encourage action.",
            "Personalization: We tailor messages to different audience segments, making them more relevant.",
            "Performance Tracking: We monitor results like open rates and clicks to improve future campaigns."],
        Content: ["With our email marketing expertise, we help you build customer relationships and boost your business. Let us enhance your email marketing strategy!"],
        RedirectLink: "/agency/email-marketing-service"
    },
    {
        heading: "Automation",
        SubPara: "Our Marketing Automation services streamline your marketing efforts and enhance efficiency.",
        ListPara: ["Automated Workflows: We help set up automated processes to manage repetitive tasks, saving you time and resources",
            "Immediate Lead Notifications: We ensure you receive instant alerts when new leads come in, enabling timely follow-ups.",
            "Lead Touch Points: We create automated touch points to nurture leads through the sales funnel, ensuring consistent engagement.",
            "Personalised Messaging: We deliver targeted messages based on user behaviour, making sure your audience receives relevant content.",
            "Performance Analytics: We track and analyse campaign performance to optimise strategies and improve results."],
        Content: ["With our marketing automation expertise, we help enhance productivity and achieve better marketing outcomes. Let us simplify your marketing efforts!"],
        RedirectLink: "/agency/marketing-automation"
    },
]
import React from "react";
import images from "../../../../helpers/images";
import { useNavigate } from "react-router-dom";

const ContentSection = () => {
  const navigate = useNavigate();
  return (
    <div className="Agency_detail_page" id="about_top_content">
      <div className="intro section section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="purpose_left" data-aos="fade-up">
                <h1>
                  Lead <br className="break_tag" />
                  Generation{" "}
                </h1>
                <button
                  onClick={() => navigate("/contact-us")}
                  className="button_red mt-4 agency_into_button"
                >
                  Keen To Hear?
                </button>
              </div>
            </div>
            <div className="col-lg-8 my-auto">
              <div className="purpose_right pe-5" data-aos="fade-down">
                <p>
                  Struggling to find the right customers? Our tailored lead
                  generation strategies are designed to bridge the gap between
                  your business and your target audience. We focus on delivering
                  high-quality prospects that drive engagement, boost
                  conversions, and fuel your business growth. Let us help you
                  connect with the right people at the right time, ensuring
                  measurable results every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="why_digital_market_section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div
                className="why_digital_market_left"
                data-aos="fade-down-right"
              >
                <h2 className="headings_text_black">
                  Should You Use Lead <br className="break_tag" />
                  Generation for Marketing?
                </h2>
                <img
                  src={images.Advisory_marketing_rght_img}
                  alt="../"
                  className="why_digital_market_image mt-4"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="why_digital_market_right"
                data-aos="fade-down-left"
              >
                <p className="text-black">
                  Choosing us for your lead generation needs means partnering
                  with a team that delivers results. We specialize in creating
                  targeted campaigns that connect your message with the right
                  audience, ensuring maximum engagement and conversions.
                  <br />
                  <br />
                  Our creative, compelling offers are designed to capture
                  quality leads who are genuinely interested in your business,
                  while our data-driven performance tracking ensures continuous
                  improvement for optimal results.
                  <br />
                  <br /> With a proven track record of building strong
                  pipelines, we turn prospects into loyal customers through
                  customized strategies tailored to your unique goals.
                  <br />
                  <br /> Backed by dedicated support and expertise, we’re here
                  to help you fuel your growth and achieve outstanding outcomes.{" "}
                  <br />
                  <br />
                  Let us take your lead generation efforts to the next level!
                </p>
                <button
                  onClick={() => navigate("/contact-us")}
                  className="button_gradient mt-4"
                >
                  Lean More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agency_detail_listing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 px-0">
              <div
                className="agency_detail_whyChoose_left"
                style={{ backgroundColor: "black" }}
                data-aos="zoom-out-right"
              >
                <div className="container">
                  <h2 className="headings_text_white">
                    Why Choose Social Eagle’s Lead Generation Services in Dubai?
                  </h2>
                  <p className="paraWhite_margin_top">
                    What if you could have a constant stream of high-quality
                    leads eager to connect with your business?
                    <br />
                    <br /> That’s what we deliver. At Social Eagle, we
                    specialize in lead generation strategies that don’t just
                    bring in numbers, they bring results.
                    <br />
                    <br />
                  </p>
                  <img
                    src={images.social_management_whyChoose}
                    alt="../"
                    className="details_page_whyChoose"
                  />
                  <p className="paraWhite_margin_top mt-5">
                    With our strategies, we help you build a strong pipeline of
                    qualified leads, turning prospects into loyal customers. Let
                    us help you boost your lead generation efforts!
                  </p>
                  <button
                    onClick={() => navigate("/contact-us")}
                    className="button_red mt-5"
                  >
                     Get FREE Consultation
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0">
              <div
                className="agency_detail_whyChoose_rght"
                data-aos="zoom-out-left"
              >
                <div className="agency_detail_whyChoose_rght_content mt-0">
                  <div>
                    <img
                      src={images.SocialMarket_icon1}
                      alt="../"
                      className="our_value_icon"
                    />
                  </div>
                  <div>
                    <h5 className="headings_text_white">Targeted Campaigns</h5>
                    <p>
                      We design targeted advertising campaigns that attract and
                      convert your ideal customers.
                    </p>
                    <p></p>
                  </div>
                </div>
                <div className="agency_detail_whyChoose_rght_content">
                  <div>
                    <img
                      src={images.SocialMarket_icon2}
                      alt="../"
                      className="our_value_icon"
                    />
                  </div>
                  <div>
                    <h5 className="headings_text_white">Compelling Offers</h5>
                    <p>
                      We develop irresistible offers and content that encourage
                      users to provide their contact information.
                    </p>
                  </div>
                </div>
                <div className="agency_detail_whyChoose_rght_content">
                  <div>
                    <img
                      src={images.SocialMarket_icon3}
                      alt="../"
                      className="our_value_icon"
                    />
                  </div>
                  <div>
                    <h5 className="headings_text_white">
                      Performance Tracking
                    </h5>
                    <p>
                      {" "}
                      We monitor and analyse lead generation efforts to ensure
                      optimal results and continuous improvement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;

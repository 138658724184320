import images from "../../helpers/images";
import ROUTES from "../constants/routes";

export const blogs_mainpage_content = [
  {
    image: images.blog_1,
    heading: "Technology",
    content: "Digital Marketing and Its Future",
    redirect_link: ROUTES.BLOGS_SERVICE.DIGITAL_MARKETING_FUTURES,
  },
  {
    image: images.blog_2,
    heading: "Technology",
    content:
      "6 Pillars of Digital Marketing: A Guide for Businesses in Dubai, UAE",
    redirect_link: ROUTES.BLOGS_SERVICE.SIX_PILLAR_OF_DIGITAL_MARKETING,
  },
  {
    image: images.blog_3,
    heading: "Technology",
    content:
      "Top 10 Reasons Your Business in Dubai Needs the Best Digital Marketing Agency",
    redirect_link: ROUTES.BLOGS_SERVICE.TEN_REASON_FOR_DIGITAL_MARKETING,
  },
  {
    image: images.blog_4,
    heading: "Technology",
    content: "How to Choose the Right Digital Marketing Agency in Dubai",
    redirect_link: ROUTES.BLOGS_SERVICE.CHOOSE_RIGHT_DIGITAL_MARKETING,
  },
  {
    image: images.blog_5,
    heading: "Technology",
    content:
      "Top Digital Marketing Trends for 2025 That Businesses in Dubai Must Follow",
    redirect_link: ROUTES.BLOGS_SERVICE.DIGITAL_MARKETING_2025TRENDS,
  },
  {
    image: images.blog_6,
    heading: "Technology",
    content: "Find How to Boost Your Business with Social Media Marketing",
    redirect_link: ROUTES.BLOGS_SERVICE.BOOST_YOUR_BUSINESS_WITH_SOCIALMEDIA,
  },
];

import React, { useEffect } from 'react'
import Navbar from '../component/Navbar/Navbar'
import AgencyBanner from '../component/Agency/AgencyBanner'
import AgencyContent from '../component/Agency/AgencyContent'
import Footer from '../component/Footer/Footer'
import TechnologyPartnerBanner from '../component/TechnologyPartner.js/TechnologyPartnerBanner'
import TechnologyPartnerContent from '../component/TechnologyPartner.js/TechnologyPartnerContent'
import AgencyContent2 from '../component/Agency/AgencyContent2'

const Agency = () => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (

        <div className='aboutus_main '>
            <Navbar />
            <AgencyBanner />
            {/* <AgencyContent /> */}
            <AgencyContent2/>
            <Footer />
        </div>
    )
}

export default Agency
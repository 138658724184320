import React from 'react'
import images from "../../../../helpers/images"
import Advisory_marketing_rght_img from "../../../../assets/images/scm_15.webp";
import social_management_whyChoose from "../../../../assets/images/scm_16.webp";
import { useNavigate } from 'react-router-dom';
const ContentSection = () => {
    const navigate = useNavigate();
    return (
        <div className='Agency_detail_page' id="about_top_content">
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='purpose_left' data-aos="zoom-in">
                                <h1> Email  <br className='break_tag' /> Marketing</h1>
                                <button onClick={()=>navigate('/contact-us')} className='button_red mt-4 agency_into_button'>Consult Now</button>
                            </div>
                        </div>
                        <div className='col-lg-8 my-auto'>
                            <div className='purpose_right pe-5' data-aos="zoom-out">
                                <p>Looking to connect with your audience and drive sales effectively?</p>
                                <p>Our Email Marketing services are designed to do just that, helping you build strong relationships and boost your business through targeted email campaigns.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-5'>
                            <div className='why_digital_market_left' data-aos="fade-down-right">
                                <h2 className='headings_text_black'>Why Email Marketing</h2>
                                <img src={Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='why_digital_market_right' data-aos="fade-down-left">
                                <p className='paraWhite_margin_top  text-black'>Email marketing is one of the most effective tools for directly reaching your audience in today’s digital landscape. As consumers are bombarded with information from various channels, a well-crafted email can cut through the noise and capture attention, ensuring your message stands out in crowded inboxes.</p>
                                <button onClick={()=>navigate('/contact-us')} className='button_gradient mt-4'> Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='Agency_added_section'>
                <div className='container'>
                    <div className='Agency_added_section_heading py-4'>
                        <h1 className='heading_para text-center' data-aos="zoom-in">Here are several reasons why email marketing <br className='break_tag' />should be a cornerstone of your marketing strategy </h1>
                    </div>
                    <div className='section_padding_bottom'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex'>
                                    <div><p className='number_list_backred' data-aos="fade-up">1</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-down">
                                        <h4 className='heading_para my-auto'>Direct Communication</h4>
                                        <p>Email allows you to communicate directly with your audience, offering a personal touch that social media or other platforms often lack. This direct line fosters stronger relationships and encourages customer loyalty.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex me-0'>
                                    <div><p className='number_list_backred'  data-aos="fade-down">2</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-up">
                                        <h4 className='heading_para my-auto'>High Return on Investment (ROI)</h4>
                                        <p>Email marketing consistently delivers one of the highest ROIs compared to other marketing channels. Every dollar spent on email marketing can yield significant returns, making it a cost-effective choice for businesses of all sizes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex'>
                                    <div><p className='number_list_backred' data-aos="fade-up">3</p></div>
                                    <div className='Agency_added_flexdiv'  data-aos="fade-down">
                                        <h4 className='heading_para my-auto'>Targeted Messaging</h4>
                                        <p>   With the ability to segment your audience based on behavior, demographics, or purchase history, email marketing enables you to send tailored messages that resonate with specific groups. This targeted approach increases engagement and conversion rates.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex me-0'>
                                    <div><p className='number_list_backred'  data-aos="fade-down">4</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-up">
                                        <h4 className='heading_para my-auto'>Measurable Results</h4>
                                        <p>Email marketing platforms provide valuable analytics that allow you to track open rates, click-through rates, and conversions. This data empowers you to refine your strategies, improve your content, and ultimately enhance your campaigns for better performance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection d-flex'>
                                    <div><p className='number_list_backred' data-aos="fade-up">5</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-down">
                                        <h4 className='heading_para my-auto'>Automated Campaigns</h4>
                                        <p>Automation tools allow you to set up drip campaigns and triggered emails based on user actions, ensuring timely and relevant communication. This not only saves time but also enhances the user experience by delivering information when it's most needed.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection d-flex'>
                                    <div><p className='number_list_backred' data-aos="fade-down">6</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-up">
                                        <h4 className='heading_para my-auto'>Fostering Connections</h4>
                                        <p> Beyond just sending promotional content, email marketing is about building and nurturing relationships. By providing value through informative newsletters, exclusive offers, and personalized messages, you can engage your audience on a deeper level.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }} data-aos="fade-right">
                                <div className='container'>
                                    <h2 className='headings_text_white'>Why Choose Our Email <br className='break_tag' /></h2>
                                    <p className='paraWhite_margin_top mb-5'>We deliver results. While many can send emails, our team understands how to create campaigns that resonate, engage, and convert.</p>
                                    <img src={social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                    <p className='paraWhite_margin_top mt-5'>With our expertise in email marketing, we help you build meaningful customer relationships and elevate your business’s success. Ready to enhance your email marketing strategy?</p>
                                    <button onClick={()=>navigate('/contact-us')} className='button_red mt-5 agency_details_button'> Get Your FREE Email Marketing Consultation Today!</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_rght' data-aos="fade-left">
                                <h3 className='headings_text_white mb-5'>Here’s what sets our email <br className='break_tag' />marketing apart<br className='break_tag' /></h3>
                                <div className='agency_detail_whyChoose_rght_content mt-0'>
                                    <div>
                                        <img src={images.Email_market_icon1} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Strategic List Building</h5>
                                        <p>We focus on growing a strong email list filled with engaged and interested subscribers, ensuring your messages reach those who genuinely want to hear from you. A quality list is the foundation of successful email marketing.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Email_market_icon2} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Engaging Campaigns That Captivate</h5>
                                        <p> Our team crafts eye-catching emails that grab attention and encourage action. With compelling subject lines, attractive designs, and valuable content, we ensure your emails stand out in crowded inboxes.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Email_market_icon3} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Personalization for Greater Relevance</h5>
                                        <p>We tailor messages to different audience segments, making each email more relevant to the recipient. By understanding your audience’s preferences and behaviours, we create personalized content that increases engagement and drives conversions.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Email_market_icon4} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>In-Depth Performance Tracking</h5>
                                        <p>Our commitment to your success doesn’t stop with sending emails. We monitor key metrics like open rates, click rates, and conversions, providing valuable insights that guide future campaigns. This data-driven approach allows us to refine our strategies continually, maximizing results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
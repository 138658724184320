import React, { useEffect } from 'react'
import Navbar from '../component/Navbar/Navbar'
import AdvisoryCommonSections from '../component/Advisory/AdvisoryCommonSections'
import Footer from '../component/Footer/Footer'
import BannerSection from '../component/Advisory/SubPages/BrandingStrategyAndConsulting/BannerSection'
import ContentSection from '../component/Advisory/SubPages/BrandingStrategyAndConsulting/ContentSection'

const BrandingStrategyAndConsulting = () => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (
        <div>
            <Navbar />
            <BannerSection />
            <ContentSection />
            <AdvisoryCommonSections />
            <Footer />
        </div>
    )
}

export default BrandingStrategyAndConsulting
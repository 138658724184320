import React, { useRef } from 'react'
import images from "../../../../helpers/images"
import { useNavigate } from 'react-router-dom';

const ContentSection = () => {
    const sectionRef = useRef(null);

    const handleScroll = () => {
        const section = sectionRef.current;
        if (section) {
            const scrollTop = section.scrollTop;

            // Synchronize scroll for both columns
            const leftColumn = section.querySelector(".agency_detail_listing_left");
            const rightColumn = section.querySelector(".agency_detail_listing_right");

            if (leftColumn) leftColumn.scrollTop = scrollTop;
            if (rightColumn) rightColumn.scrollTop = scrollTop;
        }
    };

    const navigate = useNavigate();

    return (
        <div className='Agency_detail_page ' id="about_top_content">
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='purpose_left' data-aos="fade-down-left">
                                <h1> Funnel Building <br className='break_tag' />& Marketing </h1>
                                <button onClick={()=>navigate('/contact-us')} className='button_red mt-4 agency_into_button'>Learn More</button>
                            </div>
                        </div>
                        <div className='col-lg-8 my-auto' >
                            <div className='purpose_right pe-5' data-aos="fade-down-right">
                                <p>Ready to turn potential leads into loyal customers? Our Funnel Building & Marketing services are designed to guide your audience through every stage of their journey—converting curiosity into lasting brand loyalty.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-5'>
                            <div className='why_digital_market_left' data-aos="fade-up">
                                <h2 className='headings_text_black'>Why Funnel Building &<br className='break_tag' /> Marketing? </h2>
                                <img src={images.funnel_2} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='why_digital_market_right' data-aos="fade-down">
                                <p className='paraWhite_margin_top text-black'>An effective sales funnel is essential for nurturing leads and driving conversions. By creating a structured, step-by-step journey, a sales funnel ensures that each customer interaction is meaningful and strategically crafted to move them closer to a purchase decision. Whether you’re looking to boost conversions, increase sales, or retain customers, a well-designed funnel makes all the difference.</p>
                                <button onClick={()=>navigate('/contact-us')} className='button_gradient mt-4'> Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='Agency_added_section'>
                <div className='container'>
                    <div className='Agency_added_section_heading py-4'>
                        <h1 className='heading_para text-center' data-aos="zoom-in">How Funnel Building & Marketing<br className='break_tag' /> Benefits Your Business </h1>
                    </div>
                    <div className='section_padding_bottom'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex'>
                                    <div><p className='number_list_backred' data-aos="fade-down-left">1</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-down-right">
                                        <h4 className='heading_para my-auto'>Streamlined Lead Conversion</h4>
                                        <p>By guiding customers through a clear journey, you reduce friction and make conversions more likely at every stage.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex me-0'>
                                    <div><p className='number_list_backred' data-aos="fade-down-right">2</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-down-left">
                                        <h4 className='heading_para my-auto'>Enhanced Customer Experience</h4>
                                        <p> A well-crafted funnel ensures your audience receives relevant content, creating a seamless, positive experience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection d-flex'>
                                    <div><p className='number_list_backred' data-aos="fade-down-left">3</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-down-right">
                                        <h4 className='heading_para my-auto'>Increased ROI</h4>
                                        <p> With each step optimized for success, your funnel generates better results, helping you achieve higher returns on your marketing investment.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection d-flex me-0'>
                                    <div><p className='number_list_backred' data-aos="fade-down-right">4</p></div>
                                    <div className='Agency_added_flexdiv' data-aos="fade-down-left">
                                        <h4 className='heading_para my-auto'>Stronger Customer Loyalty</h4>
                                        <p>By engaging with your audience thoughtfully and consistently, you create a lasting bond, turning one-time buyers into loyal advocates.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing' ref={sectionRef} onScroll={handleScroll}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 px-0'>
                            <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }} data-aos="zoom-in">
                                <div className='container'>
                                    <h2 className='headings_text_white'>Why Choose Our Funnel<br className='break_tag' />Building & Marketing <br className='break_tag' />Services?</h2>
                                    <p className='paraWhite_margin_top mb-5'>We create data-driven, high-impact funnels that optimize every stage of the customer journey. From attracting new visitors to encouraging repeat purchases, our team ensures your funnel is tailored to your brand’s needs and maximizes conversion potential.</p>
                                    <img src={images.funnel_1} alt="../" className='details_page_whyChoose' />
                                    <p className='paraWhite_margin_top mt-5'>With our funnel-building expertise, we help you transform interested visitors into satisfied, loyal customers.</p>
                                    <h2 className='headings_text_white mt-5'>Ready to make every <br className='break_tag' /> stage of the customer<br className='break_tag' />journey work for you?</h2>
                                    <button onClick={()=>navigate('/contact-us')} className='button_red mt-5 agency_details_button'> Schedule Your FREE Funnel Strategy Session</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 px-0 '>
                            <div className='agency_detail_whyChoose_rght' data-aos="zoom-in">
                                <h3 className='headings_text_white mb-5'>Here’s how we build successful  <br className='break_tag' /> funnels</h3>
                                <div className='agency_detail_whyChoose_rght_content mt-0'>
                                    <div>
                                        <img src={images.Funnel_marketing_icon1} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Audience-Centric Strategy</h5>
                                        <p> We start by understanding your target audience’s needs, pain points, and motivations, enabling us to create tailored content that speaks to each stage of their journey.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content'>
                                    <div>
                                        <img src={images.Funnel_marketing_icon2} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Awareness & Attraction</h5>
                                        <p> We help draw in potential customers with engaging content and optimized ad strategies that increase visibility, sparking interest from the start.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content mb-5'>
                                    <div>
                                        <img src={images.Funnel_marketing_icon3} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Nurturing & Education</h5>
                                        <p>   Through valuable content, email campaigns, and personalized messaging, we keep potential leads engaged, nurturing their journey from awareness to intent.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content mb-5'>
                                    <div>
                                        <img src={images.Funnel_marketing_icon4} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Conversion Optimization</h5>
                                        <p>  We craft a smooth, user-friendly experience that guides leads seamlessly toward the final purchase decision, maximizing your conversion rates.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content mb-5'>
                                    <div>
                                        <img src={images.Funnel_marketing_icon5} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Data-Driven Insights & Optimization</h5>
                                        <p> Our team continuously monitors funnel performance, refining each step based on data to improve results and ROI.</p>
                                    </div>
                                </div>
                                <h3 className='headings_text_white mt-5'>Our Funnel Building & Marketing <br className='break_tag' />  Process</h3>
                                <div className='agency_detail_whyChoose_rght_content '>
                                    <div>
                                        <img src={images.Funnel_marketing_icon6} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Analysis & Strategy</h5>
                                        <p>  We start by analyzing your audience and competitors to create a funnel strategy that aligns with your goals.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content '>
                                    <div>
                                        <img src={images.Funnel_marketing_icon7} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Content Creation</h5>
                                        <p>  We develop tailored, value-driven content for each stage of the funnel to keep prospects engaged and informed.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content '>
                                    <div>
                                        <img src={images.Funnel_marketing_icon8} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Implementation</h5>
                                        <p>  Using proven marketing tools and platforms, we build your funnel for optimal performance and seamless navigation.</p>
                                    </div>
                                </div>
                                <div className='agency_detail_whyChoose_rght_content '>
                                    <div>
                                        <img src={images.Funnel_marketing_icon9} alt="../" className='our_value_icon' />
                                    </div>
                                    <div>
                                        <h5 className='headings_text_white'>Ongoing Optimization</h5>
                                        <p>  We continually monitor and refine your funnel’s performance, using insights to boost conversions and enhance customer satisfaction.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
import React from 'react'
import images from "../../../../helpers/images"



const ContentSection = () => {
    return (
        <div id="about_top_content">
            <div className='advisory_parent_section'>
                <div className='agency_detail_heading'>
                    <div className='purpose_left section_padding' data-aos="fade-up">
                        <h1 className='text-center text-white' data-aos="zoom-in">Marketing Strategy<br className='break_tag' /> & Consulting</h1>
                    </div>
                    <div className='container'>
                        <div className=''>
                            <div className='row'>
                                <div className='col-lg-5  my-auto'>
                                    <div className='advisory_details_content_left' data-aos="zoom-out-left">
                                        <h2 className='heading_para text-white'>Identifying Bottlenecks In <br className='break_tag' />Your Workflow</h2>
                                        <p>Unlock your brand's fullest potential with our <span className='font-bold text-danger'>Marketing Strategy & Consulting</span>  services.
                                            Our experts collaborate closely with you to understand your business goals,
                                            whether that means increasing brand awareness, driving sales, or entering new markets</p>
                                        <p>By setting realistic objectives, we ensure your marketing efforts align with your overall business strategy.</p>
                                    </div>
                                </div>
                                <div className='col-lg-5 mx-auto'>
                                    <div className='' data-aos="zoom-out-right">
                                        <img src={images.advisory_market_1} alt="../" className='w-100 Advisory_marketing_rght_img' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='section_padding'>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='' data-aos="zoom-out-right">
                                        <img src={images.advisory_market_2} alt="../" className='w-100 Advisory_marketing_left_img' />
                                    </div>
                                </div>
                                <div className='col-lg-5  my-auto mx-auto'>
                                    <div className='advisory_details_content_left' data-aos="zoom-out-left">
                                        <h2 className='heading_para text-white'>Customized Strategies For  <br className='break_tag' />Targeted Audiences</h2>
                                        <p>We analyze market dynamics and customer behavior to define your target audience
                                            accurately. With this understanding, we develop customized strategies tailored
                                            to resonate with your specific demographic, ensuring your marketing campaigns are
                                            both effective and impactful.</p>
                                        <p>
                                        Our data-driven approach guarantees that every initiative is designed to drive results, enhancing your market presence.                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
import React, { useEffect, useRef, useState } from "react";
import why_digital_market from "../../assets/images/agency_1.webp";
import business_goals from "../../assets/images/scm_20.webp";

import Branding from "./Branding";
import Digital from "./Digital";

const AgencyContent2 = () => {
  const agencyHeading = ["Digital Marketing", "Branding & Designing"];
  const [selectedTab, setSelectedTab] = useState("Digital Marketing");

  return (
    <div className="position-relative " id="about_top_content">
      <div className="agency-tab-section my-5 mx-auto">
        {agencyHeading.map((tab, index) => {
          return (
            <h5
              key={index}
              onClick={() => {
                setSelectedTab(tab);
                window.scroll(0, 0);
              }}
              className={`d-flex mb-0  ${selectedTab === tab
                  ? "agency-tab-content line-anim-result active"
                  : "agency-tab-content"
                }`}
            >
              {tab}
            </h5>
          );
        })}
      </div>
      {selectedTab === "Digital Marketing" && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="purpose_left">
                  <h1>
                    Digital <br className="break_tag" />
                    Marketing
                  </h1>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="purpose_right mt-4 section_padding_bottom">
                  <p>
                    Digital marketing, or online marketing, is using websites,
                    apps, social media, search engines, and other online tools
                    to promote a business, sell products, boost brand awareness,
                    or reach other key business goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="why_digital_market_section  mb-5 ">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-5">
                  <div className="why_digital_market_left ">
                    <img
                      src={why_digital_market}
                      alt="../"
                      className="why_digital_market_image "
                    />
                  </div>
                </div>
                <div className="col-lg-6 my-auto">
                  <div className="why_digital_market_right">
                    <h2 className="headings_text_black">
                      Why Does Digital Marketing Matters for Your Business?
                    </h2>
                    <p className="text-black mt-3">
                      Digital marketing is important because it helps businesses
                      connect with people online. Here’s why it matters:
                    </p>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex why_digital_market_list">
                          <p className="number_list">1</p>
                          <p className="text-black my-auto  ms-2">
                            Reach More People
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex why_digital_market_list">
                          <p className="number_list">2</p>
                          <p className="text-black my-auto  ms-2">Save Money</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex why_digital_market_list">
                          <p className="number_list">3</p>
                          <p className="text-black my-auto  ms-2">
                            Target the Right Audience
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex why_digital_market_list">
                          <p className="number_list">4</p>
                          <p className="text-black my-auto  ms-2">
                            Track Results
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex why_digital_market_list">
                          <p className="number_list">5</p>
                          <p className="tex-white my-auto  ms-2">
                            Build Relationships
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex why_digital_market_list">
                          <p className="number_list">6</p>
                          <p className="text-black my-auto  ms-2">
                            Stay Up-to-Date
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Digital />
        </>
      )}
      {selectedTab === "Branding & Designing" && (
        <>
          <div className={` first_technology_tab}`}>
            <div className="container">
              <div className="purpose_left ">
                <h1 className="text-center">Branding & Designing</h1>
              </div>
            </div>
          </div>
          <div className="why_digital_market_section pb-0">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-5">
                  <div className="why_digital_market_left section_padding_top">
                    <h2 className="headings_text_black ps-2">
                      Transform your brand with <br className="break_tag" />
                      our expert Branding & <br className="break_tag" />
                      Designing services!
                    </h2>
                    <img
                      src={business_goals}
                      alt="../"
                      className="why_digital_market_image why_digital_market_image_responsive mt-4"
                      data-aos="fade-down"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="why_digital_market_right section_padding">
                    <p
                      className="text-black mt-4 mt-md-0"
                      data-aos="zoom-out-left"
                    >
                      <span className="font-medium">
                        Brand Strategy Development:{" "}
                      </span>{" "}
                      We’ll work with you to clarify your brand’s vision,
                      mission, and values, setting the stage for a powerful
                      identity.{" "}
                    </p>
                    <p className="text-black mt-4">
                      <span className="font-medium">
                        Visual Identity Creation:{" "}
                      </span>{" "}
                      Our designers will create logos and visual elements that
                      truly reflect who you are and connect with your audience.{" "}
                    </p>
                    <p className="text-black mt-4">
                      <span className="font-medium">
                        Marketing Collateral Design:{" "}
                      </span>{" "}
                      From business cards to brochures, we design stunning
                      materials that keep your branding consistent and engaging.{" "}
                    </p>
                    <p className="text-black mt-4">
                      <span className="font-medium">
                        Website and UI/UX Design:{" "}
                      </span>{" "}
                      We build beautiful, user-friendly websites that enhance
                      user experience and boost conversions.{" "}
                    </p>
                    <p className="text-black mt-4">
                      Let’s create a memorable brand identity together! Your
                      brand deserves to make an impact.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Branding />
        </>
      )}
    </div>
  );
};

export default AgencyContent2;

import React, { useEffect } from 'react'
import Navbar from '../component/Navbar/Navbar'
import Footer from '../component/Footer/Footer'
import AboutUsBanner from '../component/aboutUs/AboutUsBanner'
import AboutUsContent from '../component/aboutUs/AboutUsContent'

const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className='aboutus_main overflow-hidden'>
      <Navbar />
      <AboutUsBanner />
      <AboutUsContent />
      <Footer />
    </div>
  )
}

export default AboutUs
import React from "react";
import casestudy_img_0 from "../../assets/images/case_study_img/case-study-img-0.webp";
import casestudy_img_1 from "../../assets/images/case_study_img/case-study-img-1.webp";
import casestudy_img_2 from "../../assets/images/case_study_img/case-study-img-2.webp";
import casestudy_img_3 from "../../assets/images/case_study_img/case-study-img-3.webp";
import casestudy_img_4 from "../../assets/images/case_study_img/case-study-img-4.webp";
import casestudy_img_5 from "../../assets/images/case_study_img/case-study-img-5.webp";
import casestudy_img_6 from "../../assets/images/case_study_img/case-study-img-6.webp";
import { Link } from "react-router-dom";
import ROUTES from "../../helpers/constants/routes";

const CaseStudyContent = () => {
  return (
    <div id="about_top_content">
      <section className="">
        <div className="purposeof_se_parent_section mb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 my-auto">
                <div
                  className="headings_text_black text-center mt-2 mb-5"
                  data-aos="zoom-out"
                  data-aos-duration="1000"
                >
                  <h1>Case Study</h1>
                </div>
                <div
                  className="text-center mt-4 mb-5"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <p>
                    Ready to dominate the fitness market? See how Green Protein
                    achieved 3.35x ROAS in just 30 days and let us do the same
                    for you! has context menu
                  </p>
                </div>
              </div>
              {/* <div className='col-lg-6 my-auto'>
                                <div className='purpose_right mt-4 mb-5'>
                                    <p>Ready to dominate the fitness market?
                                        See how Green Protein achieved 3.35x ROAS in just 30 days and let us do the same for you!
                                        has context menu</p>
                                </div>
                            </div> */}
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div>
                  <div
                    className="cs-card"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <div className="image-container">
                      <img
                        src={casestudy_img_0}
                        alt="Green Protein"
                        className="case-study-image"
                      />
                      <div className="overlay">
                        <Link
                          to={ROUTES.CASE_STUDY_SERVICE.GREEN_PROTEIN}
                          className="read-more"
                        >
                          Read More{" "}
                          <i className="fa-solid fa-arrow-right contact_arrow"></i>
                        </Link>
                      </div>
                    </div>
                    <h2 className="headings_text_black mt-4">Green Protein</h2>
                    <p className="mb-5">
                      Performance marketing to increase online sales.
                    </p>
                  </div>
                  <div
                    className="cs-card"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <div className="image-container">
                      <img
                        src={casestudy_img_2}
                        alt="Green Protein"
                        className="case-study-image"
                      />
                      <div className="overlay">
                        <Link
                          to={ROUTES.CASE_STUDY_SERVICE.TECHGYAN}
                          className="read-more"
                        >
                          Read More{" "}
                          <i className="fa-solid fa-arrow-right contact_arrow"></i>
                        </Link>
                      </div>
                    </div>
                    <h2 className="headings_text_black mt-4">Techgyan</h2>
                    <p className="mb-5">
                      Performance marketing to increase online info-product
                      sales.
                    </p>
                  </div>
                  <div
                    className="cs-card"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <div className="image-container">
                      <img
                        src={casestudy_img_4}
                        alt="Green Protein"
                        className="case-study-image"
                      />
                      <div className="overlay">
                        <Link
                          to={ROUTES.CASE_STUDY_SERVICE.SKINPOT_CO}
                          className="read-more"
                        >
                          Read More{" "}
                          <i className="fa-solid fa-arrow-right contact_arrow"></i>
                        </Link>
                      </div>
                    </div>
                    <h2 className="headings_text_black mt-4">Skinpot Co</h2>
                    <p className="mb-5">
                      Market entry and category penetration while boosting
                      online sales.
                    </p>
                  </div>
                  <div
                    className="cs-card"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <div className="image-container">
                      <img
                        src={casestudy_img_6}
                        alt="Green Protein"
                        className="case-study-image"
                      />
                      <div className="overlay">
                        <Link
                          to={ROUTES.CASE_STUDY_SERVICE.YCB_ACADEMY}
                          className="read-more"
                        >
                          Read More{" "}
                          <i className="fa-solid fa-arrow-right contact_arrow"></i>
                        </Link>
                      </div>
                    </div>
                    <h2 className="headings_text_black mt-4">YCB Academy</h2>
                    <p className="mb-5">
                      Setup an appointment-setting funnel and automate lead
                      bookings.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <div
                    className="cs-card"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <div className="image-container">
                      <img
                        src={casestudy_img_1}
                        alt="Green Protein"
                        className="case-study-image"
                      />
                      <div className="overlay">
                        <Link
                          to={ROUTES.CASE_STUDY_SERVICE.HORLICKS}
                          className="read-more"
                        >
                          Read More{" "}
                          <i className="fa-solid fa-arrow-right contact_arrow"></i>
                        </Link>
                      </div>
                    </div>
                    <h2 className="headings_text_black mt-4">Horlicks</h2>
                    <p className="mb-5">
                      Market entry & product-market fit testing alongside
                      category penetration.
                    </p>
                  </div>
                  <div
                    className="cs-card"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <div className="image-container">
                      <img
                        src={casestudy_img_3}
                        alt="Green Protein"
                        className="case-study-image"
                      />
                      <div className="overlay">
                        <Link
                          to={ROUTES.CASE_STUDY_SERVICE.ECG_ACADEMY}
                          className="read-more"
                        >
                          Read More{" "}
                          <i className="fa-solid fa-arrow-right contact_arrow"></i>
                        </Link>
                      </div>
                    </div>
                    <h2 className="headings_text_black mt-4">ECG Academy</h2>
                    <p className="mb-5">
                      Acquiring new customers for info-products.
                    </p>
                  </div>
                  <div
                    className="cs-card"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <div className="image-container">
                      <img
                        src={casestudy_img_5}
                        alt="Green Protein"
                        className="case-study-image"
                      />
                      <div className="overlay">
                        <Link
                          to={ROUTES.CASE_STUDY_SERVICE.BLISSPADS}
                          className="read-more"
                        >
                          Read More{" "}
                          <i className="fa-solid fa-arrow-right contact_arrow"></i>
                        </Link>
                      </div>
                    </div>
                    <h2 className="headings_text_black mt-4">Blisspads</h2>
                    <p className="mb-5">
                      Market entry & increasing online sales through performance
                      marketing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CaseStudyContent;

import React from "react";
import contact_banner_img1 from "../../assets/images/banner/contact1.webp";
import contact_banner_img2 from "../../assets/images/banner/contact2.webp";
import contact_bar from "../../assets/images/contact_bar.webp";

const ContactUsBanner = () => {
  return (
    <div className="contact_parent_div position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="contact_banner_parent ">
              {/* <img src={contact_bar} alt="" className='contact_bar' /> */}
              <p className="mb-3">Contact Us</p>
              <h1 className="contact_banner_head" data-aos="fade-right" data-aos-delay="300">
                Let's work{" "}
                <img
                  src={contact_banner_img1}
                  alt=""
                  className="contact_banner_img"
                />
              </h1>
              <h1 className="contact_banner_head" data-aos="fade-left" data-aos-delay="300">
                <img
                  src={contact_banner_img2}
                  alt=""
                  className="contact_banner_img2"
                />
                {" "}together
              </h1>
              {/* <img src={contact_bar} alt="" className='contact_bar2' /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsBanner;

import React, { useState } from "react";
import ArrowTeam from "../../assets/images/home/arrow-team.webp";
import CustomModal from "../../common/CustomModal";


const OurTeamCard = ({ teamData }) => {
  const [activeCard, setActiveCard] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState("");
  const [currentItem, setCurrentItem] = useState({});

  return (
    <div>
      <div className="team-container">
        {teamData.map((member) => (
          <div
            className={`padding-card-team ${activeCard === member.id ? "expanded" : ""
              }`}
            key={member.id}
            onMouseEnter={() => setActiveCard(member.id)}
            onMouseLeave={() => setActiveCard(1)}
          >
            <div
              className={`team-card ${activeCard === member.id ? "expanded" : ""
                }`}
            >
              <div className="card-content">
                <div className="card-inner-content">
                  <h2 className="mb-2">{member.name}</h2>
                  <small className="team-card-small-data">{member.title}</small>
                  <div className="d-flex gap-3 mt-3">
                    {member.instaUrl &&
                      <div>
                        <a
                          className="our-team-sm-redirect"
                          href={member.instaUrl}
                          target="_blank"
                        >
                          <i class="fa-brands fa-instagram"></i>
                        </a>
                      </div>}
                    {member.faceBookUrl &&
                      <div>
                        <a
                          className="our-team-sm-redirect"
                          href={member.faceBookUrl}
                          target="_blank"
                        >
                          <i class="fa-brands fa-facebook"></i>
                        </a>
                      </div>}
                    {member.linkedinUrl &&
                      <div>
                        <a
                          className="our-team-sm-redirect"
                          href={member.linkedinUrl}
                          target="_blank"
                        >
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </div>}
                  </div>
                  <div
                    onClick={() => {
                      setOpenModal(true);
                      setCurrentMemberId(member.id);
                      setCurrentItem(member);
                    }}
                  >
                    <img
                      className="arrow-team-redirect"
                      src={ArrowTeam}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="card-image">
                <img src={member.img} alt={member.name} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="team-modal-open">
        <CustomModal
          open={openModal}
          onClickOutside={() => {
            setOpenModal(false);
            setCurrentMemberId(null);
          }}
        >
          <div className="modal-content contact-container m-auto">
            <div className="modal-body bg-team-modal w-100 p-0 ">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-md-8">
                    <div className="p-2">
                      <p className="text-light mb-2">About</p>
                      <h5 className="mb-2 text-light font-medium">
                        {currentItem.name}
                      </h5>
                      <small className="text-light">{currentItem.para}</small>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-4">
                    <div className="">
                      <img
                        className="team-open-card-modal"
                        src={currentItem.img}
                        alt={currentItem.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start px-4 gap-3 mt-3">
              {currentItem.instaUrl &&
                <div>
                  <a
                    className="our-team-sm-redirect"
                    href={currentItem.instaUrl}
                    target="_blank"
                  >
                    <i class="fa-brands fs-5 text-light fa-instagram"></i>
                  </a>
                </div>
              }
              {currentItem.faceBookUrl &&
                <div>
                  <a
                    className="our-team-sm-redirect"
                    href={currentItem.faceBookUrl}
                    target="_blank"
                  >
                    <i class="fa-brands fs-5 text-light fa-facebook"></i>
                  </a>
                </div>}
              {currentItem.linkedinUrl &&
                <div>
                  <a
                    className="our-team-sm-redirect"
                    href={currentItem.linkedinUrl}
                    target="_blank"
                  >
                    <i class="fa-brands fs-5 text-light fa-linkedin-in"></i>
                  </a>
                </div>}
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default OurTeamCard;

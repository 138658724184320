import React from "react";
import images from "../../helpers/images";

const BlogsBanner = () => {
  return (
    <div className="contact_parent_div position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="contact_banner_parent ">
              {/* <img src={contact_bar} alt="" className='contact_bar' /> */}
              <p className="mb-3">Blogs</p>
              <h1
                className="contact_banner_head"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                Insights
                <img
                  src={images.blogs_banner_bottom}
                  alt=""
                  className="contact_banner_img mx-4"
                />
                Stories
              </h1>
              <h1
                className="contact_banner_head"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img
                  src={images.blogs_banner_top}
                  alt=""
                  className="contact_banner_img2 me-4"
                />
                Ideas
              </h1>
              {/* <img src={contact_bar} alt="" className='contact_bar2' /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsBanner;

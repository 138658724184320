import React from "react";
import images from "../../../../helpers/images";
import { useNavigate } from "react-router-dom";

const ContentSection = () => {
  const navigate = useNavigate();
  return (
    <div className="Agency_detail_page" id="about_top_content">
      <div className="intro section section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="purpose_left" data-aos="fade-down-right">
                <h1>
                  {" "}
                  Creative <br className="break_tag" />
                  Solutions
                </h1>
                <button
                  onClick={() => navigate("/contact-us")}
                  className="button_red mt-4 agency_into_button"
                >
                  Consult Now
                </button>
              </div>
            </div>
            <div className="col-lg-8 my-auto">
              <div className="purpose_right pe-5" data-aos="fade-up-left">
                <p>
                  Looking to make a bold impression and stand out in a crowded
                  market?
                </p>
                <p>
                  Our Creative Solutions are designed to capture attention,
                  foster engagement, and elevate your brand to new heights.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="why_digital_market_section section_padding">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="why_digital_market_left" data-aos="zoom-in">
                <h2 className="headings_text_black">Why Creative Solutions?</h2>
                <img
                  src={images.creative_1}
                  alt="../"
                  className="why_digital_market_image mt-4"
                />
              </div>
            </div>
            <div className="col-lg-6 my-auto">
              <div className="why_digital_market_right" data-aos="zoom-in">
                <p className="paraWhite_margin_top text-black">
                  Creativity is at the heart of impactful marketing. In today’s
                  saturated market, a unique, creative approach is essential for
                  connecting with your audience, leaving a memorable impression,
                  and driving engagement. Creative solutions go beyond
                  aesthetics—they combine visual appeal with strategic messaging
                  that resonates with your target market.
                </p>
                <button
                  onClick={() => navigate("/contact-us")}
                  className="button_gradient mt-4"
                >
                   Get Consultation Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Agency_added_section">
        <div className="container">
          <div className="Agency_added_section_heading py-4">
            <h1 className="heading_para text-center" data-aos="fade-down">
              How Creative Solutions Benefit
              <br className="break_tag" /> Your Business
            </h1>
          </div>
          <div className="section_padding_bottom">
            <div className="row">
              <div className="col-lg-6">
                <div className="Agency_added_content d-flex">
                  <div>
                    <p className="number_list_backred" data-aos="fade-left">
                      1
                    </p>
                  </div>
                  <div className="Agency_added_flexdiv" data-aos="fade-right">
                    <h4 className="heading_para my-auto">
                      Brand Differentiation
                    </h4>
                    <p>
                      Stand out from the competition with unique, memorable
                      campaigns that leave a lasting impact on your audience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Agency_added_content d-flex me-0">
                  <div>
                    <p className="number_list_backred" data-aos="fade-left">
                      2
                    </p>
                  </div>
                  <div className="Agency_added_flexdiv" data-aos="fade-right">
                    <h4 className="heading_para my-auto">
                      Increased Engagement
                    </h4>
                    <p>
                      Creative, targeted content keeps your audience interested
                      and engaged, boosting interaction and connection with your
                      brand.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Agency_added_content Agency_added_content_lastsection d-flex">
                  <div>
                    <p className="number_list_backred" data-aos="fade-left">
                      3
                    </p>
                  </div>
                  <div className="Agency_added_flexdiv" data-aos="fade-right">
                    <h4 className="heading_para my-auto">
                      Enhanced Brand Perception
                    </h4>
                    <p>
                      {" "}
                      Consistent and visually appealing creative solutions
                      elevate your brand’s image, helping to build trust and
                      credibility.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Agency_added_content Agency_added_content_lastsection  d-flex me-0">
                  <div>
                    <p className="number_list_backred" data-aos="fade-left">
                      4
                    </p>
                  </div>
                  <div className="Agency_added_flexdiv" data-aos="fade-right">
                    <h4 className="heading_para my-auto">
                      Stronger Customer Loyalty
                    </h4>
                    <p>
                      Thoughtful and relatable creative solutions foster
                      emotional connections with your audience, driving
                      long-term loyalty.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agency_detail_listing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 px-0">
              <div
                className="agency_detail_whyChoose_left"
                style={{ backgroundColor: "black" }}
                data-aos="zoom-in-up"
              >
                <div className="container">
                  <h2 className="headings_text_white">
                    Why Choose Our <br className="break_tag" /> Creative
                    Solutions?{" "}
                  </h2>
                  <p className="paraWhite_margin_top mb-5">
                    We deliver creativity that drives results. While anyone can
                    create visuals, our team dives deeper to understand your
                    brand’s challenges and objectives, crafting custom
                    strategies that align perfectly with your goals.
                  </p>
                  <img
                    src={images.creative_2}
                    alt="../"
                    className="details_page_whyChoose"
                  />
                  <p className="paraWhite_margin_top mt-5">
                    With our creative expertise, we help bring your vision to
                    life, enhancing your brand’s visibility and driving
                    meaningful engagement.
                  </p>
                  <h2 className="headings_text_white mt-5">
                    Ready to let creativity <br className="break_tag" />
                    drive your success?{" "}
                  </h2>
                  <button
                    onClick={() => navigate("/contact-us")}
                    className="button_red mt-5"
                  >
                      Get Your FREE Creative Consultation
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0">
              <div
                className="agency_detail_whyChoose_rght"
                data-aos="zoom-in-down"
              >
                <h3 className="headings_text_white mb-5">
                  Here’s what sets our creative <br className="break_tag" />{" "}
                  solutions apart
                </h3>
                <div className="agency_detail_whyChoose_rght_content mt-0">
                  <div>
                    <img
                      src={images.creative_solution_icon1}
                      alt="../"
                      className="our_value_icon"
                    />
                  </div>
                  <div>
                    <h5 className="headings_text_white">
                      Custom Strategy Development
                    </h5>
                    <p>
                      We start by understanding your brand, challenges, and
                      vision. From there, we develop a tailored creative
                      strategy that addresses your needs and connects
                      authentically with your audience.
                    </p>
                  </div>
                </div>
                <div className="agency_detail_whyChoose_rght_content">
                  <div>
                    <img
                      src={images.creative_solution_icon2}
                      alt="../"
                      className="our_value_icon"
                    />
                  </div>
                  <div>
                    <h5 className="headings_text_white">Brand Storytelling</h5>
                    <p>
                      {" "}
                      Our team specializes in creating cohesive brand narratives
                      that resonate on an emotional level. Whether it’s a
                      campaign, social media content, or a website redesign, we
                      ensure your brand’s message is clear and impactful.
                    </p>
                  </div>
                </div>
                <div className="agency_detail_whyChoose_rght_content">
                  <div>
                    <img
                      src={images.creative_solution_icon3}
                      alt="../"
                      className="our_value_icon"
                    />
                  </div>
                  <div>
                    <h5 className="headings_text_white">
                      Engaging Visuals and Design
                    </h5>
                    <p>
                      {" "}
                      We create beautiful and strategic visuals that not only
                      enhance your brand’s image but also capture the essence of
                      what you offer, helping you stand out.
                    </p>
                  </div>
                </div>
                <div className="agency_detail_whyChoose_rght_content ">
                  <div>
                    <img
                      src={images.creative_solution_icon4}
                      alt="../"
                      className="our_value_icon"
                    />
                  </div>
                  <div>
                    <h5 className="headings_text_white">
                      Performance-Driven Creativity
                    </h5>
                    <p>
                      {" "}
                      We track the performance of every creative project to
                      ensure it’s driving real results, adjusting strategies to
                      improve effectiveness and maximize ROI.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;

import React from 'react'
import images from "../../../../helpers/images"



const ContentSection = () => {
    return (
        <div id="about_top_content">
            <div className='advisory_parent_section'>
                <div className='agency_detail_heading'>
                    <div className='purpose_left section_padding'>
                        <h1 className='text-center text-white' data-aos="zoom-in">Marketing Process<br className='break_tag' /> & Automation</h1>
                    </div>
                    <div className='container'>
                        <div className=''>
                            <div className='row'>
                                <div className='col-lg-5  my-auto'>
                                    <div className='advisory_details_content_left' data-aos="zoom-out-up">
                                        <h2 className='heading_para text-white'>Identifying Bottlenecks In  <br className='break_tag' />Your Workflow</h2>
                                        <p>Transform your marketing operations with our <span className='font-bold text-danger'>Marketing Process & Automation Consulting</span> services. We begin by assessing your existing workflows to identify inefficiencies
                                            and bottlenecks that hinder your productivity.</p>
                                        <p>
                                            Our goal is to streamline your processes, allowing you to focus on the core aspects of your business.                                            </p>
                                    </div>
                                </div>
                                <div className='col-lg-5 mx-auto'>
                                    <div className='advisory_details_content_right' data-aos="zoom-out-down">
                                        <img src={images.advisory_auto_1} alt="../" className='w-100 Advisory_marketing_rght_img' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='section_padding'>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='advisory_details_content_right' data-aos="fade-up">
                                        <img src={images.advisory_auto_2} alt="../" className='w-100 Advisory_marketing_left_img' />
                                    </div>
                                </div>
                                <div className='col-lg-5  my-auto mx-auto'>
                                    <div className='advisory_details_content_left' data-aos="fade-down">
                                        <h2 className='heading_para text-white'>Implementing Tailored <br className='break_tag' />Automation Tools</h2>
                                        <p>Once we identify areas for improvement, we introduce automation tools tailored to your
                                            unique needs. By automating repetitive tasks, such as lead generation and email
                                            marketing, you free up valuable time and resources.</p>
                                        <p>
                                            This allows you to concentrate on strategic initiatives that foster growth and elevate your marketing efforts.                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection
import React from "react";
import business_goals from "../../assets/images/business_goals.png";
import Advisory_icon1 from "../../assets/images/Advisory_icon1.webp";
import Advisory_icon2 from "../../assets/images/Advisory_icon2.webp";
import Advisory_icon3 from "../../assets/images/Advisory_icon3.webp";

import Advisory_icon5 from "../../assets/images/Advisory_icon5.webp";
import Advisory_icon6 from "../../assets/images/Advisory_icon6.webp";
import Advisory_icon7 from "../../assets/images/Advisory_icon7.webp";
import adv_img from '../../assets/images/adv_5.webp'
import why_choose_applied from "../../assets/images/why_choose_1.webp";
import why_choose_applied_1 from "../../assets/images/why_choose_2.webp";

import circle1 from "../../assets/images/circle_1.webp";
import circle2 from "../../assets/images/circle_2.webp";
import circle3 from "../../assets/images/circle_3.webp";
import circle4 from "../../assets/images/circle_4.webp";
import circle5 from "../../assets/images/circle_5.webp";
import circle6 from "../../assets/images/circle_6.webp";
import circle7 from "../../assets/images/circle_7.webp";
import circle8 from "../../assets/images/circle_8.webp";

import images from "../../helpers/images"


import { Link } from "react-router-dom";

const AdvisoryCommonSections = () => {
    return (
        <div className="overflow-hidden">
            <section className='why_choose_us d-flex justify-content-center position-relative overflow-hidden'>
                <div class="marquee d-flex">
                    <div id="div1">
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                    </div>
                    <div id="div2 px-3">
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                        <p> &nbsp;Why Choose Us?</p>
                    </div>
                </div>
                <div className='why_choose_us_content' data-aos="zoom-in">
                    <h2 className='headings_text_white text-center'>Why Choose Us? </h2>
                    <p className='text-white'>When you choose our services, you benefit from a wealth of experience and expertise. Our <br className='break_tag' />
                        dedicated team is committed to understanding your unique needs and delivering tailored  <br className='break_tag' />
                        solutions that drive results. We pride ourselves on transparency and collaboration, ensuring you <br className='break_tag' /> are involved every step of the way</p>
                    <p className='mt-3 text-white'>In an ever-evolving market like Dubai, we equip you with the tools and insights necessary to <br className='break_tag' />
                        stay ahead. Let us guide you in unlocking your brand's fullest potential and achieving your <br className='break_tag' /> business goals. </p>
                </div>
            </section>
            <div className='our_philosophy_ubuntu' data-aos="zoom-in">
                <p>Digital Transformation Solutions to Drive Business Growth</p>
            </div>
            <div className='business_goals_parent section_padding_top'>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-5'>
                            <div className='business_goals_image_sec' data-aos="fade-up">
                                <img src={adv_img} alt="" className='business_goals w-100' />
                            </div>

                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='business_goals_content_sec' data-aos="fade-down">
                                <h2 className='heading_para'>Understanding Your <br className='break_tag' />Business Goals</h2>
                                <p className='text-black mt-4'>At Social Eagle, we connect your business goals with the most effective digital solutions, using decades of industry experience to fuel your digital transformation journey. As your trusted partner, we guide you from the foundational stages of digital strategy creation to comprehensive implementation, ensuring every step is strategically aligned to deliver optimal results. Our commitment to continuous improvement empowers your business to stay competitive and agile in an evolving digital landscape.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='values_section_parent_div section_padding overflow-hidden'>
                <div className='container'>
                    <div className='section_padding_bottom'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h2 className='headings_text_white' data-aos="fade-right">Value Creation Enabled  <br className='break_tag' />By Digital Transformation</h2>
                            </div>
                            <div className='col-lg-5'>
                                <p className='text-white' data-aos="fade-left">We believe in "Transforming Marketing Through Innovation and Technology" to deliver measurable value. Digital transformation goes beyond adopting new tools; it’s about creating value, solving problems, and meeting the dynamic needs of customers. Here’s how we help you achieve that</p>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-between'>
                        <div className='col-lg-3'>
                            <div className='our_value_child_div mt-lg-0 mt-4' data-aos="fade-down-right">
                                <div className='icon_div'>
                                    <img src={Advisory_icon1} alt=".." className='our_value_icon' />
                                </div>
                                <div className='our_value_content '>
                                    <h6>Entering New Markets </h6>
                                    <p className='text-white'>Digital tools enable us to identify and reach new markets, allowing businesses to expand their audience and customer base like never before.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='our_value_child_div mt-lg-0 mt-4' data-aos="zoom-in">
                                <div>
                                    <img src={Advisory_icon2} alt=".." className='our_value_icon' />
                                </div>
                                <div className='our_value_content'>
                                    <h6>Creating Innovative Services</h6>
                                    <p className='text-white'>From personalized campaigns to advanced analytics, we leverage technology to deliver innovative marketing solutions that meet evolving customer demands.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='our_value_child_div mt-lg-0 mt-4' data-aos="fade-down-left">
                                <div>
                                    <img src={Advisory_icon3} alt=".." className='our_value_icon' />
                                </div>
                                <div className='our_value_content'>
                                    <h6>Building New Capabilities</h6>
                                    <p className='text-white'>Our use of cutting-edge digital tools enhances our ability to provide smarter, faster, and more effective marketing services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className='row justify-content-between'>

                        <div className='col-lg-3'>
                            <div className='our_value_child_div value_margintop' data-aos="fade-right">
                                <div>
                                    <img src={Advisory_icon5} alt=".." className='our_value_icon' />
                                </div>
                                <div className='our_value_content'>
                                    <h6>Boosting Transparency and Collaboration
                                    </h6>
                                    <p className='text-white'>Through digital platforms, we foster open communication and collaboration with our clients, building trust and ensuring smooth project execution.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='our_value_child_div value_margintop' data-aos="zoom-in">
                                <div>
                                    <img src={Advisory_icon6} alt=".." className='our_value_icon' />
                                </div>
                                <div className='our_value_content'>
                                    <h6>Customer-Centric Approach
                                    </h6>
                                    <p className='text-white'>We put customers at the heart of our strategies, using data and insights to create personalized experiences that drive engagement and loyalty.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='our_value_child_div value_margintop' data-aos="fade-down-left">
                                <div>
                                    <img src={Advisory_icon5} alt=".." className='our_value_icon' />
                                </div>
                                <div className='our_value_content'>
                                    <h6>Partnering for Value
                                    </h6>
                                    <p className='text-white'>By closely collaborating with our clients, we co-create solutions that deliver tangible value and help businesses grow.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className='row justify-content-between'>
                        <div className='col-lg-3'>
                            <div className='our_value_child_div value_margintop' data-aos="zoom-in">
                                <div>
                                    <img src={Advisory_icon7} alt=".." className='our_value_icon' />
                                </div>
                                <div className='our_value_content'>
                                    <h6>Making a Positive Social Impact
                                    </h6>
                                    <p className='text-white'>Our strategies extend beyond business growth, supporting social causes and creating a positive impact on society.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='our_philosophy'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h2 className='headings_text_white' data-aos="fade-right">Applied Analytics: Unleashing  <br className='break_tag' />the Power of Data</h2>
                        </div>
                        <div className='col-lg-6'>
                            <p className='text-white' data-aos="fade-left">In a world driven by data, the ability to harness and interpret data is key to achieving a competitive edge. Social Eagle specializes in applied analytics, empowering businesses to turn complex data into actionable insights that fuel growth and innovation.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='whychoose_applied_parent section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 my-auto'>
                            <div data-aos="zoom-in">
                                <img src={images.advisory_common_2} alt="" className='w-100' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='business_goals_content_sec1 pt-5' data-aos="fade-down">
                                <h2 className='heading_para'>Your Partner in{' '}
                                    <br className='break_tag' />Data-Driven Growth</h2>
                                <p className='text-black mt-4'>At Social Eagle, we are passionate about helping businesses unlock the potential of their data. We work closely with organizations to navigate data-driven challenges, scale their analytics capabilities, and harness insights that lead to sustained growth and success.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 my-auto order-lg-2'>
                            <div className='ps-lg-5' data-aos="zoom-in">
                                <img src={why_choose_applied_1} alt="" className='w-100' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto order-lg-1'>
                            <div className='business_goals_content_sec2 ps-5 pb-5' data-aos="fade-up">
                                <h2 className='heading_para'>Why Choose Applied{' '}
                                    <br className='break_tag' />Analytics?</h2>
                                <p className='text-black mt-4'>Applied analytics transforms raw data into a valuable asset by identifying patterns, uncovering trends, and providing actionable insights that drive smarter business decisions. With advanced tools and methodologies, we help businesses like yours streamline operations, solve complex challenges, and create data-driven value. Our consulting services are tailored for those looking to simplify data complexities and turn insights into actionable strategies for impactful growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='why_social_eagle section_padding '>
                <div className='container'>
                    <h2 data-aos="fade-down">Why Social Eagle?</h2>
                    <div className='row'>
                        <div className='col-lg-3 why_social_eagle_res'>
                            <div className='why_social_eagle_left position-relative'>
                                <img src={circle1} alt="" className="whyseleftImg1" />
                                <img src={circle2} alt="" className="whyseleftImg2" />
                                <img src={circle3} alt="" className="whyseleftImg3" />
                                {/* <img src={circle4} alt="" className="whyseleftImg4" /> */}
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='why_social_eagle_center mt-2 position-relative overflow-hidden' data-aos="fade-up">
                                <h3 >Why Social Eagle?</h3>
                                <p className='text-center text-white mt-3'>Partnering with Social Eagle means joining forces with a team committed
                                    to pushing the boundaries of what digital transformation and applied
                                    analytics can achieve for your business. Let’s create a future where
                                    technology,
                                    data, and strategy come together to unlock unprecedented value.</p>
                                <Link to="/contact-us" className="link_tag">
                                    <button className='button_red_center mt-4'>Get in touch</button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-3 why_social_eagle_res'>
                            <div className='why_social_eagle_right position-relative'>
                                <img src={circle5} alt="" className="whyserghtImg1" />
                                {/* <img src={circle6} alt="" className="whyserghtImg2" /> */}
                                <img src={circle7} alt="" className="whyserghtImg3" />
                                <img src={circle8} alt="" className="whyserghtImg4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvisoryCommonSections;

import img1 from '../../assets/images/meet_team/amt1.webp'
import img2 from '../../assets/images/meet_team/amt2.webp'
import img3 from '../../assets/images/meet_team/amt3.webp'
import img4 from '../../assets/images/meet_team/amt4.webp'
import img5 from '../../assets/images/meet_team/amt5.webp'
import img6 from '../../assets/images/meet_team/amt6.webp'
import img7 from '../../assets/images/meet_team/amt7.webp'
import img8 from '../../assets/images/meet_team/amt8.webp'
import img9 from '../../assets/images/meet_team/amt9.webp'
import img10 from '../../assets/images/meet_team/amt10.webp'
import img11 from '../../assets/images/meet_team/amt11.webp'
import img12 from '../../assets/images/meet_team/amt12.webp'
import img13 from '../../assets/images/meet_team/amt13.webp'
import img14 from '../../assets/images/meet_team/amt14.webp'
import img15 from '../../assets/images/meet_team/amt15.webp'
import img16 from '../../assets/images/meet_team/amt16.webp'
import img17 from '../../assets/images/meet_team/amt17.webp'
import img18 from '../../assets/images/meet_team/amt18.webp'
import img19 from '../../assets/images/meet_team/amt19.webp'
import img20 from '../../assets/images/meet_team/amt20.webp'
import img21 from '../../assets/images/meet_team/amt21.webp'
import img22 from '../../assets/images/meet_team/amt22.webp'
import img23 from '../../assets/images/meet_team/amt23.webp'

export const teamData = [
    {
        id: 1,
        name: "Dharaneetharan G D",
        title: "Founder & Thought Leader",
        img: img1,
        para: "Dharaneetharan G D, the “Digital Monk” from Sivakasi, Tamil Nadu, is redefining EdTech and business globally with over 10 years of digital marketing expertise. As the founder of Social Eagle Marketing, he has generated ₹100+ Crores in client revenue and impacted over 5 lakh people through high-value content. Dharani's innovative strategies and purpose-driven leadership continue to inspire measurable growth and entrepreneurial success.",
        faceBookUrl: "https://www.facebook.com/dharaneetharangd/",
        instaUrl: "https://www.instagram.com/dharaneetharan/?hl=en",
        linkedinUrl:
            "https://www.linkedin.com/in/dharaneetharan/?originalSubdomain=in",
    },
    {
        id: 2,
        name: "Vishnuhari H",
        title: "Performance Marketer & Lead Trainer",
        img: img2,
        para: "Vishnuhari Harikumar, a tech expert and digital entrepreneur, transforms ideas into thriving businesses. As Co-founder of Social Eagle Marketing, he has trained over 1,200 students in digital entrepreneurship, driving crores in revenue. His strategic leadership and collaborations with global brands empower businesses to excel in the digital space.",
        faceBookUrl: "https://www.facebook.com/imvishnuhari?mibextid=ZbWKwL",
        instaUrl: "https://www.instagram.com/imvishnuhari/",
        linkedinUrl: "https://www.linkedin.com/in/vishnuhari-harikumar/",
    },
    {
        id: 3,
        name: "Sathish S",
        title: "Operations & Acquisition Expert",
        img: img3,
        para: "Sathish S, a results-driven leader, transitioned from Mechanical Engineer to Digital Entrepreneur, scaling businesses to 8-figure revenues. As an expert in operations and talent acquisition, he specializes in streamlining processes and building high-performing teams. His passion for growth and operational expertise drives measurable business success.",
        faceBookUrl: "https://www.facebook.com/SocialEagleOfficial/",
        instaUrl: "https://www.instagram.com/socialeagleofficial/?hl=en",
        linkedinUrl: "https://www.linkedin.com/company/socialeagle/",
    },
    {
        id: 4,
        name: "Shatheeshram Ganapathy",
        title: "Sales Beast",
        img: img4,
        para: "Shatheeshram Ganapathy, the “Sales Beast,” is a high-ticket sales expert known for his strategic creativity and process-driven approach. From aesthetic photographer to process-oriented entrepreneur, he mentors sales teams using 8-figure systems to achieve exceptional results. His guidance helps students secure high-ticket clients, empowering businesses to scale with innovation and efficiency.",
        faceBookUrl: "https://www.facebook.com/shatheeshram?mibextid=ZbWKwL",
        instaUrl:
            "https://www.instagram.com/shatheeshramganapathy/?igsh=MW5nYzc4Nmh3OXJvdQ%3D%3D#",
        linkedinUrl: "https://www.linkedin.com/in/shatheeshram-ganapathy-a7476473/",
    },
];
export const teamData2 = [
    {
        id: 5,
        name: "Priya Dharaneetharan",
        title: "Personal Branding & Authority Creator",
        para: "Priya Dharaneetharan, the powerhouse behind 'Content Creators,' is a seasoned personal branding expert with over half a decade of experience. Armed with extensive IT knowledge, she specializes in empowering individuals and businesses to build compelling personal brands that resonate in the digital landscape. As a dynamic trainer, Priya's innovative strategies and hands-on approach foster creativity and drive exceptional growth for her clients.",
        img: img5,
    },
    {
        id: 6,
        name: "Varsha D",
        title: "Personal Branding & Authority Creator",
        para: "Varsha D is a content creation specialist renowned for her exceptional ability to ask the right questions and build strong rapport with clients. With extensive experience in organizing shoots and crafting compelling videos for content creators, she elevates brands through her meticulous planning and creative insights. Varsha's passion for storytelling and her keen understanding of audience engagement make her an invaluable asset to any project.",
        img: img6,
    },
    {
        id: 7,
        name: "Manikandan",
        title: "Creative Head",
        img: img7,
        para: "Manikandan, with over a decade of expertise in graphic design, specializes in creating stunning web and print media designs. His work includes compelling visual presentations, personal branding, and business collateral that leave a lasting impact. Combining creativity with precision, he ensures every project aligns with strategic goals. From websites to brochures, Manikandan’s designs elevate brands effortlessly.",
        linkedinUrl: "https://www.linkedin.com/in/manikandan-palaninathan-2b4289129/"
    },
    {
        id: 8,
        name: "Aswin Kumar Ramesh",
        title: "Executive - Admin & Operations",
        para: "Aswin Kumar Ramesh ensures seamless operations and efficient administration with his meticulous organizational skills. His ability to streamline processes and manage day-to-day tasks keeps the team running smoothly. Dedicated and detail-oriented, Aswin plays a vital role in supporting the team’s overall productivity and success.",
        img: img8,
    },
]
export const teamData3 = [
    {
        id: 9,
        name: "Azhagarasan",
        title: "Senior Video Editor",
        para: "Azhagarasan is a seasoned video editor and visual storyteller, specializing in animation and VFX, bringing stories to life through captivating visuals. As a professor and cinematographer, he shares his expertise while continuing to innovate in the creative space.",
        img: img9,
        linkedinUrl: "https://www.linkedin.com/in/azhagarasan-ravi-3325a123a/"
    },
    {
        id: 10,
        name: "Manoj",
        title: "Executive Video Editor",
        img: img10,
        para: "Manoj is a talented video editor who brings stories to life through creative and impactful visuals. With a sharp eye for detail and technical expertise, he ensures each video captures the essence of the message. Manoj’s skillful editing enhances the team’s ability to deliver high-quality content.",
        linkedinUrl: "https://www.linkedin.com/in/siva-mano-455829158/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        id: 11,
        name: "Praveen Kumar",
        title: "Executive Video Editor",
        img: img11,
        para: "Praveen Kumar is a versatile Executive Video Editor and Visual Storyteller with expertise in graphic design, UI/UX design, photo editing, and video editing, backed by advanced proficiency in the Adobe Suite. He leverages his technical and creative skills to deliver innovative and user-focused designs. With a strong passion for UI/UX, Praveen is eager to contribute to crafting visually striking and intuitive digital experiences.",
        linkedinUrl: "https://www.linkedin.com/in/praveen-kumar-35aa641b9/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    },
    // {
    //     id: 12,
    //     name: "Ganesh Kumar M",
    //     title: "Sales Executive",
    //     para: "Ganesh Kumar M is a results-driven sales professional with a knack for identifying client needs and delivering tailored solutions. His proactive approach and strong interpersonal skills help build lasting relationships and drive business growth. Ganesh consistently exceeds expectations, contributing significantly to team success.",
    //     img: img12,
    //     linkedinUrl: "https://www.linkedin.com/in/ganesh-kumar-9b9b1a215/"
    // },
    {
        id: 13,
        name: "Anbarasan",
        title: "Sales Executive",
        para: "Anbarasan is a results-driven Sales Executive with a proven ability to identify leads, generate sales opportunities, and achieve targets consistently. He effectively drives revenue growth and builds strong client relationships. His strategic approach and focus on delivering measurable sales outcomes make him a key contributor to business success.",
        img: img13,
        linkedinUrl: "https://www.linkedin.com/in/anbarasan-v-n-bb5180160/"
    },
]
export const teamData4 = [

    // {
    //     id: 14,
    //     name: "Naresh",
    //     title: "Sales Executive",
    //     img: img14,
    //     para: "Naresh excels in driving sales growth through strategic planning and effective client engagement. His ability to identify opportunities and tailor solutions ensures consistent achievement of sales targets. With a results-oriented mindset, Naresh delivers exceptional outcomes and contributes significantly to revenue generation.",
    //     linkedinUrl: "https://www.linkedin.com/in/naresh-karthik-91a810222/"
    // },
    {
        id: 15,
        name: "Pradeep",
        title: "Sales Executive",
        img: img15,
        para: "Pradeep is a skilled sales professional who builds strong client relationships based on trust. He understands market needs and creates effective strategies to drive sales. His focus on client success makes him a valuable part of the team.",
    },
    {
        id: 16,
        name: "Ashwin",
        title: "Sales Executive",
        para: "Ashwin is a focused sales professional skilled in generating leads and converting them into opportunities. His ability to connect with prospects ensures a strong pipeline and consistent sales growth. Ashwin plays a key role in achieving targets and driving revenue.",
        img: img16,
    },
    {
        id: 17,
        name: "Sabarinathan B",
        title: "Sales Executive",
        img: img17,
        para: "Sabarinathan B is a skilled sales professional who understands client needs and delivers the right solutions. With strong negotiation skills and a proactive approach, he closes deals efficiently and helps grow the business.",
    },
    {
        id: 18,
        name: "Agalya Maran",
        title: "Accounts Executive",
        img: img18,
        linkedinUrl: "https://www.linkedin.com/in/agalyamaran/",
        para: "Agalya Maran is a reliable accounts executive skilled in managing finances with accuracy and efficiency. Her strong understanding of accounting principles ensures smooth financial operations. Agalya's attention to detail and commitment to integrity contribute to the team's financial stability and success.",
    },
]
export const teamData5 = [


    {
        id: 19,
        name: "Surya Balasubramaniyan",
        title: "Sales Executive",
        img: img19,
        para: "Surya Balasubramaniyan is a dynamic sales professional specializing in building strong client relationships and delivering tailored solutions. With a keen understanding of customer needs, he consistently drives sales growth and achieves targets. His dedication to maximizing results and fostering long-term partnerships makes him a key contributor to sales success.",
    },
    {
        id: 20,
        name: "Gowtham",
        title: "Performance Marketing Executive",
        img: img20,
        para: "Gowtham is a Performance Marketing Specialist dedicated to driving client success through high-impact campaigns that maximize ROI. With a client-focused approach, he tailors strategies to meet unique business goals. Committed to excellence, Gowtham ensures every campaign delivers measurable results and contributes to the long-term success of our clients.",
    },
    {
        id: 21,
        name: "Sujitha R",
        title: "Senior Graphic Designer",
        img: img21,
        para: "Sujitha R brings creativity and expertise to every design project, crafting visually captivating designs. With a keen eye for detail and a deep understanding of design principles, she ensures each project aligns perfectly with the brand vision. Sujitha’s passion for design consistently elevates the team’s creative output.",
        linkedinUrl: "https://www.linkedin.com/in/sujitha-ramakrishnan-84a73b184/"
    },
    {
        id: 23,
        name: "Karthikeyan",
        title: "Performance Marketing Executive",
        img: img23,
        para: "Karthikeyan is a proficient Digital Marketing Executive who leverages data-driven insights to create impactful marketing campaigns. With a focus on data, he ensures campaigns deliver exceptional results. His commitment to growth and ongoing improvements makes him a valuable asset in achieving digital marketing success.",
        linkedinUrl: 'https://www.linkedin.com/in/karthikeyan-h/'
    }
]
export const teamData6 = [

    {
        id: 22,
        name: "Vishva Venkataraman",
        title: "Sales Executive",
        img: img22,
        para: "Vishva Venkataraman is a results-driven sales professional with a talent for identifying opportunities and crafting solutions that drive revenue. His strategic mindset and strong communication skills enable him to build lasting client relationships. Focused on delivering measurable results, Vishva is a key contributor to achieving sales targets and driving business growth.",
    },

]
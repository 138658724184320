import tech_dev_img_1 from "../../assets/images/tech_dev_img_1.webp";
import tech_dev_img_2 from "../../assets/images/tech_dev_img_2.webp";
import tech_dev_img_3 from "../../assets/images/tech_dev_img_3.webp";
import tech_dev_img_4 from "../../assets/images/tech_dev_img_4.webp";
import tech_dev_img_5 from "../../assets/images/tech_dev_img_5.webp";
import tech_dev_img_6 from "../../assets/images/tech_dev_img_6.webp";
import tech_dev_img_7 from "../../assets/images/tech_dev_img_7.webp";

export const techno_main_content = [
    {
        image_url: tech_dev_img_1,
        heading: "Web & Mobile App Development",
        content1:
            "Social Eagle offers full-scale web and mobile app development services that transform your business concepts into powerful, user-friendly digital solutions. We provide tailored applications designed to enhance customer engagement and drive business growth across industries. Our skilled team of developers and designers leverage modern frameworks, methodologies, and best practices to deliver high-performance applications that meet all technical and user requirements.",
        content2: "Our process is driven by thorough research and strategy, starting from initial ideation and prototyping, through to the development, quality testing, and deployment phases. Social Eagle’s AI integration enhances personalization, enabling brands to reach users with precision and foster a deeper connection. Additionally, we ensure that all apps are optimized for speed, scalability, and security, making them robust platforms that grow alongside your business.",
    },
    {
        image_url: tech_dev_img_2,
        heading: "E-com Custom Website Development",
        content1: "Our eCommerce development service is tailored to create highly functional, user-centric online stores that make shopping enjoyable and straightforward. We understand that an eCommerce platform should not only look attractive but also function seamlessly, handling a large volume of transactions, managing inventory effectively, and supporting personalized customer interactions. Social Eagle’s custom eCommerce solutions cater to these needs by providing flexible, feature-rich, and optimized eCommerce sites that align perfectly with your brand identity.",
        content2: "Our expert team works closely with you to design a responsive, fast-loading site that ensures a smooth browsing experience on any device. We incorporate secure checkout processes, various payment gateway options, and a custom shopping cart experience. Beyond this, we also focus on SEO and load time optimization to ensure your products are always accessible and rank well on search engines."
    },
    {
        image_url: tech_dev_img_3,
        heading: "Shopify Development",
        content1: "As Shopify partners, we specialize in building and optimizing online s`tores that meet modern standards for eCommerce businesses. Social Eagle provides a complete Shopify development experience from store setup and customization to app integration and performance optimization. Our goal is to craft an online store that is visually stunning and designed to convert.",
        content2: "With Shopify’s extensive tools and our custom approach, we can tailor the entire shopping experience to your needs. Our solutions ensure that every aspect of the store aligns with your branding while also delivering a frictionless shopping experience. From UX design to optimizing product listings and checkout processes, we leverage Shopify’s flexibility to maximize conversion rates and ensure sustained growth for your business.",
    },
    {
        image_url: tech_dev_img_4,
        heading: "WordPress Development",
        content1: "Our WordPress development services enable businesses to have an adaptable, professional, and feature-rich website that’s easy to manage. We specialize in building fully customized WordPress websites, utilizing SEO-focused practices to help your site rank higher, attract more visitors, and improve conversion rates. With WordPress, we can tailor nearly every aspect of the website, from themes and plugins to site structure and integrations.",
        content2: "We build websites with a focus on performance, ensuring they load quickly, function smoothly, and are accessible to all users. Additionally, our team provides comprehensive training and documentation, enabling your team to update and manage the site with ease. From brand-new websites to redesigns, Social Eagle’s WordPress services cater to both SMEs and larger enterprises seeking to scale their online presence.",
    },
    {
        image_url: tech_dev_img_5,
        heading: "Web & Mobile App Maintenance",
        content1: "Maintaining your digital applications is critical to ensuring an optimal user experience and keeping your platform secure. Social Eagle’s maintenance services cover all essential aspects, from regular updates and troubleshooting to security patching and performance monitoring. Our proactive approach allows us to address potential issues before they become problems, ensuring your website or app remains reliable and effective.",
        content2: "With dedicated maintenance plans, Social Eagle helps businesses avoid downtime, boost user satisfaction, and reduce operational risks. We offer everything from basic updates to comprehensive performance enhancements based on analytics and real-time insights.",
    },
    {
        image_url: tech_dev_img_6,
        heading: "Machine Learning & AI Development",
        content1: "Social Eagle harnesses the power of machine learning and AI to deliver advanced, data-driven solutions that boost personalization and improve customer engagement. By developing AI-powered systems and applications, we help businesses gain valuable insights, automate repetitive tasks, and create enhanced user experiences. Our AI development solutions range from predictive analytics and recommendation engines to chatbots and customer support automation.",
        content2: "Through our ML and AI expertise, Social Eagle builds solutions that foster deeper customer relationships and deliver relevant experiences at every stage. We create models tailored to your data and requirements, unlocking the potential of AI for smarter decision-making and efficient operations.",
    },
    {
        image_url: tech_dev_img_7,
        heading: "UI/UX Development",
        content1: "Our UI/UX development services focus on creating user-friendly, engaging, and intuitive designs that make lasting impressions on users. We prioritize user research, prototyping, and testing to ensure that the design aligns with user needs and business objectives. Social Eagle’s designers work meticulously to deliver responsive and adaptive designs that captivate users on any device.",
        content2: "By continuously analyzing user behavior and incorporating feedback, we evolve our designs to meet changing user expectations and market trends. We ensure that every digital interaction is purposeful and enjoyable, strengthening brand affinity and encouraging repeat engagement.",
    },
];

import React from "react";

import contact_banner_img1 from "../../../../assets/images/banner/advisory1.webp";
import contact_banner_img2 from "../../../../assets/images/banner/advisory2.webp";
import contact_bar from "../../../../assets/images/contact_bar.webp";

import { scrollToAboutSection } from "../../../../helpers/utils";
const BannerSection = () => {
  return (
    <div className="contact_parent_div overflow-x-hidden position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="contact_banner_parent ">
              <img src={contact_bar} alt="" className="contact_bar_whiteback" />
              <p className="mb-3">Advisory</p>
              <h1 className="contact_banner_head"
                data-aos="fade-left" data-aos-delay="300"
              >
                Community
                <img
                  src={contact_banner_img1}
                  alt=""
                  className="contact_banner_img"
                />
              </h1>
              <h1 className="contact_banner_head"
                data-aos="fade-right" data-aos-delay="300">
                <img
                  src={contact_banner_img2}
                  alt=""
                  className="contact_banner_img2"
                />{" "}
                & MasterMind
              </h1>
              <img
                src={contact_bar}
                alt=""
                className="contact_bar_bottom_whiteback"
              />
            </div>
          </div>
        </div>
      </div>
      <a href="#/advisory/#about_top_content" class="circle-div" onClick={(e) => {
                e.preventDefault();
                scrollToAboutSection("about_top_content");
              }}>
        <div className="banner_circle_loop">
          <div class="circle-center-link">
            <div class="circle-center">
              <i class="fa-solid fa-computer-mouse"></i>
            </div>
            <div id="circle" class="rotate">
              <svg width="300px" height="300px" viewBox="0 0 300 300">
                <defs>
                  <path
                    id="circlePath"
                    d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                  ></path>
                </defs>
                <circle cx="150" cy="150" r="60" fill="none"></circle>
                <g>
                  <use xlinkHref="#circlePath" fill="none"></use>
                  <text fill="#000" font-size="10.5" fontWeight="normal   ">
                    <textPath xlinkHref="#circlePath">
                      &nbsp; &#183; &nbsp; FLY &nbsp; HIGH &nbsp; WITH &nbsp;
                      SOCIAL &nbsp; EAGLE &nbsp; &#183; &nbsp; FLY &nbsp; HIGH
                      &nbsp; WITH &nbsp; SOCIAL &nbsp; EAGLE
                    </textPath>
                  </text>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default BannerSection;

import React, { useEffect, useRef, useState } from "react";
import { blogs_mainpage_content } from "../../helpers/contents/BlogsMainPage";
import { Link } from "react-router-dom";

const BlogsContent = () => {
  return (
    <div>
      <div className="meetour_team_parent_section blogs_card_parent">
        <div className="container">
          <h1
            className="text-white blogs_heading"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            Blogs
          </h1>
          <div className="row">
            {blogs_mainpage_content?.map((item, index) => (
              <div
                className="col-lg-4"
                data-aos="fade-right"
                data-aos-duration="1000"
                key={index}
              >
                <div className="blogs_card position-relative">
                  <div className="blogs_image_div">
                    <div className="image_wrapper">
                      <img src={item?.image} alt=".." className="blogs_image" />
                      <div className="blogs_overlay">
                        <Link to={item?.redirect_link} className="read-more">
                          Read More{" "}
                          <i className="fa-solid fa-arrow-right contact_arrow"></i>
                        </Link>
                      </div>
                    </div>
                    <p className="blogs_card_image_para">{item?.heading}</p>
                  </div>
                  <div className="blogs_content_div mt-4">
                    <p>{item?.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsContent;

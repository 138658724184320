// export const scrollToAboutSection = () => {
//     const targetElement = document.getElementById('about_top_content');
//     if (targetElement) {
//         window.scrollTo({
//             top: targetElement.offsetTop - 50,
//             behavior: 'smooth',
//         });
//     }
// };

export const scrollToAboutSection = (sectionId) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
        // Smooth scroll to the target element
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};




